<!-- @format -->

<div class="card custom-section">
	<h1 class="section-title">Edit Admin User</h1>
	<p class="subtext">Fill out the User details using the form below.</p>

	<div class="edit-admin">
		<div class="user-info">
			<img
				src="../../assets/profile-icon.svg"
				alt="profile icon" />
			<div>
				<span>{{ username }}</span> <br />
				<span>{{ userEmail }}</span>
			</div>
		</div>
		
			
	</div>
	<div class="dropdown-group">
		<div>
			<label class="custom-label">Company</label>
		<app-dropdown
			[disabled]="true"
			selectedItem="{{ selectedValues.company }}"
			[label]="
				 selectedValues.company.companyName
			"></app-dropdown>
		</div>
		<div>
			<label class="custom-label">Plant</label>
		
		<app-dropdown
			[disabled]="true"
			selectedItem="{{ selectedValues.plant }}"
			[label]="
				selectedValues.plant.plantName
			"></app-dropdown>
		</div>
	</div>
	<div class="form-data-selection-row">
		<div class="data-select">
			<div class="section1">
				<div class="mr-28">
					<h3>Available Plant Assignments</h3>
					<p class="instructions">
						Select checkbox to Plant to current Plant Assignments
					</p>
				</div>
				<div class="search-bar mr-28">
					<img
						src="../assets/search.svg"
						alt="search" />
					<input
						type="text"
						placeholder="Search"
						[(ngModel)]="searchText" />
				</div>
				<!-- PLANTS -->
				<div
					class="data-list"
					*ngIf="activePlants.length > 0">
					<app-checked-card
						*ngFor="
							let data of activePlants
								| search : searchText : 'plantName';
							let i = index
						"
						[attr.data-index]="i"
						[title]="data?.plantName"
						(onClickIcon)="
							isSelectedPlant(data) ? null : addPlant(data)
						"
						[isSelected]="isSelectedPlant(data)"
						[icon]="
							isSelectedPlant(data)
								? 'checkbox-selected.svg'
								: 'checkbox-unselected.svg'
						"></app-checked-card>
				</div>
			</div>
			<!-- SECTION 2 -->
			<div class="section2">
				<h3>Current Plant Assignments</h3>

				<ul>
					<li class="instructions">Click “X” to remove a plant</li>
				</ul>
				<!-- SELECTED PLANTS -->
				<div
					class="data-list"
					*ngIf="selectedPlants.length > 0">
					<app-checked-card
						*ngFor="let data of selectedPlants; let i = index"
						[attr.data-index]="i"
						[title]="data?.plantName"
						[isDraggable]="true"
						[isSelected]="true"
						(onClickIcon)="removePlant(i)"
						icon="checkbox-remove.svg"></app-checked-card>
				</div>
			</div>
		</div>

		<div class="btn-group">
			<button
				type="button"
				class="btn-outline"
				(click)="transferData()">
				Cancel
			</button>
			<button
				[ngClass]="!enableNextBtn() ? 'disabled-btn' : ''"
				[disabled]="!enableNextBtn()"
				(click)="enableNextBtn() ? showSaveModal() : null"
				type="submit"
				class="btn-fill">
				Update User
			</button>
		</div>
	</div>
</div>

<app-save-modal
	*ngIf="updateAdmin"
	(onSave)="editAdminUser()"></app-save-modal>
