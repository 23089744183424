/** @format */

import { Component, EventEmitter, Input, Output } from "@angular/core"

@Component({
	selector: "app-checked-card",
	templateUrl: "./checked-card.component.html",
	styleUrls: ["./checked-card.component.css"],
})
export class CheckedCardComponent {
	// @Input() data: any
	@Input() title: string = ""
	@Input() description: string = ""
	@Input() icon: string = ""
	@Input() isDraggable: boolean = false
	@Input() isSelected: boolean = false
	@Output() onClickIcon: EventEmitter<any> = new EventEmitter()

	selectIcon() {
		this.onClickIcon.emit()
	}
}
