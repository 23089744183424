import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Audit } from 'src/app/models/audit.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

   baseUrl: string = environment.apiUrl
  constructor(private http : HttpClient) { }

  retrieveAudits() : Observable<Audit[]> {
    return this.http.get<Audit[]>(this.baseUrl + '/audit')
  }

  retrieveAuditByPage(auditRequest : any) : Observable<Audit[]> {
    return this.http.get<Audit[]>(this.baseUrl + `/audit/${auditRequest.pageNumber}/${auditRequest.pageSize}`)
  }
}
