/** @format */

import { Component, EventEmitter, Input, Output } from "@angular/core"

@Component({
	selector: "app-custom-date",
	templateUrl: "./custom-date.component.html",
	styleUrls: ["./custom-date.component.css"],
})
export class CustomDateComponent {
	@Output() onSelectDate: EventEmitter<any> = new EventEmitter<any>()
	@Input() isDisabled?: boolean = false
	@Input() placeholder?: string = "YYYY-MM-DD"
	@Input() id?: string = "schedule-date"
	@Input() error: boolean = false

	datePickerValue: string = ""
	@Input() dateFormatted?: string = ""
	prevDate: any = ""

	formatDate() {
		if (!this.datePickerValue) {
			this.dateFormatted = ""
		} else {
			this.dateFormatted = new Date(
				this.datePickerValue.replace(/-/g, "/"),
			).toLocaleDateString("en-us", {
				year: "numeric",
				month: "short",
				day: "numeric",
			})
		}
		this.onSelectDate.emit(this.dateFormatted)
	}

	getCurrentDate() {
		//return new Date().toISOString().split("T")[0]
		const currentDateUTC = new Date();
		const offset = -5 * 60 * 60 * 1000; // Offset is in milliseconds
		const currentDateEST = new Date(currentDateUTC.getTime() + offset);
		const formattedDate = currentDateEST.toISOString().replace('Z', '-05:00');
		return formattedDate.split("T")[0]
	}

	getFormattedDate() {
		if (this.dateFormatted != "") {
			this.prevDate = this.dateFormatted
		}

		if (this.isDisabled) {
			this.resetValues()
		} else {
			this.dateFormatted = this.prevDate
		}
		return this.dateFormatted
	}
	resetValues() {
		this.datePickerValue = ""
		this.dateFormatted = ""
	}
}
