import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor( private router: Router) {}

  logout() {
    localStorage.removeItem("lastName");
    localStorage.removeItem("firstName");
    localStorage.removeItem("token");
    localStorage.removeItem("companyId");
    // Only navigate if it's not on one of the specified routes
    if (
      !window.location.hash.includes('#/auth/resetPassword') &&
      !window.location.hash.includes('#/account/verify-email')
    ) {
      this.router.navigate(["auth/login"])
    }
  }

  justEntered = true;

  ngOnInit(): void {
    const storedDateStr = localStorage.getItem("beat");
    if (storedDateStr && this.justEntered) {
      // Check timers
      const currentDateStr = new Date().toString();
      const storedDate: any = new Date(storedDateStr);
      const currentDate: any = new Date(currentDateStr);

      const timeDifference = currentDate - storedDate;

      if (
        window.location.hash.includes('#/auth/resetPassword') ||
        window.location.hash.includes('#/account/verify-email')
      ) {
        this.justEntered = false;
      } else if (timeDifference > 1000) {
        this.logout();
      }
    }

    this.justEntered = false;
    // Doing it here because it stops ticking if you reload too fast
    localStorage.setItem("beat", new Date().toString());
    this.startTimer();
  }

  startTimer() {
    const item = setInterval(() => {
      const currentDate = new Date().toString();
      localStorage.setItem("beat", currentDate);
    }, 700);
  }

  title = 'jps-lightyear-portal';
}
