/** @format */

import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { Plant } from "src/app/models/plant.model"
import { CompanyService } from "src/app/services/company/company.service"
import { PlantService } from "src/app/services/plant/plant.service"
import { DialogService } from "src/app/services/modal/dialog.service"
import { ToastService } from "src/app/services/toast/toast.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"

@Component({
	selector: "app-edit-admin-user",
	templateUrl: "./edit-admin-user.component.html",
	styleUrls: ["./edit-admin-user.component.css"],
})
export class EditAdminUserComponent {
	selectedValues = {
		company: {
			companyId: 0,
			companyName: "",
		},
		plant: {
			plantId: 0,
			plantName: "",
		},
	}
	selectedCompany = {
		companyId: 0,
		companyName: "",
		isDeleted: false,
	}

	updateAdmin = false

	username: String = ""
	userEmail: String = ""
	userId: number = -1
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public toastService: ToastService,
		public dialogService: DialogService,
		private companyService: CompanyService,
		private plantService: PlantService,
		private errorService: ErrorHandlerService,
	) {}

	editAdminRequest = {
		ids: [] as number[],
	}

	errorMessage: any = {
		success: true,
		message: "",
	}

	currentRole: any
	companyId: any

	companies: Company[] = []
	activePlants: Plant[] = []
	selectedPlants: any = []
	activeStep = 1

	firstRender = false
	searchText: String = ""
	

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl("settings/user", {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
			},
		})
	}

	setSelectedCompany(selected: any) {
		this.selectedCompany = selected
	}

	enableNextBtn() {
		if (
			this.selectedPlants.length != 0 &&
			this.selectedCompany.companyId > 0
		) {
			return true
		}
		return false
	}

	isSelectedPlant(plant: Plant) {
		var index = this.selectedPlants.findIndex(
			(x: Plant) => x.plantId == plant.plantId,
		)
		return index >= 0
	}

	showSaveModal() {
		this.updateAdmin = true
	}

	addPlant(data: Plant) {
		this.selectedPlants.push(data)
		this.editAdminRequest.ids.push(data.plantId)
	}

	removePlant(index: number) {
		this.selectedPlants.splice(index, 1)
		this.editAdminRequest.ids.splice(index, 1)
	}

	ngOnInit(): void {
		this.currentRole = localStorage.getItem("role")
		
		this.userEmail = history?.state?.data?.email
		this.username =
			history?.state?.data?.firstName +
			' ' +
			history?.state?.data?.lastName
		this.userId = history?.state?.data?.userId
		this.selectedValues = {
			company: history?.state?.selectedValues?.company,
			plant: history?.state?.selectedValues?.plant,
		}

		let companyIdToUse: any

		this.setSelectedCompany(this.selectedValues.company)

		if (this.currentRole === "Admin") {
			this.companyId = localStorage.getItem("companyId")
			companyIdToUse = this.companyId
		}

		if (this.currentRole === "Super Admin") {
			companyIdToUse = this.selectedCompany.companyId.toString()
		}
		
		this.plantService.retrieveCompanyPlants(companyIdToUse).subscribe({
			next: (plants) => {
				this.activePlants = plants
			},
			error: (response) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(response)
			},
		})

		this.plantService.getPlantsAssociatedWithAdminSelected(this.userId).subscribe({
			next: (plants) => {
				this.selectedPlants = plants
				this.selectedPlants.map((data:Plant) => this.editAdminRequest.ids.push(data.plantId))
			},
			error: (response) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(response)
			},
		})
		
		this.companyService.retrieveCompanies().subscribe({
			next: (companies) => {
				this.companies = companies
			},
			error: (response) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(response)
			},
		})
	}

	editAdminUser() {
		this.companyService.editAdminUser(this.userId,this.editAdminRequest).subscribe({
			next: (response) => {
				this.transferData()
				this.toastService.showToast = true
				this.toastService.toastText = "User successfully updated!"
				setTimeout(() => {
					this.toastService.showToast = false
				}, 5000)
			},
		    error: (response) => {
				this.errorService.handleError(response, 'Error updating admin user');
		    },
		})
	}
}
