<!-- @format -->

<div class="card custom-section">
	<h1 class="section-title">Edit Data Point</h1>
	<p class="subtext">Fill out the data point details using the form below.</p>
	<form
		#form="ngForm"
		style="margin-top: 2rem"
		(ngSubmit)="showModal = true">
		<!-- COMPANY -->
		<div *ngIf="currentRole === 'Super Admin'">
			<label
				for=""
				class="custom-label required"
				>Company Name</label
			>
			<app-dropdown
				[disabled]="true"
				selectedItem="{{ selectedValues['company'] }}"
				label="{{
					selectedValues['company'].companyName
				}}"></app-dropdown>
		</div>

		<div
			class="form-group"
			*ngIf="currentRole === 'Super Admin'"></div>
		<div class="form-group col-2">
			<!-- PLANT -->
			<div>
				<label
					for=""
					class="custom-label required"
					>Plant Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues['plant'] }}"
					label="{{
						selectedValues['plant'].plantName
					}}"></app-dropdown>
			</div>

			<!-- UNIT -->
			<div>
				<label
					for=""
					class="custom-label required"
					>Unit Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues['unit'] }}"
					label="{{
						selectedValues['unit'].unitName
					}}"></app-dropdown>
			</div>
		</div>

		<div class="form-group col-3">
			<!-- DATA POINT NAME -->
			<div>
				<label
					for="data_point_name"
					class="custom-label required"
					>Data Point Name</label
				>

				<input
					type="text"
					class="custom-input"
					name="data_point_name"
					id="data_point_name"
					[(ngModel)]="editDataPointRequest.dataPointName" />
				<!-- <div *ngIf="!errorMessage.success" class="error">
					<img src="../../assets/alert.svg" alt="" />
					<p>
						{{ errorMessage.message }}
					</p>
				</div> -->
			</div>
			<!-- DATA TYPE -->
			<div>
				<label
					for="unit"
					class="custom-label required"
					>Data Type</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ editDataPointRequest.dataType }}"
					label="{{ editDataPointRequest.dataType }}"></app-dropdown>
			</div>
			<!-- UNIT OF MEASUREMENT -->
			<div *ngIf="editDataPointRequest.dataType === 'Number'">
				<label
					for="unit"
					class="custom-label required"
					>Unit of Measurement</label
				>
				<input
					type="text"
					class="custom-input"
					placeholder="Enter unit of measurement"
					[ngClass]="
						editDataPointRequest.dataType !== 'Number'
							? 'disabled-input'
							: ''
					"
					[readOnly]="
						editDataPointRequest.dataType !== 'Number'
							? true
							: false
					"
					name="unit"
					id="unit"
					[(ngModel)]="editDataPointRequest.unitOfMeasurement" />
				<!-- <div *ngIf="!errorMessage.success" class="error">
					<img src="../../assets/alert.svg" alt="" />
					<p>
						{{ errorMessage.message }}
					</p>
				</div> -->
			</div>
		</div>

		<div
			class="form-group col-2"
			*ngIf="editDataPointRequest.dataType === 'Number'">
			<!-- MIN -->
			<div>
				<label
					for="data_point_min"
					class="custom-label required"
					>Data Point Min</label
				>
				<input
					type="text"
					class="custom-input"
					name="data_point_min"
					placeholder="Enter min"
					id="data_point_min"
					[(ngModel)]="editDataPointRequest.dataPointMin" />
				<!-- <div *ngIf="!errorMessage.success" class="error">
					<img src="../../assets/alert.svg" alt="" />
					<p>
						{{ errorMessage.message }}
					</p>
				</div> -->
			</div>
			<!-- MAX -->
			<div>
				<label
					for="data_point_max"
					class="custom-label required"
					>Data Point Max</label
				>
				<input
					type="text"
					class="custom-input"
					name="data_point_max"
					id="data_point_max"
					placeholder="Enter max"
					[(ngModel)]="editDataPointRequest.dataPointMax" />
			</div>
		</div>

		<!-- DESCRIPTION -->
		<label
			for="desc"
			class="custom-label"
			>Description</label
		>
		<textarea
			name="desc"
			id="desc"
			cols="30"
			rows="10"
			placeholder="Type here"
			class="custom-input"
			[(ngModel)]="editDataPointRequest.dataPointDescription">
		</textarea>
		<div
			*ngIf="!errorMessage.success"
			class="error">
			<img
				src="../../assets/alert.svg"
				alt="" />
			<p>
				{{ errorMessage.message }}
			</p>
		</div>

		<input
			type="hidden"
			name="company_is_deleted"
			id="company_is_deleted"
			[(ngModel)]="editDataPointRequest.isDeleted" />
		<div class="btn-group">
			<button
				(click)="transferData()"
				class="btn-outline"
				routerLink="../..">
				Cancel
			</button>
			<button
				type="submit"
				class="btn-fill"
				[ngClass]="!activateSubmitBtn() ? 'disabled-btn' : ''">
				Update Data Point
			</button>
		</div>
	</form>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-save-modal
	*ngIf="showModal"
	(closeModal)="closeModal()"
	(onSave)="editDataPoint()"></app-save-modal>
