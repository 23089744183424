<!-- @format -->

<div class="card custom-section">
	<div class="header">
		<div>
			<h1 class="section-title">Add a Log Sheet</h1>
			<p class="subtext">
				Complete the steps below to create a new log sheet to be used.
			</p>
		</div>
		<button
			type="button"
			class="btn-text"
			(click)="transferData()">
			Cancel
		</button>
	</div>
	<!-- STEPPER -->
	<div class="custom-stepper">
		<div
			class="custom-stepper__step"
			(click)="setActiveStep(1)"
			[ngClass]="activeStep === 1 ? 'active' : ''">
			<span>STEP 1</span>
			<small>Details</small>
		</div>
		<div
			class="custom-stepper__step"
			(click)="enableNextBtn() ? setActiveStep(2) : null"
			[ngClass]="activeStep === 2 ? 'active' : ''">
			<span>STEP 2</span>
			<small>Layout</small>
		</div>
	</div>

	<!-- STEP 1 -->
	<div
		*ngIf="activeStep == 1"
		class="custom-stepper__step1">
		<div class="dropdown-group col-3">
			<div *ngIf="currentRole == 'Super Admin'">
				<label
					for=""
					class="custom-label required"
					>Company Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues.company.companyName }}"
					label="{{
						selectedValues.company.companyName
					}}"></app-dropdown>
				<div class="form-group"></div>
			</div>
			<div>
				<label
					for=""
					class="custom-label required"
					>Plant Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues.plant.plantName }}"
					label="{{ selectedValues.plant.plantName }}"></app-dropdown>
				<div class="form-group"></div>
			</div>
			<div>
				<label
					for=""
					class="custom-label required"
					>Unit Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues.unit.unitName }}"
					label="{{ selectedValues.unit.unitName }}"></app-dropdown>
				<div class="form-group"></div>
			</div>
		</div>

		<label
			for="logsheet_name"
			class="custom-label required"
			>Log Sheet Name</label
		>
		<input
			type="text"
			class="custom-input"
			name="logsheet_name"
			id="logsheet_name"
			maxlength="50"
			minlength="3"
			[(ngModel)]="addLogsheetRequest.logSheetName" />
	</div>

	<!-- STEP 2 -->
	<div
		*ngIf="activeStep == 2"
		class="custom-stepper__step2">
		<div class="custom-stepper__step2-container">
			<!-- SECTION 1 -->
			<div class="section1">
				<div class="mr-28">
					<h3>Data Points</h3>
					<p class="instructions">
						Select checkbox to add data point to the Log Sheet
					</p>
				</div>
				<div class="search-bar mr-28">
					<img
						src="../assets/search.svg"
						alt="search" />
					<input
						type="text"
						placeholder="Search"
						[(ngModel)]="searchText" />
				</div>
				<!-- DATA POINTS -->
				<div
					class="data-point-list"
					*ngIf="activeDataPoints.length > 0">
					<app-checked-card
						*ngFor="
							let data of activeDataPoints
								| search : searchText : 'dataPointName';
							let i = index
						"
						[attr.data-index]="i"
						[title]="data?.dataPointName"
						[description]="data?.dataPointDescription"
						(onClickIcon)="
							isSelectedDataPoint(data)
								? null
								: addDataPoint(data)
						"
						[isSelected]="isSelectedDataPoint(data)"
						[icon]="
							isSelectedDataPoint(data)
								? 'checkbox-selected.svg'
								: 'checkbox-unselected.svg'
						"></app-checked-card>
				</div>
			</div>
			<!-- SECTION 2 -->
			<div class="section2">
				<h3>{{ addLogsheetRequest.logSheetName }}</h3>

				<ul>
					<li class="instructions">
						Click “X” to remove data point from the Log Sheet
					</li>
					<li class="instructions">
						Click and hold the handles beside the “X” to reorder
						Data Points
					</li>
				</ul>
				<!-- SELECTED DATA POINTS -->
				<div
					class="data-point-list"
					*ngIf="selectDataPoints.length > 0"
					cdkDropList
					cdkDropListData="selectDataPoints"
					(cdkDropListDropped)="drop($event)">
					<app-checked-card
						cdkDrag
						*ngFor="let data of selectDataPoints; let i = index"
						[attr.data-index]="i"
						[title]="data?.dataPointName"
						[description]="data?.dataPointDescription"
						[isDraggable]="true"
						[isSelected]="true"
						(onClickIcon)="removeDataPoint(i)"
						icon="checkbox-remove.svg"></app-checked-card>
				</div>
			</div>
		</div>
	</div>

	<div class="step__btn-group">
		<button
			type="button"
			class="btn-outline"
			(click)="activeStep == 1 ? transferData() : setActiveStep(1)">
			Back
		</button>
		<button
			[ngClass]="!enableNextBtn() ? 'disabled-btn' : ''"
			[disabled]="!enableNextBtn()"
			(click)="activeStep == 1 ? setActiveStep(2) : showSaveModal()"
			type="submit"
			class="btn-fill">
			{{ activeStep == 1 ? "Next" : "Add Log Sheet" }}
		</button>
	</div>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-save-modal
	*ngIf="dialogService.showModal"
	(onSave)="addLogsheet()"></app-save-modal>
