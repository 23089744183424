import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from 'src/app/services/modal/dialog.service';

@Component({
  selector: 'app-deactivate-modal',
  templateUrl: './deactivate-modal.component.html',
  styleUrls: ['./deactivate-modal.component.css']
})
export class DeactivateModalComponent {
@Input() title: string = "";
	@Input() message1: string = ""
	@Input() message2: string = ""

	@Input() actionButtonTitle: string = "Deactivate"
	// Are you sure you want to save changes?
	@Output() onSave: EventEmitter<any> = new EventEmitter<any>()
	@Output() onCancel: EventEmitter<any> = new EventEmitter<any>()
	constructor(public dialogService: DialogService) {}
	toggleModal(): void {
		this.onCancel.emit()
	}

	action(): void {
		this.toggleModal()
		this.onSave.emit()
	}
}
