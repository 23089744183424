import { Component, ChangeDetectorRef } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Company } from 'src/app/models/company.model'
import { Plant } from 'src/app/models/plant.model'
import { Unit } from 'src/app/models/unit.model'
import { CompanyService } from 'src/app/services/company/company.service'
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service'
import { PlantService } from 'src/app/services/plant/plant.service'
import { ToastService } from 'src/app/services/toast/toast.service'
import { UnitService } from 'src/app/services/unit/unit.service'

@Component({
	selector: 'app-unit',
	templateUrl: './unit.component.html',
	styleUrls: ['./unit.component.css'],
})
export class UnitComponent {
	activeItem = 0
	adminCompany: any = []
	assignedPlants: any = []
	companies: Company[] = []
	currentRole: any = ''
	firstRender: boolean = false
	plants: Plant[] = []
	resetPlant: boolean = false
	selectedUnit = ''
	showCompanies = false
	showDeactivateMenu = false
	showMenu = false
	showPlants = false
	showToast: boolean = false
	toastText: string = ''
	units: Unit[] = []

	//pagination data
	currentPage: number = 1
	data: Plant[] = []
	pageSize: number = 7
	totalPages: number = 1
	loopArray: number[] = []

	deactivateText = {
		title: 'Deactivate Unit',
		message1: '',
		message2: 'This action is irreversible. Do you wish to proceed?',
	}

	emptyStateCompanies = {
		imgPath: '../../assets/unit.svg',
		message1: 'Select a company to see associated units listed here',
		message2: '',
	}
	emptyStatePlants = {
		imgPath: '../../assets/unit.svg',
		message1: 'Select a plant to see associated units listed here',
	}
	emptyState = {
		imgPath: '../../assets/unit.svg',
		message1: 'No units to display',
		message2: 'Created units will appear here.',
	}

	errorMessage = {
		success: true,
		message: '',
	}

	get values() {
		return {
			data: this.data,
			currentPage: this.currentPage,
			totalPages: this.totalPages,
			loadData: this.loadData,
			loopArray: this.loopArray,
		}
	}

	selectedValues = {
		company: {
			companyId: 0,
			companyName: '',
			isDeleted: false,
		},
		plant: {
			plantId: 0,
			plantName: '',
			isDeleted: false,
		},
	}

	unitDetails: Unit = {
		unitId: 0,
		unitName: '',
		isDeleted: false,
		plant: [],
		companyId: 0,
	}

	// styles
	defaultStyle = {
		flex: 1,
	}

	specialStyle = {
		flex: 0.5,
	}

	constructor(
		private route: ActivatedRoute,
		private plantService: PlantService,
		private companyService: CompanyService,
		private unitService: UnitService,
		private router: Router,
		public toastService: ToastService,
		private errorService: ErrorHandlerService,
		private changeDetector: ChangeDetectorRef,
	) {}

	activateAddUnitBtn() {
		if (this.currentRole === 'Super Admin') {
			if (
				this.selectedValues.company.companyId === 0 ||
				this.selectedValues.plant.plantId === 0
			) {
				return false
			} else if (
				this.selectedValues.company.isDeleted ||
				this.selectedValues.plant.isDeleted
			) {
				return false
			} else {
				return true
			}
		} else if (this.currentRole === 'Admin') {
			if (
				this.selectedValues.plant.plantId === 0 ||
				this.selectedValues.plant.plantId == null ||
				this.selectedValues.plant.plantId == undefined ||
				this.selectedValues.plant.plantName.length === 0 ||
				this.selectedValues.plant.isDeleted
			) {
				return false
			} else {
				return true
			}
		}
		return true
	}

	handleDeactivate() {
		this.unitService.deactivateUnit(this.selectedUnit).subscribe({
			next: (response) => {
				this.handleSuccessToast('Deactivate_Unit')
				this.setPlant(this.selectedValues.plant)
			},
			error: (error) => {
				this.errorService.handleError(error, 'Error deactivating unit')
			},
		})
	}

	handleSuccessToast(state: any) {
		if (state === 'Edit_Unit') {
			this.toastService.toastText = 'Unit successfully updated!'
		}
		if (state === 'Add_Unit') {
			this.toastService.toastText = 'Unit successfully added!'
		}
		if (state === 'Deactivate_Unit') {
			this.toastService.toastText = 'Unit successfully deactivated!'
		}
		this.toastService.showToast = state || false
		setTimeout(() => {
			this.toastService.showToast = false
		}, 5000)
	}

	goToDynamic() {
		this.router.navigateByUrl('add-unit', {
			state: this.selectedValues.company,
		})
	}

	isSelectionByUser(): boolean {
		return !(this.changeDetector as any).destroyed
	}

	loadData(plantId: number) {
		const unitRequest = {
			plantId: plantId.toString(),
			pageNumber: this.currentPage,
			pageSize: this.pageSize,
		}

		this.unitService.retrieveUnitPlantByPage(unitRequest).subscribe({
			next: (result: any) => {
				this.units = result.item1
				this.data = result.item1
				this.totalPages = result.item2
				this.updateLoopArray()
			},
			error: (err) => this.errorService.handleRetrievalErrors(err),
		})
	}

	onPageChange(pageNumber: any) {
		this.currentPage = pageNumber
		this.loadData(this.selectedValues.plant.plantId)
	}

	showDeactivateModal(name: string, unitId: any) {
		this.selectedUnit = unitId

		this.deactivateText.message1 =
			'You are about to deactivate ' +
			name +
			' in Lightyear. Doing this will deactivate all objects related to this unit.'
		this.showDeactivateMenu = true
	}

	setPlant(item: any) {
		this.selectedValues.plant = {
			plantId: item.plantId,
			plantName: item.plantName,
			isDeleted: item.isDeleted,
		}

		this.loadData(this.selectedValues.plant.plantId)
	}

	setSelectedCompany(item: any) {
		if (item.companyId === this.selectedValues.company.companyId) return
		const initalState = { plantId: 0, plantName: '', isDeleted: false }
		if (
			this.isSelectionByUser() &&
			(this.selectedValues.plant.plantId > 0 || this.plants.length > 0)
		) {
			this.resetPlant = true
			this.plants = []
			this.selectedValues.plant = { ...initalState }
		}
		this.units = []
		this.retrievePlantsByCompanyId(item.companyId.toString())

		this.selectedValues.company = item
		this.resetPlant = false
	}

	retrievePlantsByCompanyId(id: string) {
		this.plantService.retrieveCompanyPlants(id).subscribe({
			next: (response) => {
				const plantsArrayWithoutCompany = response.map(
					({ plantId, plantName, isDeleted }) => ({
						plantId,
						plantName,
						isDeleted,
					}),
				)

				this.plants = plantsArrayWithoutCompany
			},
			error: (err) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(err)
			},
		})
	}

	setSelectedPlant(plantId: number, plantName: string) {
		this.loadData(plantId)
		this.selectedValues.plant = { plantId, plantName, isDeleted: false }
	}

	toggleMenu(plantId: any) {
		this.activeItem = plantId
		this.showMenu = !this.showMenu
	}

	toggleSelect() {
		this.showCompanies = !this.showCompanies
	}

	toggleSelectCompany() {
		this.showCompanies = !this.showCompanies
	}

	toggleSelectPlant() {
		this.showPlants = !this.showPlants
	}

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl('settings/units', {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
			},
		})
	}

	updateLoopArray() {
		this.loopArray = new Array(this.totalPages)
			.fill(0)
			.map((_, index) => index + 1)
	}

	ngOnInit(): void {
		this.handleSuccessToast(history.state.prevPage)
		this.firstRender = history.state.firstRender
		this.currentRole = localStorage.getItem('role')
		if (this.firstRender) {
			this.selectedValues = { ...history.state.selectedValues }
			this.retrievePlantsByCompanyId(
				this.selectedValues.company.companyId.toString(),
			)
			this.setSelectedCompany(this.selectedValues.company)
			this.setSelectedPlant(
				this.selectedValues.plant.plantId,
				this.selectedValues.plant.plantName,
			)
			this.firstRender = false
		}

		history.replaceState({}, '')

		try {
			const storedAssignment = localStorage.getItem('assignedPlants')
			if (storedAssignment) {
				this.assignedPlants = JSON.parse(storedAssignment)
			} else {
				console.error(
					"No data found in local storage for key 'assignedPlants' ",
				)
			}
		} catch (error) {
			console.error('Error parsing JSON data from local storage:', error)
		}

		if (this.currentRole === 'Admin') {
			this.assignedPlants.map((assignment: Plant) =>
				this.plants.push(assignment),
			)
		} else {
			this.companyService.retrieveCompanies().subscribe({
				next: (companies) => {
					this.companies = companies
				},
				error: (response) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(response)
				},
			})
		}
	}
}
