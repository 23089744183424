<!-- @format -->

<div class="unit-wrapper">
	<div class="unit-navigation">
		<img
			src="../../assets/back-arrow-icon.svg"
			alt="back navigation" />
		<div class="prev-page">Units</div>
		<img
			src="../../assets/forward-arrow-icon.svg"
			alt="back navigation" />
		<div class="forward-page">Add a Unit</div>
	</div>
	<div class="card add-unit">
		<h1 class="section-title">Add a Unit</h1>
		<p class="subtext">Fill out unit details using the form below.</p>
		<form
			(ngSubmit)="
				addUnit()
			"
			#form="ngForm"
			style="margin-top: 2rem">
			<app-custom-dropdown
				*ngIf="currentRole === 'Super Admin'"
				(setSelected)="setCompany($event)"
				[label]="'Company Name'"
				[selectedItem]="selectedValues.company.companyName"
				[items]="companies"
				[disabled]="true" />

			<div class="dropdown-container">
				<app-custom-dropdown
					(setSelected)="setPlant($event)"
					style="flex: 1"
					[label]="'Plant Name'"
					[selectedItem]="selectedValues.plant.plantName"
					[items]="plants"
					[disabled]="true" />
				<div class="unit-name">
					<label
						for="unit_name"
						class="custom-label required"
						>Unit Name</label
					>
					<input
						[(ngModel)]="unitName"
						type="text"
						class="custom-input"
						name="unit_name"
						id="unit_name" />
					<span
						*ngIf="!errorMessage.success"
						class="unit-error">
						<img
							class="unit-icon"
							src="../../assets/error-icon.svg"
							alt="dropdown icon" />
						<div class="unit-error-text">
							{{ errorMessage.message }}
						</div>
					</span>
				</div>
			</div>

			<div class="btn-group">
				<button
					class="btn-outline"
					(click)="transferData()">
					Cancel
				</button>
				<button
					[ngClass]="
						unitName.length === 0 ? 'btn-fill disabled' : 'btn-fill'
					"
					type="submit">
					Add Unit
				</button>
			</div>
		</form>
	</div>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
