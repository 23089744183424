/** @format */

import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "search",
	pure: true,
})
export class SearchPipe implements PipeTransform {
	transform(items: any[], searchText: String, property: string): any[] {
		if (!items) return []

		if (!searchText) return items

		searchText = searchText.toLocaleLowerCase()

		return items.filter((data) => {
			return data[property].toLocaleLowerCase().includes(searchText)
		})
	}
}
