<!-- @format -->

<div class="card custom-section">
	<div class="header">
		<h1 class="section-title">Assign: {{logSheetName}}</h1>
		<p class="subtext">See and select Available Assignments below.</p>
	</div>

	<div class="form-container-groups">
		<div class="groups-select">
			<!-- SECTION 1 -->
			<div class="section1">
				<div class="mr-28">
					<h3>Available Assignments</h3>
					<p class="instructions">
						Select checkbox to assignment to the Log Sheet
					</p>
				</div>
				<div class="search-bar mr-28">
					<img
						src="../assets/search.svg"
						alt="search" />
					<input
						type="text"
						placeholder="Search"
						[(ngModel)]="searchText" />
				</div>
				<!-- DATA POINTS -->
				<div
					class="group-list"
					*ngIf="activeGroups.length > 0">
					<app-checked-card
						*ngFor="
							let data of activeGroups
								| search : searchText : 'groupName';
							let i = index
						"
						[attr.data-index]="i"
						[title]="data?.groupName"
						(onClickIcon)="
							isSelectedGroup(data) ? null : addGroup(data)
						"
						[isSelected]="isSelectedGroup(data)"
						[icon]="
							isSelectedGroup(data)
								? 'checkbox-selected.svg'
								: 'checkbox-unselected.svg'
						"></app-checked-card>
				</div>
			</div>
			<!-- SECTION 2 -->
			<div class="section2">
				<h3>Current Assignments</h3>
				<p class="instructions">
					Click “X” to remove assignment from the Log Sheet
				</p>
				<!-- SELECTED DATA POINTS -->
				<div
					class="group-list"
					*ngIf="selectedGroups.length > 0">
					<app-checked-card
						*ngFor="let data of selectedGroups; let i = index"
						[attr.data-index]="i"
						[title]="data?.groupName"
						[isSelected]="true"
						(onClickIcon)="removeGroup(i)"
						icon="checkbox-remove.svg">
					</app-checked-card>
				</div>
			</div>
		</div>
		<div class="btn-group">
			<button
				type="button"
				class="btn-outline"
				(click)="transferData()">
				Cancel
			</button>
			<button
				[ngClass]="!enableSubmitBtn() ? 'disabled-btn' : ''"
				[disabled]="!enableSubmitBtn()"
				(click)="!enableSubmitBtn() ? null : assignLogsheet()"
				type="submit"
				class="btn-fill">
				Assign Log Sheet
			</button>
		</div>
	</div>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<!-- <app-save-modal
	*ngIf="dialogService.showModal"
	(onSave)="assignLogsheet()"></app-save-modal> -->
