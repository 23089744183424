<!-- @format -->

<div class="custom-section card">
	<div class="section-header">
		<div>
			<h1 class="section-title">Data Point</h1>
		</div>
		<div>
			<button
				type="button"
				[ngClass]="!activateAddDataPointBtn() ? 'disabled-btn' : ''"
				[routerLink]="
					!activateAddDataPointBtn() ? [] : ['add-data-point']
				"
				[state]="selectedValues">
				Add a Data Point
			</button>
		</div>
	</div>
	<!-- DROPDOWNS -->
	<div class="dropdown-group">
		<app-dropdown
			*ngIf="currentRole === 'Super Admin'"
			[disabled]="false"
			[options]="companies"
			selectedItem="{{ selectedValues.company }}"
			(onSelectOption)="retrievePlantsByCompanyId($event)"
			[fields]="['companyId', 'companyName']"
			[label]="
				selectedValues.company.companyName.length === 0
				? 'Choose Company'
				: selectedValues.company.companyName">
		</app-dropdown>
		<app-dropdown
			[disabled]="
				currentRole === 'Super Admin' && selectedValues.company.companyId === 0
					? true
					: false
			"
			[options]="plants"
			selectedItem="{{ selectedValues.plant }}"
			(onSelectOption)="retrieveUnitsByPlantId($event)"
			[fields]="['plantId', 'plantName']"
			[label]="
				resetPlant || selectedValues.plant.plantId == 0
					? 'Choose Plant'
					: selectedValues.plant.plantName
			"></app-dropdown>
		<app-dropdown
			[disabled]="selectedValues.plant.plantId === 0 ? true : false"
			[options]="units"
			selectedItem="{{ selectedValues.unit }}"
			(onSelectOption)="retrieveDataPointsByUnitId($event)"
			[fields]="['unitId', 'unitName']"
			[label]="
				resetUnit || selectedValues.unit.unitId == 0
					? 'Choose Unit'
					: selectedValues.unit.unitName
			"></app-dropdown>
	</div>

	<table class="custom-table">
		<thead>
			<tr>
				<th>Data Point</th>
				<th>Data Type</th>
				<th>Status</th>
				<th>
					<img
						src="../../assets/dotted-menu.svg"
						alt="menu icon" />
				</th>
			</tr>
		</thead>
		<tbody *ngFor="let data of dataPoints">
			<tr>
				<td>
					<div>
						<p class="title">{{ data.dataPointName }}</p>
						<p class="description">
							{{ data.dataPointDescription }}
						</p>
					</div>
				</td>
				<td>{{ data.dataType }}</td>
				<td>{{ data.isDeleted ? "Inactive" : "Active" }}</td>
				<td>
					<img
						*ngIf="!data.isDeleted"
						src="../../assets/dotted-menu.svg"
						alt="menu icon"
						(click)="toggleMenu(data.dataPointId)"
						style="cursor: pointer" />

					<div
						[ngClass]="
							!showMenu || data.dataPointId != activeItem
								? 'd-none'
								: ''
						"
						class="custom-table__menu">
						<div>
							{{ data.dataPointName }}
						</div>

						<a
							[routerLink]="[
								'/settings',
								'data-points',
								'edit-data-point',
								data.dataPointId
							]"
							[state]="selectedValues">
							<img
								src="../../assets/edit-pencil.svg"
								alt="menu icon" />
							<span>Edit Data Point</span>
						</a>
						<div class="underline"></div>
						<div
							(click)="
								showDeactivateModal(
									data.dataPointName,
									data.dataPointId
								);
								showMenu = false
							">
							<img
								src="../../assets/delete.svg"
								alt="menu icon" />
							<span>Deactivate</span>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="center">
		<app-empty-state [data]="emptyStateCompanies" *ngIf="
			selectedValues.company.companyName.length === 0 &&
			currentRole === 'Super Admin'
		"/>
	</div>
	<div class="center">
		<!-- *ngIf="((currentRole === 'Super Admin' && selectedValues.company.companyId > 0) || (selectedValues.plant.plantId === 0 && currentRole === 'Admin') || (plants.length === 0 && currentRole === 'Admin')) && selectedValues.unit.unitId === 0 && dataPoints.length === 0" -->
		<app-empty-state [data]="emptyStatePlants" *ngIf="showPlantEmptyState()"/>
	</div>
	<div class="center">
		<app-empty-state [data]="emptyStateUnits" *ngIf="
			selectedValues.plant.plantId > 0 &&
			(selectedValues.unit.unitId === 0 || units.length === 0) &&
			dataPoints.length === 0
		"/>
	</div>
	<div class="center">
		<app-empty-state [data]="emptyStateDataPoints" *ngIf="
			this.selectedValues.unit.unitName.length > 0 &&
			dataPoints.length === 0 &&
			this.selectedValues.plant.plantId > 0
		"/>
	</div>
	<app-pagination [values]="values" (pageChange)="onPageChange($event)" *ngIf="totalPages > 0"/>
</div>

<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-deactivate-modal
	(onCancel)="showDeactivateMenu = false"
	*ngIf="showDeactivateMenu"
	[title]="deactivateText.title"
	[message1]="deactivateText.message1"
	[message2]="deactivateText.message2"
	(onSave)="handleDeactivate()" />
