<!-- @format -->

<div class="card custom-section">
	<h1 class="section-title">Add a company</h1>
	<p class="subtext">Fill out the company details using the form below.</p>
	<form
		#form="ngForm"
		(ngSubmit)="addCompany()">
		<div class="form-group add-company__form">
			<div class="fieldset">
				<label
					for="company_name"
					class="custom-label required"
					>Name</label
				>
				<input
					type="text"
					class="custom-input"
					name="company_name"
					id="company_name"
					[(ngModel)]="addCompanyRequest.companyName" />
			</div>
			<div class="fieldset">
				<label
					for="company_abbreviation"
					class="custom-label required"
					>Abbreviation</label
				>
				<input
					type="text"
					class="custom-input"
					name="company_abbreviation"
					id="company_abbreviation"
					[(ngModel)]="addCompanyRequest.companyAbbreviation" />
				<input
					type="hidden"
					name="company_is_deleted"
					id="company_is_deleted"
					[(ngModel)]="addCompanyRequest.isDeleted" />
			</div>
		</div>
		<div class="btn-group">
			<button
				class="btn-outline"
				routerLink="..">
				Cancel
			</button>
			<button
				type="submit"
				class="btn-fill">
				Add Company
			</button>
		</div>
	</form>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
