<!-- @format -->

<app-success-toast
	*ngIf="showToast"
	[text]="toastText" />

<div class="custom-section card add-unit">
	<form (ngSubmit)="updateUserDetails()">
		<div class="section-header">
			<div>
				<h1 class="section-title">Profile</h1>

				<span class="subtext"
					>Fill out the User Details using the form below.</span
				>
			</div>

			<button
				class="cancel-link"
				(click)="navToHome()">
				Cancel
			</button>
		</div>
		<div class="step-container">
			<div
				(click)="setCurrentStep(1)"
				class="step"
				[ngStyle]="{
					'border-color': currentStep === 2 ? '#6B7280' : '#353b40'
				}">
				<span class="step-title">Step 1</span>
				<span class="step-subtitle">Details</span>
			</div>
			<div
				(click)="
					handleChange() === 'Next' ? setCurrentStep(2) : validate()
				"
				class="step second-step"
				[ngStyle]="{
					'border-color': currentStep === 1 ? '#D5D8DD' : '#102F53',
					cursor:
						lastName.length > 0 &&
						firstName.length > 0 &&
						email.length > 0
							? 'pointer'
							: 'not-allowed'
				}">
				<span class="step-title">Step 2</span>
				<span class="step-subtitle">Password</span>
			</div>
		</div>

		<div
			class="inputs"
			*ngIf="currentStep === 1">
			<div class="input-container">
				<div class="input-item">
					<label
						class="custom-label required"
						for="first_name"
						>First Name</label
					>
					<input
						[(ngModel)]="firstName"
						type="text"
						class="custom-input"
						name="first_name"
						id="first_name" />
					<div
						*ngIf="showFirstNameError"
						class="error">
						<img
							src="../../assets/alert.svg"
							alt="" />
						<p>
							{{ firstNameError }}
						</p>
					</div>
				</div>
				<div class="input-item">
					<label
						class="custom-label required"
						for="last_name"
						>Last Name</label
					>
					<input
						[(ngModel)]="lastName"
						type="text"
						class="custom-input"
						name="last_name"
						id="last_name" />
					<div
						*ngIf="showLastNameError"
						class="error">
						<img
							src="../../assets/alert.svg"
							alt="" />
						<p>
							{{ lastNameError }}
						</p>
					</div>
				</div>
			</div>

			<div>
				<label
					class="custom-label required"
					for="email"
					>Email</label
				>
				<input
					[(ngModel)]="email"
					type="text"
					class="custom-input"
					name="email"
					id="email" />
				<div
					*ngIf="showEmailError"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ emailError }}
					</p>
				</div>
			</div>
			<button
				[ngClass]="
					lastName.length > 0 &&
					firstName.length > 0 &&
					email.length > 0
						? 'btn-fill next-button'
						: 'btn-fill disabled-btn next-button'
				"
				style="margin-left: auto"
				(click)="
					handleChange() === 'Next' ? setCurrentStep(2) : validate()
				"
				type="button">
				Next
			</button>
		</div>

		<div
			class="inputs"
			*ngIf="currentStep === 2">
			<div>
				<label
					class="custom-label"
					for="current_password"
					>Current Password</label
				>
				<input
					[(ngModel)]="currentPassword"
					type="password"
					class="custom-input"
					name="current_password"
					id="current_password" />
				<div
					*ngIf="showCurrentPasswordError"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ currentPasswordError }}
					</p>
				</div>
			</div>

			<div>
				<div class="new_password">
					<label
						class="custom-label"
						for="new_password">
						New Password
					</label>
					<button
						class="tool-tip"
						type="button"
						(click)="toggleOverlay()">
						ⓘ
					</button>
				</div>
				<div
					class="overlay"
					*ngIf="displayOverlay">
					<div class="overlay-header">
						New password must be at least 9 characters long and
						include the below elements:
					</div>
					<ul class="overlay-list">
						<li class="overlay-item">
							At least 1 uppercase letter
						</li>
						<li class="overlay-item">
							At least 1 lowercase letter
						</li>
						<li class="overlay-item">
							At least 1 special character (!@#$%^&amp;*()_+)
						</li>
						<li class="overlay-item">At least 1 number</li>
						<li class="overlay-item">No space</li>
					</ul>
				</div>

				<input
					[(ngModel)]="newPassword"
					type="password"
					class="custom-input"
					name="new_password"
					id="new_password" />
				<div
					*ngIf="showNewPasswordError"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ newPasswordError }}
					</p>
				</div>
			</div>

			<div>
				<label
					class="custom-label"
					for="confirm_password"
					>Confirm New Password</label
				>
				<input
					[(ngModel)]="confirmPassword"
					type="password"
					class="custom-input"
					name="confirm_password"
					id="confirm_password" />
				<div
					*ngIf="showConfirmPasswordError"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ confirmPasswordError }}
					</p>
				</div>
			</div>

			<div class="step__btn-group">
				<button
					type="button"
					(click)="setCurrentStep(1)"
					class="btn-outline next-button">
					Back
				</button>
				<button
					type="submit"
					class="btn-fill next-button">
					Update
				</button>
			</div>
		</div>
	</form>
</div>
