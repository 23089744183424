<!-- @format -->

<div class="card custom-section">
	<h1 class="section-title">Add a Non-Admin Group</h1>
	<p class="subtext">Fill out the non-admin details using the form below.</p>
	<form
		#form="ngForm"
		style="margin-top: 2rem"
		(ngSubmit)="addNonAdminGroupRequest.groupName.length > 3 && activateSubmitBtn() ? addNonAdminGroup() : null">
		<div
			*ngIf="currentRole === 'Super Admin'"
			style="margin-bottom: 24px">
			<label
				for=""
				class="custom-label required"
				>Company Name</label
			>
			<app-dropdown
				[disabled]="true"
				selectedItem="{{ selectedValues.company.companyName }}"
				label="{{ selectedValues.company.companyName }}"></app-dropdown>
		</div>

		<div class="form-group col-2">
			<div>
				<label
					for="plant_name"
					class="custom-label required"
					>Plant Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues.plant.plantName }}"
					label="{{ selectedValues.plant.plantName }}"></app-dropdown>
			</div>

			<div>
				<label
					for="group_name"
					class="custom-label required"
					>Non-Admin Group Name</label
				>
				<input
					type="text"
					class="custom-input"
					name="group_name"
					id="group_name"
					[(ngModel)]="addNonAdminGroupRequest.groupName" />
				<div
					*ngIf="!errorMessage.success"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ errorMessage.message }}
					</p>
				</div>
			</div>
		</div>
		<input
			type="hidden"
			name="group_is_deleted"
			id="group_is_deleted"
			[(ngModel)]="addNonAdminGroupRequest.isDeleted" />
		<div class="btn-group">
			<button
				class="btn-outline"
				(click)="transferData()">
				Cancel
			</button>
			<button
				[ngClass]="!activateSubmitBtn() ? 'disabled-btn' : ''"
				type="submit"
				class="btn-fill">
				Add Group
			</button>
		</div>
	</form>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
