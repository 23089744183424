<!-- @format -->
<div class="toast-container">
	<div [ngClass]="!this.toastService.isError ? 'success-toast' : 'error-toast'">
		<img
			*ngIf="!this.toastService.isError"
			src="../../../assets/tick.svg"
			alt=" icon" 
		/>
		<img
			*ngIf="this.toastService.isError"
			src="../../../assets/red-warning.svg"
			alt=" icon" 
		/>
		<span>{{ this.toastService.toastText }}</span>
	</div>
</div>
