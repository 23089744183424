/** @format */

import { Component } from "@angular/core"
import { Router } from "@angular/router"

@Component({
	selector: "app-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.css"],
})
export class SettingsComponent {
	title: string = "Settings"

	constructor(private router: Router) {}
	settingsMenus = [
		{
			subMenuTitle: "Company",
			subMenuIcon: "../../../assets/company.svg",
			subMenuDesc: "Add and remove compaines from Lightyear's system.",
			subMenuLink: "companies",
		},
		{
			subMenuTitle: "Plant",
			subMenuIcon: "../../../assets/plant.svg",
			subMenuDesc: "Add and remove plants from Companies.",
			subMenuLink: "plants",
		},
		{
			subMenuTitle: "Unit",
			subMenuIcon: "../../../assets/unit.svg",
			subMenuDesc: "Add and remove units from Plants.",
			subMenuLink: "units",
		},
		{
			subMenuTitle: "Data Point",
			subMenuIcon: "../../../assets/datapoint.svg",
			subMenuDesc:
				"Add or remove data points from units. These are individual fields that will be populated on data collection for a Unit.",
			// subMenuLink: "data-points/add-data-point",
			subMenuLink: "data-points",
		},
		{
			subMenuTitle: "Log Sheet",
			subMenuIcon: "../../../assets/logsheet.svg",
			subMenuDesc:
				"Configure log sheets with existing data points per Unit. Schedule and delete previously deleted log sheets.",

			// subMenuLink: "users/add-super-user",
			subMenuLink: "logsheets",
		},
		{
			subMenuTitle: "Group",
			subMenuIcon: "../../../assets/group.svg",
			subMenuDesc:
				"Create Groups to which Users and Log sheets can get assigned.",
			subMenuLink: "groups",
		},
		{
			subMenuTitle: "Modification Window",
			subMenuIcon: "../../../assets/edit-pencil.svg",
			subMenuDesc:
				"Edit the period of time which data other roles may edit the data captured.",
			subMenuLink: "modification-window",
		},
		{
			subMenuTitle: "User",
			subMenuIcon: "../../../assets/user-icon.svg",
			subMenuDesc:
				"Create user and grant either non-admin, admin or super admin permissions.",
			subMenuLink: "user",
		},
		{
			subMenuTitle: "Profile",
			subMenuIcon: "../../../assets/profile-icon-small.svg",
			subMenuDesc: "Edit Profile Details",
			subMenuLink: "profile",
		},
				{
			subMenuTitle: "Audit",
			subMenuIcon: "../../../assets/audit.svg",
			subMenuDesc: "View Audit table and Details",
			subMenuLink: "audit",
		}
	]

	ngOnInit(): void {
		const currentRole = localStorage.getItem("role")
		if (currentRole === "Admin") {
			let clonedSettings = [...this.settingsMenus]
			const result = clonedSettings.filter((item) => {
				if (
					item.subMenuTitle !== "Company" &&
					item.subMenuTitle !== "Plant"
				) {
					return item
				}
				return null
			})
			this.settingsMenus = result
		}
	}
}
