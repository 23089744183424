<div class="popup-container">
    <div class="popup-content">
        <img class="img" src="../../assets/success-tick.svg" alt=""/>
        <div class="login-btn">
            <p>{{message}}</p>
            <button type="button" class="login-button" (click)="action()">
                {{buttonTitle}}
            </button>
        </div>
    </div>
  </div>