<!-- @format -->

<div
	[ngClass]="{ 'custom-section': true, card: true }"
	*ngIf="!(overviewService.showOverview$ | async)">
	<!-- DROPDOWNS -->
	<div
		[ngClass]="{
			plant: currentRole !== 'Super Admin',
			'dropdown-group': true
		}">
		<app-dropdown
			*ngIf="currentRole === 'Super Admin'"
			[disabled]="false"
			[options]="companies"
			selectedItem="{{ selectedValues.company }}"
			(onSelectOption)="retrievePlantsByCompanyId($event)"
			[fields]="['companyId', 'companyName']"
			[label]="
				selectedValues.company.companyName.length === 0
					? 'Choose Company'
					: selectedValues.company.companyName
			"></app-dropdown>
		<app-dropdown
			[disabled]="
				currentRole === 'Super Admin' &&
				selectedValues.company.companyId === 0
					? true
					: false
			"
			[options]="plants"
			selectedItem="{{ selectedValues.plant }}"
			(onSelectOption)="retrieveUnitsByPlantId($event)"
			[fields]="['plantId', 'plantName']"
			[label]="
				selectedValues.plant.plantName === ''
					? 'Choose Plant'
					: selectedValues.plant.plantName
			">
		</app-dropdown>
	</div>
	<div class="unit-container">
		<a
			[routerLink]="['/home', 'overview', 'unit']"
			[state]="[unit, selectedValues]"
			class="unit-item"
			*ngFor="let unit of units"
			(click)="toggleOverview()">
			<div>
				<img
					src="../../../assets/unit-dark.svg"
					alt="icon" />
			</div>
			<div class="unit-name">{{ unit.unitName }}</div>
			<div class="logs">{{ unit?.logsheets?.length }} Log Sheets</div>
		</a>
	</div>
	<div
		[ngClass]="{
			'pagination-location': units.length < 4,
			pagination: true
		}"
		*ngIf="units.length !== 0">
		<button
			[style.opacity]="currentPage === 1 ? 0.5 : 1"
			type="button"
			(click)="performDecrement()">
			<img
				class="arrow flipped"
				src="../../assets/triangle-icon.svg"
				alt="navigate back" />
		</button>
		<div *ngFor="let item of loopArray; let i = index">
			<button
				*ngIf="
					item <= totalPages &&
					currentPage > 2 &&
					currentPage < totalPages - 1
						? item >= currentPage - 2 && item <= currentPage + 2
						: 'null' && currentPage === 1
						? item >= currentPage && item <= currentPage + 4
						: 'null' && currentPage === 2
						? item >= currentPage - 1 && item <= currentPage + 3
						: 'null' && currentPage === totalPages - 1
						? item >= currentPage - 3 && item <= currentPage + 1
						: 'null' && currentPage === totalPages
						? item >= currentPage - 4 && item <= currentPage
						: 'null'
				"
				[style]="currentPage === item ? selectedPage : ''"
				(click)="setCurrentPage(item)"
				class="page-number">
				{{ item }}
			</button>
		</div>
		<button
			[style.opacity]="currentPage === totalPages ? 0.5 : 1"
			type="button"
			(click)="performIncrement()">
			<img
				class="arrow"
				src="../../assets/triangle-icon.svg"
				alt="navigate forward" />
		</button>
	</div>

	<div
		class="center"
		*ngIf="
			selectedValues?.company?.companyName.length === 0 &&
			currentRole === 'Super Admin'
		">
		<app-empty-state [data]="emptyStateCompanies" />
	</div>
	<div
		class="center"
		*ngIf="showPlantEmptyState()">
		<app-empty-state [data]="emptyStatePlants" />
	</div>
	<div
		class="center"
		*ngIf="selectedValues?.plant?.plantId > 0 && units.length === 0">
		<app-empty-state [data]="emptyStateUnits" />
	</div>
</div>
<div *ngIf="overviewService.showOverview$ | async">
	<router-outlet></router-outlet>
</div>
