<!-- @format -->

<div class="custom-section card">
	<div class="section-header">
		<div>
			<h1 class="section-title">Audit</h1>
			<p class="subtext">Manage the audit table and view details.</p>
		</div>
	</div>

	<table
		class="custom-table"
		style="margin-top: 2rem">
		<thead>
			<tr>
				<th id="user-col">User</th>
				<th>Action</th>
				<th>Timestamp</th>
				<th>Details</th>
				<!-- <th>
                    <img src="../../assets/dotted-menu.svg" alt="menu icon" />
                </th> -->
			</tr>
		</thead>
		<tbody *ngFor="let audit of audits">
			<tr>
				<td>{{ audit.performedBy }}</td>
				<td>{{ audit.eventDetails }}</td>
				<td>{{ stringToDate(audit.eventTime) }}</td>
				<td class="details">
					<p
						(click)="
							showDetails = true;
							auditDetails = audit;
							findChangedValues(
								audit.prevStateObj,
								audit.currentStateObj
							)
						">
						View Details
					</p>
				</td>
			</tr>
		</tbody>
	</table>
	<div
		class="pagination"
		*ngIf="audits.length !== 0">
		<button
			[style.opacity]="currentPage === 1 ? 0.5 : 1"
			type="button"
			(click)="performDecrement()">
			<img
				class="arrow flipped"
				src="../../assets/triangle-icon.svg"
				alt="navigate back" />
		</button>
		<div *ngFor="let item of loopArray; let i = index">
			<button
				*ngIf="
					item <= totalPages &&
					currentPage > 2 &&
					currentPage < totalPages - 1
						? item >= currentPage - 2 && item <= currentPage + 2
						: 'null' && currentPage === 1
						? item >= currentPage && item <= currentPage + 4
						: 'null' && currentPage === 2
						? item >= currentPage - 1 && item <= currentPage + 3
						: 'null' && currentPage === totalPages - 1
						? item >= currentPage - 3 && item <= currentPage + 1
						: 'null' && currentPage === totalPages
						? item >= currentPage - 4 && item <= currentPage
						: 'null'
				"
				[style]="currentPage === item ? selectedPage : ''"
				(click)="setCurrentPage(item)"
				class="page-number">
				{{ item }}
			</button>
		</div>
		<button
			[style.opacity]="currentPage === totalPages ? 0.5 : 1"
			type="button"
			(click)="performIncrement()">
			<img
				class="arrow"
				src="../../assets/triangle-icon.svg"
				alt="navigate forward" />
		</button>
	</div>
</div>
<app-audit-modal
	(onCancel)="showDetails = false"
	*ngIf="showDetails"
	[data]="auditDetails"
	[values]="differences"></app-audit-modal>
