import { SearchPipe } from "src/app/pipes/search.pipe"
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Logsheet } from 'src/app/models/logsheet.model';
import { LogsheetService } from 'src/app/services/logsheet/logsheet.service';
import { OverviewService } from "src/app/services/overview/overview.service";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent {
  constructor(private route: ActivatedRoute,
              private router: Router,
              private logsheetService: LogsheetService,
			  public overviewService: OverviewService

              ) {}
  	selectedValues = {
		company: {
			companyId: 0,
			companyName: "Company",
		},
		plant: {
			plantId: 0,
			plantName: "Plant",
		}
	}
  activeLink = false
searchText: String = ""
firstRender: boolean = false;
	transferData() {
		this.firstRender = true
		this.router.navigateByUrl("home", {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
			},
		})
	}
	closeOverview() {
		this.overviewService.toggleOverview()
	}
  unit: any;
  logsheets: Logsheet[] = []
  unitName: any;
  ngOnInit(){
    this.unitName = history.state[0].unitName
    this.logsheets = history.state[0].logsheets
	this.logsheets.sort((a, b) => {
		const nameA = a.logSheetName.toUpperCase();
		const nameB = b.logSheetName.toUpperCase();
		if (nameA > nameB) {
			return 1;
		}
		if (nameA < nameB) {
			return -1; 
		}
		return 0; 
		});
    this.selectedValues.company = history.state[1]?.company
    this.selectedValues.plant = history.state[1]?.plant
  }
}
