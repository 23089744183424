/** @format */

import { Group } from "src/app/models/group.model"
import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { SearchPipe } from "src/app/pipes/search.pipe"
import { ToastService } from "src/app/services/toast/toast.service"
import { GroupService } from "src/app/services/group/group.service"
import { moveItemInArray } from "@angular/cdk/drag-drop"
import { DialogService } from "src/app/services/modal/dialog.service"
import { LogsheetService } from "src/app/services/logsheet/logsheet.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"

@Component({
	selector: 'app-assign-logsheet',
	templateUrl: './assign-logsheet.component.html',
	styleUrls: ['./assign-logsheet.component.css'],
})
export class AssignLogsheetComponent {
	selectedValues = {
		company: {
			companyId: 0,
			companyName: '',
		},
		plant: {
			plantId: 0,
			plantName: '',
		},
		unit: {
			unitId: 0,
			unitName: '',
		},
	}

	logSheetName: string = ''

	activeGroups: Group[] = []
	selectedGroups: any = []
	activeStep = 1
	currentRole: any
	errorMessage = {
		success: true,
		message: '',
	}
	itemArray: any = []
	firstRender = false
	searchText: String = ''
	logSheetTemplateId = ''
	assignLogsheetRequest: any = {
		groupId: [],
	}

	constructor(
		private groupService: GroupService,
		private route: ActivatedRoute,
		private router: Router,
		public toastService: ToastService,
		public dialogService: DialogService,
		public logsheetService: LogsheetService,
		private errorService: ErrorHandlerService,
	) {}

	addGroup(data: Group) {
		this.selectedGroups.push(data)
	}

	removeGroup(index: number) {
		this.selectedGroups.splice(index, 1)
	}

	sortgroupName() {
		this.selectedGroups.sort((a: any, b: any) => {
			const groupNameA = a.groupName.toUpperCase()
			const groupNameB = b.groupName.toUpperCase()
			if (groupNameA < groupNameB) {
				return -1
			}
			if (groupNameA > groupNameB) {
				return 1
			}
			return 0
		})
	}

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl('settings/logsheets', {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
			},
		})
	}

	isSelectedGroup(dataPoint: Group) {
		var index = this.selectedGroups.findIndex(
			(x: Group) => x.groupId == dataPoint.groupId,
		)
		return index >= 0
	}

	ngOnInit(): void {
		this.selectedValues = history?.state?.selectedValues
		this.logSheetName = history?.state?.logSheetName
		this.route.paramMap.subscribe({
			next: (params) => {
				const id = params.get('id')
				if (id) {
					this.logsheetService
						.retrieveLogsheetRelative(id)
						.subscribe({
							next: (response: any) => {
								this.logSheetTemplateId =
									response.logSheetTemplateId
								this.logsheetService
									.retrieveLogsheet(
										response.logSheetTemplateId,
									)
									.subscribe({
										next: (response) => {
											this.itemArray =
												response?.group?.filter(
													(group) => !group.isDeleted,
												)
											for (
												let i = 0;
												i < this.itemArray.length;
												i++
											) {
												this.selectedGroups.push({
													groupId:
														this.itemArray[i]
															.groupId,
													groupName:
														this.itemArray[i]
															.groupName,
												})
											}
											this.sortgroupName()
										},
										error: (response) => {
											this.errorMessage =
												this.errorService.handleRetrievalErrors(
													response,
												)
										},
									})
							},
							error: (response) => {
								this.errorMessage =
									this.errorService.handleRetrievalErrors(
										response,
									)
							},
						})
				}
			},
		})
		this.groupService
			.retrieveGroupPlant(this.selectedValues?.plant?.plantId)
			.subscribe({
				next: (groups) => {
					this.activeGroups = groups.filter(
						(group) => !group.isDeleted,
					)
				},
				error: (err) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(err)
				},
			})
	}

	loading = false
	enableSubmitBtn() {
		return this.selectedGroups.length > 0 && !this.loading
	}

	assignLogsheet() {
		this.loading = true
		for (let i = 0; i < this.selectedGroups?.length; i++) {
			this.assignLogsheetRequest.groupId.push(
				this.selectedGroups[i]?.groupId,
			)
		}
		this.logsheetService
			.assignLogSheet(this.logSheetTemplateId, this.assignLogsheetRequest)
			.subscribe({
				next: (response) => {
					this.transferData()
					this.toastService.showToast = true
					this.toastService.toastText =
						'Log Sheet assigned successfully added!'
					setTimeout(() => {
						this.toastService.showToast = false
						this.loading = false
					}, 5000)
				},
				error: (response) => {
					// fall back
					this.logsheetService
						.retrieveLogsheetRelative(this.logSheetTemplateId)
						.subscribe({
							next: (resp: any) => {
								let id = resp.logSheetTemplateId
								this.logsheetService
									.assignLogSheet(
										id,
										this.assignLogsheetRequest,
									)
									.subscribe({
										next: (response) => {
											this.transferData()
											this.toastService.showToast = true
											this.toastService.toastText =
												'Log Sheet assigned successfully added!'
											setTimeout(() => {
												this.toastService.showToast =
													false
												this.loading = false
											}, 5000)
										},
										error: (error) => {
											this.errorService.handleError(
												error,
												'Error assigning logsheet',
											)
										},
									})
							},
							error: (error) => {
								this.errorService.handleError(
									error,
									'Error assigning logsheet',
								)
							},
						})
					//
				},
			})
	}
}
