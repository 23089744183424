/** @format */

import { Component, EventEmitter, Input, Output } from "@angular/core"
import { DialogService } from "src/app/services/modal/dialog.service"

@Component({
	selector: "app-save-modal",
	templateUrl: "./save-modal.component.html",
	template: `onSave: {{ onSave }}`,
	styleUrls: ["./save-modal.component.css"],
})
export class SaveModalComponent {
	// @Input() public onSave!: Function
	// @Input() onSave!: any
	@Input() title: string = "Are you sure you want to save changes?";
	@Input() subTitle: string = "If you don’t save, your changes will be lost."
	@Input() actionButtonTitle: string = "Yes, save"
	// Are you sure you want to save changes?
	@Output() onSave: EventEmitter<any> = new EventEmitter<any>()
	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>()
	@Input() showCancelButton: boolean = true;

	constructor(public dialogService: DialogService) {}
	toggleModal() {
		this.dialogService.showModal = false
		this.closeModal.emit()
	}

	saveDetails(): void {
		this.toggleModal()
		this.onSave.emit()
	}
}
