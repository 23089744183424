/** @format */

import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { Plant } from "src/app/models/plant.model"
import { CompanyService } from "src/app/services/company/company.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { PlantService } from "src/app/services/plant/plant.service"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: "app-plant",
	templateUrl: "./plant.component.html",
	styleUrls: ["./plant.component.css"],
})
export class PlantComponent implements OnInit {
	activeItem = 0
	companies: Company[] = []
	firstRender: boolean = false
	plants: Plant[] = []
	showCompanies = false
	showDeactivateMenu = false
	showMenu = false
	selectedPlantId: any = ""
	
	//pagination data
	currentPage: number = 1
	data: Plant[] = []
	pageSize: number = 7
	totalPages: number = 1
	loopArray: number[] = []

	deactivateText = {
		title: "Deactivate Plant",
		message1: "",
		message2: "This action is irreversible. Do you wish to proceed?"
	}

	emptyStateCompanies = {
		imgPath: "../../assets/plant.svg",
		message1: "Select a company to see associated plants listed here",
		message2: "",
	}

	emptyStatePlants = {
		imgPath: "../../assets/plant.svg",
		message1: "No plants to display",
		message2: "Created plants will appear here.",
	}

	errorMessage = {
		success: true,
		message: ''
	}

	get values() {
		return {
			data: this.data,
			currentPage: this.currentPage, 
			totalPages: this.totalPages, 
			loadData: this.loadData, 
			loopArray: this.loopArray 
		}
	}

	plantDetails: Plant = {
		plantId: 0,
		plantName: "",
		isDeleted: false,
		company: "",
	}

	selectedCompany = {
		companyId: 0,
		companyName: "",
		isDeleted: false,
	}
	constructor(
		private route: ActivatedRoute,
		private plantService: PlantService,
		private companyService: CompanyService,
		private router: Router,
		public toastService: ToastService,
		private errorService: ErrorHandlerService
	) {}

	callFunc() {
		this.toggleSelect()
	}

	handleDeactivate() {
		this.plantService
		.deactivatePlant(this.selectedPlantId)
		.subscribe({
			next: (response) => {
				this.loadData(this.selectedCompany)
				this.handleSuccessToast("Plant-Deactivated")
			},
			error: (error) => {
				this.errorService.handleError(error, 'Error deactivating plant')
			},
		})
	}
	
	handleSuccessToast(state: any) {
		if(state === "Plant-Deactivated") {
			this.toastService.toastText = "Plant succesfully deactivated!"
		}
		this.toastService.showToast = true
		setTimeout(() => {
			this.toastService.showToast = false
		}, 5000)
	}

	loadData(selectedCompany: any) {
		this.selectedCompany = selectedCompany

		this.plantService.retrieveCompanyPlantsPaginated(selectedCompany.companyId, this.currentPage, this.pageSize).subscribe((result: any) => {
			this.plants = result.item1
			this.data = result.item1
			this.totalPages = result.item2
			this.updateLoopArray()
		})
	}

	onPageChange(pageNumber: any) {
		this.currentPage = pageNumber
		this.loadData(this.selectedCompany)
	}

	showDeactivateModal(name: string, plantId: any) {
		this.selectedPlantId = plantId
		this.deactivateText.message1 = "You are about to deactivate " + name + " in Lightyear. Doing this will deactivate all objects related to this plant."
		this.showDeactivateMenu = true
	}

	toggleMenu(plantId: number) {
		this.activeItem = plantId
		this.showMenu = !this.showMenu
	}

	toggleSelect() {
		this.showCompanies = !this.showCompanies
	}

	updateLoopArray() {
        this.loopArray = new Array(this.totalPages).fill(0).map((_, index) => index + 1);
    }

	ngOnInit(): void {
		const currentRole = localStorage.getItem('role')
		
		if (currentRole === "Admin") {
			this.router.navigate(["/settings/units"])
		}

		this.firstRender = history.state.firstRender
		
		if (this.firstRender) {
			this.selectedCompany = { ...history.state.selectedCompany }
			this.loadData(this.selectedCompany)
		}
		
		this.companyService.retrieveCompanies().subscribe({
			next: (companies) => {
				this.companies = companies
			},
			error: (response) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(response)
			},
		})
	}
}
