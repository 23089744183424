<!-- @format -->

<div class="custom-section card">
	<div class="section-header">
		<div>
			<h1 class="section-title">Companies</h1>
			<span class="subtext"
				>Manage the list of companies within Lightyear.</span
			>
		</div>
		<div>
			<button type="button">
				<a routerLink="add-company">Add a company</a>
			</button>
		</div>
	</div>

	<table class="custom-table">
		<thead>
			<tr>
				<th>Company</th>
				<th>Abbreviation</th>
				<th>Status</th>
				<th>
					<img
						src="../../assets/dotted-menu.svg"
						alt="menu icon" />
				</th>
			</tr>
		</thead>
		<tbody *ngFor="let company of companies">
			<tr>
				<td>{{ company.companyName }}</td>
				<td>{{ company.companyAbbreviation }}</td>
				<td>{{ company.isDeleted ? "Inactive" : "Active" }}</td>
				<td>
					<img
						*ngIf="!company.isDeleted"
						src="../../assets/dotted-menu.svg"
						alt="menu icon"
						(click)="toggleMenu(company.companyId)" />

					<div
						[ngClass]="
							!showMenu || company.companyId != activeItem
								? 'd-none'
								: ''
						"
						class="custom-table__menu">
						<div>
							{{ company.companyName }}({{
								company.companyAbbreviation
							}})
						</div>

						<a
							[routerLink]="[
								'/settings',
								'companies',
								'edit-company',
								company.companyId
							]">
							<img
								src="../../assets/edit-pencil.svg"
								alt="menu icon" />
							<span>Edit Company</span>
						</a>
						<div class="underline"></div>
						<div
							(click)="
								showDeactivateModal(
									company.companyName,
									company.companyId
								);
								showMenu = false
							">
							<img
								src="../../assets/delete.svg"
								alt="menu icon" />
							<span>Deactivate</span>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="center">
		<app-empty-state
			[data]="emptyStateData"
			*ngIf="!companies || companies.length <= 0" />
	</div>
	<app-pagination [values]="values" (pageChange)="onPageChange($event)" *ngIf="totalPages > 0"/>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-deactivate-modal
	(onCancel)="showDeactivateMenu = false"
	*ngIf="showDeactivateMenu"
	[title]="deactivateText.title"
	[message1]="deactivateText.message1"
	[message2]="deactivateText.message2"
	(onSave)="handleDeactivate()" />
