<!-- @format -->

<div class="custom-section card add-unit">
	<div class="section-header">
		<div>
			<h1 class="section-title">User</h1>

			<span class="subtext"
				>Manage the list of users within Lightyear</span
			>
		</div>

		<div>
			<div class="search-bar">
				<img
					class="search-icon"
					src="../assets/search-alt.svg"
					alt="search" />
				<input
					type="text"
					(ngModelChange)="onSearchChange($event)"
					[ngModel]="searchInput" />
			</div>
			<button
				class="botton-container"
				type="button"
				(click)="toggleAdd()">
				Add New
				<img
					src="../../../assets/down-arrow.svg"
					width="16px"
					height="16px"
					alt="down-arrow" />
			</button>
			<div
				*ngIf="showAddMenu"
				class="dropdown-menu">
				<span
					[ngClass]="{								
						'd-none': currentRole == 'Admin',
						'button-item': true
					}"
					*ngIf="currentRole === 'Super Admin'"
					[routerLink]="['add-super-user']"
					[state]="{
						company: selectedCompany,
						plant: selectedPlant
					}"
					>Super Admin</span
				>
				<a
					*ngIf="currentRole !== 'Admin'"
					class="button-item"
					[ngClass]="!activateSubmitBtn() ? 'disabled-link' : ''"
					[routerLink]="
						activateSubmitBtn() ? ['add-admin-user'] : null
					"
					[state]="{
						company: selectedCompany,
						plant: selectedPlant
					}"
					>Admin</a
				>
				<!-- [routerLink]="activateSubmitBtn() ? ['add-admin-user'] : null" -->
				<a
					[ngClass]="!activateSubmitBtn() ? 'disabled-link' : ''"
					class="button-item"
					[routerLink]="
						activateSubmitBtn() ? ['add-non-admin-user'] : null
					"
					[state]="[selectedCompany, selectedPlant]">
					Non Admin
				</a>
			</div>
		</div>
	</div>
	<!-- DROPDOWNS -->
	<div class="dropdown-group">
		<app-dropdown
			*ngIf="currentRole === 'Super Admin'"
			[disabled]="false"
			[options]="companies"
			selectedItem="{{ selectedCompany }}"
			(onSelectOption)="retrievePlantsByCompanyId($event)"
			[fields]="['companyId', 'companyName']"
			[label]="
				selectedCompany.companyId === 0
					? 'Choose Company'
					: selectedCompany.companyName
			"></app-dropdown>
		<app-dropdown
			[disabled]="
				currentRole === 'Super Admin' && selectedCompany.companyId === 0
					? true
					: false
			"
			[options]="plants"
			selectedItem="{{ selectedPlant }}"
			(onSelectOption)="retrieveUnitsByPlantId($event)"
			[fields]="['plantId', 'plantName']"
			[ngStyle]="currentRole === 'Admin' ? specialStyle : null"
			[label]="
				resetPlant || selectedPlant.plantId == 0
					? 'Choose Plant'
					: selectedPlant.plantName
			"></app-dropdown>
	</div>

	<table class="custom-table">
		<thead>
			<tr>
				<th>User</th>
				<th>Group</th>
				<th>Status</th>
				<th></th>
			</tr>
		</thead>
		<tbody *ngFor="let data of user">
			<tr>
				<td>
					<div>
						<p class="title custom-title">
							{{ data.firstName }} {{ data.lastName }}
						</p>
						<div class="roles">
							<span
								[style]="getRoleColor(data.role)"
								class="role-container"
								>{{ data.role }}</span
							>
							<p class="description custom-title">
								<span class="email-container">{{
									data.email
								}}</span>
							</p>
						</div>
					</div>
				</td>
				<td>
					{{
						data.group[0]?.isDeleted ? "" : data.group[0]?.groupName
					}}
				</td>
				<td>{{ data.isLocked ? "Locked" : data.isDeleted ? "Inactive" : "Active" }}</td>
				<td>
					<img
						[ngClass]="{
							'd-none':
								(data.role == 'Super Admin' ||
									data.role == 'Admin') &&
								currentRole == 'Admin'
						}"
						*ngIf="!data.isDeleted"
						src="../../assets/dotted-menu.svg"
						alt="menu icon"
						(click)="toggleMenu(data.userId)"
						style="cursor: pointer" />

					<div
						[ngClass]="{
							'd-none': !showMenu || data.userId != activeItem
						}"
						class="custom-table__menu">
						<div class="custom-title">
							{{ data.firstName }} {{ data.lastName }}
						</div>

						<a
							*ngIf="data.role != 'Super Admin'"
							
							(click)="
								transferData(
									data, data.role
								)
							">
							<img
								src="../../assets/edit-pencil.svg"
								alt="menu icon" />
							<span>Edit User</span>
						</a>
						<div class="underline" [ngClass]="{'title-margin': data.role === 'Super Admin'}"></div>
						<div
							[ngStyle]="{
								opacity:
									data.role === 'Super Admin' &&
									currentRole === 'Admin'
										? '0.5'
										: null,
								cursor:
									data.role === 'Super Admin' &&
									currentRole === 'Admin'
										? 'not-allowed'
										: 'pointer'
							}"
							(click)="
								showDeactivateModal(
									data.firstName,
									data.lastName,
									data.role!,
									data.email
								)
							">
							<img
								src="../../assets/delete.svg"
								alt="menu icon" />
							<span>Deactivate</span>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>

	<div
		class="center"
		*ngIf="showPlantEmptyState()">
		<app-empty-state [data]="emptyStatePlants" />
	</div>

	<div
		class="center"
		*ngIf="
			selectedPlant.plantId > 0 &&
			selectedCompany.companyId > 0 &&
			user.length === 0
		">
		<app-empty-state [data]="emptyStateDataPoints" />
	</div>
	<div
		class="pagination"
		*ngIf="user.length !== 0">
		<button
			[style.opacity]="currentPage === 1 ? 0.5 : 1"
			type="button"
			(click)="performDecrement()">
			<img
				class="arrow flipped"
				src="../../assets/triangle-icon.svg"
				alt="navigate back" />
		</button>
		<div *ngFor="let item of loopArray; let i = index">
			<button
				*ngIf="item <= totalPages"
				[style]="currentPage === item ? selectedPage : ''"
				(click)="setCurrentPage(item)"
				class="page-number">
				{{ item }}
			</button>
		</div>
		<button
			[style.opacity]="currentPage === totalPages ? 0.5 : 1"
			type="button"
			(click)="performIncrement()">
			<img
				class="arrow"
				src="../../assets/triangle-icon.svg"
				alt="navigate forward" />
		</button>
	</div>
</div>

<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-deactivate-modal
	(onCancel)="showDeactivateMenu = false"
	*ngIf="showDeactivateMenu"
	[title]="deactivateText.title"
	[message1]="deactivateText.message1"
	[message2]="deactivateText.message2"
	(onSave)="handleDeactivate()" />
