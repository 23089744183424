import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Unit } from 'src/app/models/unit.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  baseUrl : string = environment.apiUrl

  constructor(private http: HttpClient) { }

  retrieveUnit(id: string) : Observable<Unit> {
    return this.http.get<Unit>(this.baseUrl + '/unit/' + id)
  }

  addUnit(addUnitRequest : Unit) : Observable<Unit> {
    return this.http.post<Unit>(this.baseUrl + '/unit', addUnitRequest)
  }

  retrieveUnitPlant(id: string) : Observable<Unit[]> {
    return this.http.get<Unit[]>(this.baseUrl + '/unit/plant/' + id)
  }
  editUnit(id: number, updateUnitRequest : Unit) : Observable<Unit> {
    return this.http.patch<Unit>(this.baseUrl + '/unit/' + id, updateUnitRequest)
  }
  retrieveUnitPlantByPage(unitRequest: any) : Observable<Unit[]> {
    return this.http.get<Unit[]>(this.baseUrl + `/unit/plant/${unitRequest.plantId}/${unitRequest.pageNumber}/${unitRequest.pageSize}`)
  } 

  deactivateUnit(id: any) : Observable<Unit> {
    return this.http.patch<Unit>(this.baseUrl + '/unit/deactivate/' + id, {})
  }

  retrieveUnitAnalytics(plantId: number) : Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/unit/analytics/${plantId}`)
  }
}