/** @format */

import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { Plant } from "src/app/models/plant.model"
import { CompanyService } from "src/app/services/company/company.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { DialogService } from "src/app/services/modal/dialog.service"
import { PlantService } from "src/app/services/plant/plant.service"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: "app-edit-plant",
	templateUrl: "./edit-plant.component.html",
	styleUrls: ["./edit-plant.component.css"],
})
export class EditPlantComponent implements OnInit {
	editPlantRequest: Plant = {
		plantId: 0,
		plantName: "",
		isDeleted: false,
		company: "",
	}

	selectedCompany = {
		companyId: 0,
		companyName: "",
	}

	showCompanies = false
	plantNameUpdate = ""

	addPlantRequest = {
		plantId: 0,
		companyId: 0,
		company: "",
		plantName: "",
		isDeleted: false,
	}

	errorMessage: any = {
		success: true,
		message: "",
	}


	companies: Company[] = []
	plants: any
	retrievedCompany: any
	firstRender: boolean = false
	updatePlantModal: boolean = false

	constructor(
		private route: ActivatedRoute,
		private plantService: PlantService,
		private companyService: CompanyService,
		private router: Router,
		public dialogService: DialogService,
		public toastService: ToastService,
		private errorService: ErrorHandlerService
	) {}

	ngOnInit(): void {
		this.selectedCompany = history.state
		this.companyService.retrieveCompanies().subscribe({
			next: (companies) => {
				this.companies = companies
			},
			error: (response) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(response)
			},
		})

		this.route.paramMap.subscribe({
			next: (params) => {
				const id = params.get("id")
				if (id) {
					this.plantService.retrievePlant(id).subscribe({
						next: (response) => {
							this.editPlantRequest = response
							this.plantNameUpdate = response.plantName
						},
					})
				}
			},
		})
	}

	closeUpdatePlantModal() {
		this.updatePlantModal = false
	}

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl("settings/plants", {
			state: {
				selectedCompany: this.selectedCompany,
				firstRender: this.firstRender,
			},
		})
	}

	editPlant() {
		this.plantService
			.editPlant(this.editPlantRequest.plantId, this.editPlantRequest)
			.subscribe({
				next: (response) => {
					this.transferData()
					this.toastService.showToast = true
					this.toastService.toastText = "Plant successfully updated!"
					setTimeout(() => {
						this.toastService.showToast = false
					}, 5000)
				},
				error: (error) => {
					this.errorService.handleError(error, 'Error updating plant')
				},
			})
	}
}
