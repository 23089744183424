import { Component, ChangeDetectorRef } from '@angular/core';
import { OverviewService } from 'src/app/services/overview/overview.service';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  
  constructor(public overviewService: OverviewService) {
    
  }
  
  async resetHomeScreen() {
    this.overviewService.resetToHome()
  }
}
