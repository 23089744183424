export const constants = {
    addAdminUser: "Add-Admin-User",
    addNonAdminUser: "Add-Non-Admin-User",
    addSuperUser: "Add-Super-User",
    editAdminUser: "Edit-Admin-User",
    editNonAdminUser: "Edit-Non-Admin-User",
    editSuperAdminUser: "Edit-Super-Admin-User",
    userDeactivated: "User-Deactivated",
}

export default constants