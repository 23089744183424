<!-- @format -->

<div class="custom-section card schedule-form">
    <h1 class="section-title">Schedule: {{logSheetName}}</h1>
    <span class="subtext"
        >Complete the steps below to create a new log sheet to be used.</span
    >

    <h2 class="schedule-title">Schedule Period</h2>

    <div class="schedule-row">
        <!-- ENABLE SHEET SWITCH -->
        <div>
            <label for="sheetSwitch" class="custom-label">Enable Sheet</label>
            <app-custom-switch
                [enabled]="addScheduleRequest.isEnabled"
                (onToggle)="toggleSheet($event)"
            ></app-custom-switch>
        </div>
        <!-- SCHEDULE TYPE -->
        <div>
            <label for="scheduleType" class="custom-label">Schedule Type</label>
            <app-dropdown
                [form]="true"
                [options]="scheduleTypes"
                selectedItem="{{ selectedScheduleType }}"
                (onSelectOption)="setScheduleType($event)"
                [fields]="['id', 'type']"
                [form]="true"
                [label]="
                    !addScheduleRequest.scheduleType
                        ? 'Select Type'
                        : addScheduleRequest.scheduleType
                "
            ></app-dropdown>
        </div>
    </div>

    <div  
   [ngClass]="addScheduleRequest.scheduleType == 'Scheduled' ? '' : 'd-none'">
   
        <h3>Date & Time</h3>
        <div class="row-wrapper">
            <!-- START DATE -->
            <div>
                <div class="label-container">
                    <label for="startDate" class="custom-label required">
                        Start Date 
                    </label>
                    <span *ngIf="startDateMissing.length > 0" class="required-field-error">{{ startDateMissing }}</span>
                </div>

                <app-custom-date
                    [error]="startDateMissing.length > 0"
                    id="startDate"
                    [dateFormatted]="formatDate(addScheduleRequest.startDate)"
                    placeholder="Select Date"
                    (onSelectDate)="getStartDate($event)"
                ></app-custom-date>
            </div>
            <!-- START TIME -->
            <div>
                <div class="label-container">
                    <label for="startTime" class="custom-label required">Start Time</label>
                    <span *ngIf="startTimeMissing.length > 0" class="required-field-error">{{ startTimeMissing }}</span>
                </div>

                <input
                    [value]="addScheduleRequest.startTime"
                    [owlDateTimeTrigger]="startTime"
                    [owlDateTime]="startTime"
                    class="custom-input time-input"
                    [ngClass]="{'error-field' : startTimeMissing.length > 0}"
                    [(ngModel)]="addScheduleRequest.startTime"
                    id="startTime"
                    name="startTime"
                />

                <owl-date-time
                    [pickerType]="'timer'"
                    [hour12Timer]="true"
                    #startTime
                ></owl-date-time>
            </div>
            <!-- END DATE -->
            <div>
                <label for="endDate" class="custom-label">End Date</label>

                <app-custom-date
                    [dateFormatted]="formatDate(addScheduleRequest.endDate)"
                    id="endDate"
                    placeholder="Select Date"
                    (onSelectDate)="getEndDate($event)"
                ></app-custom-date>

                <div
                        *ngIf="hasError"
                        class="error">
                        <img
                            src="../../assets/alert.svg"
                            alt="" />
                        <p>
                            End date and end time should not occur before start date
                        </p>
                </div>
            </div>
            <!-- END TIME -->
            <div>
                <label for="endTime" class="custom-label">End Time</label>
                <input
                    [value]="addScheduleRequest.endTime"
                    [owlDateTimeTrigger]="endTime"
                    [owlDateTime]="endTime"
                    class="custom-input time-input"
                    [disabled]="addScheduleRequest.scheduleType != 'Scheduled'"
                    [readOnly]="addScheduleRequest.scheduleType != 'Scheduled'"
                    [(ngModel)]="addScheduleRequest.endTime"
                    [ngClass]="
                        addScheduleRequest.scheduleType != 'Scheduled'
                            ? 'disabled-input'
                            : ''
                    "
                    id="endTime"
                    name="endTime"
                />

                <div
                        *ngIf="hasError"
                        class="error">
                        <img
                            src="../../assets/alert.svg"
                            alt="" />
                        <p>
                            End date and end time should not occur before start date
                        </p>
                </div>

                <owl-date-time
                    [pickerType]="'timer'"
                    [hour12Timer]="true"
                    [disabled]="addScheduleRequest.scheduleType != 'Scheduled'"
                    #endTime
                ></owl-date-time>
            </div>
        </div>

        <!-- RECURRENCE SECTION -->
        <h3 class="recurrence-title">Recurrence</h3>
        

        <div    
            *ngIf="
                addScheduleRequest.scheduleType == 'Scheduled'
            "
        >
            <div class="label-container">
                <label for="repeatLabel" class="custom-label repeatLabel required">Repeat Every</label>
                <span *ngIf="frequencyMissing.length > 0" class="required-field-error" style="margin-top: 24px;">{{ frequencyMissing }}</span>
            </div>

            <div class="frequency-container">
                <input
                    [ngClass]="{'error-field' : frequencyGeneralError.length > 0 || (frequencyWeekError.length > 0 && this.addScheduleRequest.recurrenceFrequencyUnit == 'Week(s)')}"
                    type="text"
                    name="interval"
                    id="frequency"
                    class="custom-input recurrence-input"
                    maxlength="2"
                    max="99"
                    min="1"
                    placeholder="00"
                    [(ngModel)]="addScheduleRequest.frequencyDay"
                    onkeypress="return (/\d/g.test(event.key))"
                />

            <div>
                <app-dropdown
                label="{{addScheduleRequest.recurrenceFrequencyUnit || 'Select Frequency'}}"
                [form]="true"
                [options]="frequencyUnits"
                selectedItem="{{ selectedFrequencyUnit }}"
                (onSelectOption)="setFrequencyUnit($event)"
                [fields]="['id', 'unit']"
            ></app-dropdown>
            </div>

                
            </div>

            <!-- Frequency Error Div -->
            <div *ngIf="frequencyGeneralError.length > 0 && this.addScheduleRequest.recurrenceFrequencyUnit !== 'Week(s)'" class="error required-field">
                <img src="../../assets/alert.svg" alt="" />
                <p>
                    {{frequencyGeneralError}}
                </p>
            </div>

            <!-- Frequency Error Hours -->
            <div *ngIf="frequencyHourError.length > 0 && this.addScheduleRequest.recurrenceFrequencyUnit == 'Hour(s)'" class="error required-field">
                <img src="../../assets/alert.svg" alt="" />
                <p>
                    {{ frequencyHourError}}
                </p>
            </div>

            <!-- Frequency Error Div -->
            <div *ngIf="frequencyWeekError.length > 0 && this.addScheduleRequest.recurrenceFrequencyUnit == 'Week(s)'" class="error required-field">
                <img src="../../assets/alert.svg" alt="" />
                <p>
                    {{ frequencyWeekError}}
                </p>
            </div>

            <!-- EVERY MONTH -->
            <div *ngIf="this.addScheduleRequest.recurrenceFrequencyUnit == 'Month(s)'" class="monthly-container">

                <div class="margin-bottom">
                    <input
                        id="option1"
                        name="recurrence"
                        type="radio"
                        class=""
                        for=""
                        [value]="true"
                        [(ngModel)]="addScheduleRequest.recurrenceOnSpecificDay"
                    />
        
                    <label for="option1">On day</label>
        
                    <div>
                        <app-dropdown
                        label="{{this.addScheduleRequest.specificDay || 'Select Value'}}"
                        [form]="true"
                        [disabled]="
                                addScheduleRequest.recurrenceOnSpecificDay == false ||  
                                addScheduleRequest.recurrenceOnSpecificDay == null
                        "
                        [options]="frequencyValue"
                        selectedItem="{{ selectedFrequencyUnit }}"
                        (onSelectOption)="setSpecificDay($event)"
                        
                        [fields]="['id', 'unit']"
                    ></app-dropdown>
                    </div>
           
                </div>
                <div class="">
                    <input
                        id="option2"
                        name="recurrence"
                        type="radio"
                        class=""
                        [value]="false"
                        [(ngModel)]="addScheduleRequest.recurrenceOnSpecificDay"
                    />
        
                    <label for="option2">On the</label>
        
                    <div class="recurrence-dropdown">
                        <app-dropdown
                        [isError]="firstOrLastDayError.length > 0 && addScheduleRequest.recurrenceOnSpecificDay == false"
                        label="{{this.addScheduleRequest.firstOrLastDay || 'Select'}}"
                        [form]="true"
                        [disabled]="
                                addScheduleRequest.recurrenceOnSpecificDay == true ||
                                addScheduleRequest.recurrenceOnSpecificDay == null
                            "
                        [options]="firstOrLastDay"
                        selectedItem="{{ selectedFirstOrLastDay }}"
                        (onSelectOption)="setFirstOrLastDay($event)"
                        [fields]="['id', 'day']"
                        ></app-dropdown>
                    </div>
                    <div class="recurrence-dropdown">
                        <app-dropdown
                            [isError]="dayOfWeekError.length > 0"
                            label="{{this.addScheduleRequest.dayOfWeek || 'Select Day'}}"
                            [form]="true"
                            [disabled]="
                                addScheduleRequest.recurrenceOnSpecificDay == true ||
                                addScheduleRequest.recurrenceOnSpecificDay == null
                            "
                            [options]="allowedForMonth"
                            selectedItem="{{ selectedDayOfWeek }}"
                            (onSelectOption)="setDayOfWeek($event)"
                            [fields]="['id', 'day']"
                        ></app-dropdown>
                    </div>
                </div>
            </div>

            

            <!-- Monthly Error Div -->
            <div *ngIf="(firstOrLastDayError.length > 0 || dayOfWeekError.length > 0 ) && addScheduleRequest.recurrenceOnSpecificDay == false " class="error required-field">
                <img src="../../assets/alert.svg" alt="" />
                <p>
                    {{ firstOrLastDayError.length > 0 ? firstOrLastDayError :  dayOfWeekError}}
                </p>
            </div>

            <!-- EVERY WEEK -->
            <div *ngIf="this.addScheduleRequest.recurrenceFrequencyUnit == 'Week(s)'" class="weekly-container">
                <div class="label-container">
                    <label for="repeatLabel" class="custom-label repeatLabel required">Repeat On</label>
                    <span *ngIf="daysSelectedMissing.length > 0" class="required-field-error">{{ daysSelectedMissing }}</span>
                </div>

            <div>
                <div
                *ngFor="let day of daysOfTheWeek"
                [ngClass]="this.addScheduleRequest.selectedDaysOfTheWeek && this.addScheduleRequest.selectedDaysOfTheWeek.includes(day.day) ? 'selected' : ''"

                (click)="setSelectedDaysOfTheWeek(day.day)"
                >

                {{ day.day.toUpperCase().substring(0,3) }}
            </div>
            </div>

            </div>

        </div>

        <!-- Week Day Error Div -->
        <div
        *ngIf="weeklyCountError.length > 0 && this.addScheduleRequest.recurrenceFrequencyUnit == 'Week(s)'"
            class="error required-field">
            <img
                src="../../assets/alert.svg"
                alt="" />
            <p>
                {{ weeklyCountError }}
            </p>
        </div>

        <!-- SUMMARY SECTION -->
        <div class="recurrence-summary">{{renderSummary()}}</div>

        <!-- END RECURRENCE SECTION -->
        <div
        *ngIf="atLeastOneMissingField"
            class="error required-field">
            <img
                src="../../assets/alert.svg"
                alt="" />
            <p>
                At least one required field is empty. Please input a value
            </p>
        </div>

        <h2 class="overdue-title">Overdue Markers</h2>
        <div class="row-wrapper">
            <!-- INTERVAL -->
            <div>
                <div class="label-container">
                    <label for="interval" class="custom-label required">Interval Value</label>
                    <span *ngIf="overdueIntervalMissing.length > 0" class="required-field-error">{{ overdueIntervalMissing }}</span>
                </div>
                <input
                    type="text"
                    name="interval"
                    id="interval"
                    class="custom-input"
                    [ngClass]="{'error-field' : overdueIntervalMissing.length > 0}"
                    maxlength="2"
                    max="99"
                    min="1"
                    placeholder="00"
                    [(ngModel)]="addScheduleRequest.scheduleInterval"
                    
                />
            </div>
            <!-- INTERVAL UNIT -->
            <div>
                <label for="intervalUnit" class="custom-label required">Interval Unit</label>
                <app-dropdown
                    [label]="
                        !addScheduleRequest.scheduleIntervalUnit ||
                        addScheduleRequest.scheduleType == 'On Demand'
                            ? 'Select Unit'
                            : addScheduleRequest.scheduleIntervalUnit
                    "
                    [form]="true"
                    [options]="intervalUnits"
                    selectedItem="{{ selectedIntervalUnit }}"
                    (onSelectOption)="setIntervalUnits($event)"
                    [fields]="['id', 'unit']"
                ></app-dropdown>
            </div>
        </div>
    </div>

    <div class="btn-group">
        <button class="btn-outline" (click)="transferData()">Cancel</button>
        <button
            [disabled]="!activeAddBtn()"
            [ngClass]="!activeAddBtn() ? 'disabled-btn' : ''"
            type="submit"
            (click)="!activeAddBtn() ? null : submitClick()"
            class="btn-fill"
        >
            Schedule Log Sheet
        </button>
    </div>


</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-save-modal
    *ngIf="showModal"
    (closeModal)="closeModal()"
    (onSave)="submitSchedule()"
></app-save-modal>
