<!-- @format -->

<header>
	<div>
		<div class="user-image">
			<span style="color: white;">{{ initial }}</span>
		</div>
		<div>
			<h4 class="user-name">{{ name }}</h4>
			<p class="user-role">{{ currentRole }}</p>
		</div>
		<button class="dropdown-trigger" (click)="toggleDropdown()" type="button">
			<img
			
			src="../../../assets/down-arrow.svg"
			width="16px"
			height="16px"
			alt="down-arrow" />
		</button>
	</div>
</header>
<div *ngIf="isToggleOpen" class="logout-container">
	<button (click)="setShowLogout(true)" class="logout-btn" type="button">
		<img src="../../../assets/logout-icon.svg" /> 
		<span>Log Out</span>
	</button>
</div>
<app-save-modal (onSave)="handleLogout()" *ngIf="dialogService.showModal" [actionButtonTitle]="'Log Out'" [subTitle]="'Are you sure you want to log out?'" [title]="'Log Out'">

</app-save-modal>