/** @format */

import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"

import { CompanyService } from "src/app/services/company/company.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { ToastService } from "src/app/services/toast/toast.service"

import constants from "src/app/constants"

@Component({
	selector: "app-add-super-user",
	templateUrl: "./add-super-user.component.html",
	styleUrls: ["./add-super-user.component.css"],
})
export class AddSuperUserComponent {
	selectedValues = {
		company: {
			companyId: 0,
			companyName: "dd",
		},
		plant: {
			plantId: 0,
			plantName: "dd",
		},
	}

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public toastService: ToastService,
		public userService: CompanyService,
		private errorService: ErrorHandlerService,
	) {}

	addSuperUserRequest = {
		firstName: "",
		lastName: "",
		username: "",
		Roles: [] as string[],

	}

	errorMessage: any = {
		success: true,
		message: "",
	}

	currentRole: any

	firstRender = false
	searchText: String = ""

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl("settings/user", {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
				prevPage: constants.addSuperUser,
				success:true
			},
		})
	}

	ngOnInit(): void {
		this.currentRole = localStorage.getItem("role")
		this.selectedValues = history.state
	}

	isActiveBtn() {
		if (
			this.addSuperUserRequest.firstName.length >= 2 &&
			this.addSuperUserRequest.lastName.length >= 2 &&
			this.addSuperUserRequest.username.length >= 10
		) {
			return true
		}
		return false
	}

	addUser() {
		this.addSuperUserRequest.Roles.push('Super Admin')
		// Add variables to request object
		this.userService.addAdminUser(this.addSuperUserRequest).subscribe({
		    next: (response) => {
		this.transferData()
		this.toastService.showToast = true
		this.toastService.toastText = "User successfully added!"
		setTimeout(() => {
			this.toastService.showToast = false
		}, 5000)
		    },
		    error: (response) => {
		        this.errorService.handleError(response, 'Error creating a super user')
		    },
		})
	}
}
