import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/company.model';
import { CompanyService } from 'src/app/services/company/company.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {

    editCompanyRequest: Company = {
      companyId: 0,
      companyName: '',
      companyAbbreviation: '',
      isDeleted: false
    };

    errorMessage: any = {
      success: true,
      message: ''
    }

    constructor(
      private route: ActivatedRoute,
      private companyService: CompanyService,
      private router: Router,
      private errorService: ErrorHandlerService,
      public toastService: ToastService,
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe({
          next: (params) => {
            const id = params.get('id')
            if (id) {
              this.companyService.retrieveCompany(id).subscribe({
                next: (response) => {
                    this.editCompanyRequest = response
                },
              })
            }
          },
          error: (response) => {
            this.errorMessage = response
          }
        })
    }

    editCompany() {
      this.companyService
      .updateCompany(this.editCompanyRequest.companyId, this.editCompanyRequest)
      .subscribe({
        next: (response) => {
          this.router.navigate(['settings/companies'])
        },
        error: (error) => {
          this.errorService.handleError(error, 'Error updating company')
        }
      })
    }

}
