/** @format */

import { Component, Input } from "@angular/core"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: "app-success-toast",
	templateUrl: "./success-toast.component.html",
	styleUrls: ["./success-toast.component.css"],
})
export class SuccessToastComponent {
	@Input() text: any = []

	constructor(public toastService: ToastService)  {}
}
