import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Logsheet } from 'src/app/models/logsheet.model';
@Injectable({
  providedIn: 'root'
})
export class LogsheetService {
  baseUrl: string = environment.apiUrl
  constructor(private http : HttpClient) { }

  retrieveLogsheetByUnit(id: number) : Observable<Logsheet[]> {
    const queryParams = new URLSearchParams();
    queryParams.set('isConsole', 'true'); 
    return this.http.get<Logsheet[]>(this.baseUrl + '/logsheet/unit/' + id + `?${queryParams.toString()}`)
  }

  retrieveLogsheetByUnitPaginated(id: number, pageNumber: number, pageSize: number) : Observable<Logsheet[]> {
    const queryParams = new URLSearchParams();
    queryParams.set('isConsole', 'true'); 
    return this.http.get<Logsheet[]>(`${this.baseUrl}/logsheet/unit/${id}/${pageNumber}/${pageSize}?${queryParams.toString()}`)
  }

  addLogSheet(addLogsheet : Logsheet) : Observable<Logsheet> {
    return this.http.post<Logsheet>(this.baseUrl + '/logsheet', addLogsheet)
  }

  scheduleLogSheet(id: number, scheduleLogsheet : Logsheet) : Observable<Logsheet> {
    return this.http.post<Logsheet>(this.baseUrl + '/logsheet/schedule/' + id, scheduleLogsheet)
  }

  assignLogSheet(id: string, assignLogsheet : Logsheet) : Observable<Logsheet> {
    return this.http.post<Logsheet>(this.baseUrl + '/logsheet/assign/' + id, assignLogsheet)
  }

  retrieveLogSheetById(id: string) : Observable<Logsheet> {
    return this.http.get<Logsheet>(this.baseUrl + '/logsheet/' + id)
  }

  retrieveLogsheet(id: string) : Observable<Logsheet> {
    return this.http.get<Logsheet>(this.baseUrl + '/logsheet/' + id)
  } 

  retrieveLogsheetRelative(id: string) : Observable<Logsheet> {
    return this.http.get<Logsheet>(this.baseUrl + '/logsheet/relative/' + id)
  } 

  updateLogSheet(id: string, updateLogsheetRequest : Logsheet) : Observable<Logsheet> {
    return this.http.patch<Logsheet>(this.baseUrl + '/logsheet/' +id, updateLogsheetRequest)
  }

  updateLogSheetStatus(id: string, statusUpdate : {status: string, currentUser?: string}) : Observable<Logsheet> {
    return this.http.post<Logsheet>(this.baseUrl + '/logsheet/logs/' +id, statusUpdate)
  }

  deactivateLogSheet(id: number) : Observable<Logsheet> {
    return this.http.patch<Logsheet>(this.baseUrl + '/logsheet/deactivate/' + id, {})
  }

  retrieveLogSheetByRelativeId(item: any) : Observable<any> {
    return this.http.get<any>(this.baseUrl + '/LogSheet/logs-console?templateId=' + item.id +'&pageNumber=' +item.pageNumber+ '&pageSize=' +item.pageSize + '&startDate=' + item.startDate + '&endDate=' + item.endDate)
  }

  retrieveAnalytics(days: number, plantId: number) : Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/logsheet/logs/analytics/${days}/${plantId}`)
  }

  retrieveActualLogsheet(id: string) : Observable<Logsheet> {
    return this.http.get<Logsheet>(this.baseUrl + '/logsheet/actual-logsheet/'+id)
  } 
  
}

