/** @format */

import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Company } from 'src/app/models/company.model'
import { DataPoint } from 'src/app/models/datapoint.model'
import { CompanyService } from 'src/app/services/company/company.service'
import { DataPointService } from 'src/app/services/data-point/data-point.service'
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service'
import { PlantService } from 'src/app/services/plant/plant.service'
import { ToastService } from 'src/app/services/toast/toast.service'

@Component({
	selector: 'app-add-data-point',
	templateUrl: './add-data-point.component.html',
	styleUrls: ['./add-data-point.component.css'],
})
export class AddDataPointComponent {
	selectedValues = {
		company: {
			companyId: 0,
			companyName: '',
		},
		plant: {
			plantId: 0,
			plantName: '',
		},
		unit: {
			unitId: 0,
			unitName: '',
		},
	}

	fractionMessage =
		'Use a forward slash to seperate numerator from denominator. For example (1/2 or 7/4)'
	listMessage =
		'Use a comma to seperate each List Data Type option. For example (None,Amber,Red,Green)'
	validationErrorMessage = ''

	dataTypes = [
		{
			id: 6,
			dataPointType: 'Fraction',
			isDeleted: false,
		},
		{
			id: 2,
			dataPointType: 'List (Multiple Selection)',
			isDeleted: false,
		},
		{
			id: 7,
			dataPointType: 'List (Single Selection)',
			isDeleted: false,
		},
		{
			id: 1,
			dataPointType: 'Number',
			isDeleted: false,
		},
		{
			id: 3,
			dataPointType: 'Text',
			isDeleted: false,
		},
		{
			id: 4,
			dataPointType: 'Timestamp',
			isDeleted: false,
		},
	]

	selectedDataType = {
		id: 0,
		dataPointType: '',
		isDeleted: false,
	}

	addDataPointRequest = {
		dataPointId: 0,
		dataPointName: '',
		dataType: '',
		unitOfMeasurement: '',
		listOptions: '',
		fraction: '',
		dataPointMin: 0,
		dataPointMax: 0,
		dataPointDescription: '',
		isDeleted: false,
		status: 'active',
		unitId: 0,
	}

	errorMessage: any = {
		success: true,
		message: '',
	}

	companies: Company[] = []
	plants: any
	retrievedCompany: any
	currentRole: any = ''
	firstRender: boolean = false
	displayOverlay: boolean = false
	unitInput: any

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private dataPointService: DataPointService,
		private companyService: CompanyService,
		public toastService: ToastService,
		private errorService: ErrorHandlerService,
	) {}

    ngOnInit(): void {
        this.selectedValues = history.state
        this.currentRole = localStorage.getItem('role');
    }

    transferData() {
		this.firstRender = true
		this.router.navigateByUrl('settings/data-points', {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
			},
		})
	}

	toggleOverlay() {
		this.displayOverlay = !this.displayOverlay
	}

	activateSubmitBtn() {
		if (
			(this.selectedDataType.dataPointType ===
				'List (Multiple Selection)' &&
				this.addDataPointRequest.listOptions.length < 3) ||
			(this.selectedDataType.dataPointType ===
				'List (Multiple Selection)' &&
				this.addDataPointRequest.listOptions.length < 3)
		) {
			return false
		}
		if (
			this.selectedDataType.id === 0 ||
			this.addDataPointRequest.dataPointName.length < 3
		) {
			return false
		} else if (
			this.selectedDataType.dataPointType === 'Number' &&
			(this.addDataPointRequest.unitOfMeasurement.length == 0 ||
				this.addDataPointRequest.dataPointMax == null ||
				this.addDataPointRequest.dataPointMin == null ||
				this.addDataPointRequest.dataPointMin >=
					this.addDataPointRequest.dataPointMax)
		) {
			return false
		}
		return true
	}

	setSelectedDataPointType(selected: any) {
		this.selectedDataType = selected
	}

	addDataPoint() {
		this.addDataPointRequest.dataType = this.selectedDataType.dataPointType
		this.addDataPointRequest.unitId = this.selectedValues['unit'].unitId
		const isListDataType = this.addDataPointRequest.dataType === 'List'
		const isFractionDataType =
			this.addDataPointRequest.dataType === 'Fraction'
		const listOptions = this.addDataPointRequest.listOptions
		const fraction = this.addDataPointRequest.fraction

		if (isListDataType) {
			const hasMoreThanOneItem =
				listOptions.split(',').filter((part) => part.trim() !== '')
					.length > 1
			const isValidListFormat = /^[^,\s]+(?:,[^,\s]+)*$/.test(listOptions)

			if (!hasMoreThanOneItem) {
				this.validationErrorMessage =
					'Please enter more than one item for the list'
				return
			} else if (!isValidListFormat) {
				this.validationErrorMessage =
					'Please enter a comma-separated string with no spaces'
				return
			} else {
				this.validationErrorMessage = ''
			}
		}

		if (this.addDataPointRequest.dataType !== 'Number') {
			this.addDataPointRequest.unitOfMeasurement = ''
			this.addDataPointRequest.dataPointMin = 0
			this.addDataPointRequest.dataPointMax = 0
		}

		this.dataPointService.addDataPoint(this.addDataPointRequest).subscribe({
			next: (request) => {
				this.transferData()
				this.toastService.showToast = true
				this.toastService.toastText = 'Data point successfully added!'
				setTimeout(() => {
					this.toastService.showToast = false
				}, 5000)
			},
			error: (error) => {
				this.errorService.handleError(error, 'Error adding datapoint')
			},
		})
	}
}
