import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company.model'
import { Plant } from 'src/app/models/plant.model'
import { CompanyService } from 'src/app/services/company/company.service'
import { PlantService } from "src/app/services/plant/plant.service"
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router'
import { UnitService } from 'src/app/services/unit/unit.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';
import { DialogService } from 'src/app/services/modal/dialog.service';
@Component({
  selector: 'app-add-unit',
  templateUrl: './add-unit.component.html',
  styleUrls: ['./add-unit.component.css']
})
export class AddUnitComponent {
  errorMessage: any = {
    success: true,
    message: ''
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private plantService: PlantService,
    private companyService: CompanyService,
    private unitService: UnitService,
    public toastService: ToastService,
    private errorService: ErrorHandlerService
  ) {}

  selectedValues = {
		company: {
			companyId: 0,
			companyName: "",
			isDeleted: false
		},
		plant: {
			plantId: 0,
			plantName: "",
			isDeleted: false
		},
	}
  
  companies: Company[] = [];
  plants: Plant[] = [];

  unitName: string = "";

  currentRole: any = "";
  firstRender: boolean = false

  ngOnInit() {
      this.currentRole = localStorage.getItem('role');
      this.selectedValues = { ...history.state }
  }

  transferData() {
		this.firstRender = true
		this.router.navigateByUrl("settings/units", {
			state: {
				selectedValues: this.selectedValues,
				firstRender: this.firstRender,
			},
		})
	}

  addUnit() {
    
    const result = {
      plantId: this.selectedValues.plant.plantId,
      unitName: this.unitName,
      unitId: 0
    }
    this.unitService.addUnit(result).subscribe({
        next: (request) => {
          this.router.navigate(['settings/units'], { state: { 
            prevPage: "Add_Unit", success: true,
            selectedValues: this.selectedValues,
            firstRender: true
          }});
        },
        error: (error) => {
          this.errorService.handleError(error, 'Error adding unit')
        },
      })
  }

  activateAddUnitBtn() {        
		if (this.currentRole === "Super Admin") {
			if (
				this.selectedValues.company.companyId === 0 ||
				this.selectedValues.plant.plantId === 0
			) {
				return false
			} else if (
				this.selectedValues.company.isDeleted ||
				this.selectedValues.plant.isDeleted
			) {
				return false
			} else {
				return true
			}
		} else if (this.currentRole === "Admin") {
			if (
				this.selectedValues.plant.plantId === 0 ||
        this.selectedValues.plant.plantId == null ||
        this.selectedValues.plant.plantId == undefined ||
        this.selectedValues.plant.plantName.length === 0 ||
        this.selectedValues.plant.isDeleted
			) {
				return false
			} else {
				return true
			}
		}
		return true
	}

  handleCancel() {
    this.router.navigate(['settings/units'])
  }

  setPlant(item:any) {
    this.selectedValues.plant = {
      plantId: item.id,
      plantName: item.name,
      isDeleted: item.isDeleted
    };
  }

  setCompany(item:any) {
    this.selectedValues.company = {
      companyId: item.id,
      companyName: item.name,
      isDeleted: item.isDeleted
    };

    this.plantService
			.retrieveCompanyPlants(item.id.toString())
			.subscribe({
				next: (response) => {
					this.plants = response
				},
				error: (err) => {
					this.errorMessage = this.errorService.handleRetrievalErrors(err)
				},
			})

    }
}
