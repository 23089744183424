<!-- @format -->

<div class="logSheet-section" *ngIf="this.logSheet != {}">
    <div class="logSheet-title">
        <h1>{{ logSheet['versionNumber'] > 0 ? logSheet['logSheetTemplated']['logSheetName'] + ' V' + logSheet['versionNumber'] : logSheet['logSheetTemplated']['logSheetName']}}</h1>
        <div>
            {{ activeVersion == 0 ? 'Current' : 'Previous' }}
            State
        </div>
    </div>
    <div class="logSheet-details">
        <div>
            <span
                >Last Edited:
                {{ logSheet.lastModifiedDate | date : 'medium' }}</span
            >
            <span
                >Edit Count:
                {{ logSheet.nonAdminEditCount + logSheet.adminEditCount }}</span
            >
        </div>
        <div class="logSheet-details__btns">
            <button type="button" (click)="printPage()">
                <img src="../../../assets/print.svg" alt=" icon" />Print
            </button>
            <div
                *ngIf="logSheet.nonAdminEditCount + logSheet.adminEditCount >= 1"
            >
                <button type="button" (click)="toggleVersions()">
                    Versions
                    <img src="../../../assets/down-arrow.svg" alt=" icon" />
                </button>
                <div *ngIf="isVersionMenuVisible" class="toggle-menu">
                    <div
                        *ngFor="
                            let version of versions[0]['dataPointValue'];
                            index as idx
                        "
                        (click)="setActiveVersion(idx)"
                    >
                        <img
                            [src]="
                                activeVersion == idx
                                    ? '../../../assets/active-checkbox.svg'
                                    : '../../../assets/inactive-checkbox.svg'
                            "
                            alt=" checkbox"
                        />
                        <p>
                            {{
                                idx == 0
                                    ? 'Current'
                                    : (version['lastModifiedDate']
                                      | date : 'medium')
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="logSheet-form" id="printsection">
        <div style="padding: 10px">
            <div *ngFor="let dataPoint of dataPoints; index as idx">
                <div
                    class="logSheet-form__datapoint"
                    [ngClass]="
                        dataPoint['dataType'] != 'Boolean'
                            ? 'stretch'
                            : 'space-between'
                    "
                >
                    <div>
                        <label
                            [for]="dataPoint['dataPointId']"
                            class="custom-label"
                            >{{ dataPoint['dataPointName'] }}
                        </label>
                        <!-- TEXT -->
                        <input
                            *ngIf="dataPoint['dataType'] == 'Text'"
                            maxlength="50"
                            type="text"
                            class="custom-input"
                            [disabled]="activeVersion != 0"
                            [ngClass]="
                                activeVersion != 0 ? 'disabled-input' : ''
                            "
                            [id]="dataPoint['dataPointId']"
                            [value]="
                                activeVersion == 0
                                    ? dataPointValues[idx]['value']
                                    : versions[idx]['dataPointValue'][
                                          activeVersion
                                      ]['value']
                            "
                            [(ngModel)]="dataPointValues[idx]['value']"
                            placeholder="Add Text"
                        />
                        <!-- NUMBER -->
                        <input
                            *ngIf="dataPoint['dataType'] == 'Number'"
                            type="number"
                            onkeypress="return (/^[0-9&.-]/g.test(event.key))"
                            class="custom-input"
                            [id]="dataPoint['dataPointId']"
                            [(ngModel)]="dataPointValues[idx]['value']"
                            [value]="
                                activeVersion == 0
                                    ? dataPointValues[idx]['value']
                                    : versions[idx]['dataPointValue'][
                                          activeVersion
                                      ]['value']
                            "
                            [disabled]="activeVersion != 0"
                            [ngClass]="
                                activeVersion != 0 ? 'disabled-input' : ''
                            "
                            placeholder="Add Number"
                        />

                        <!-- List Fraction -->
                        <div
                            *ngIf="dataPoint['dataType'] == 'Fraction'"
                            class="single-list"
                        >
                            <div class="fraction-container">
                                <input
                                    [disabled]="activeVersion != 0"
                                    [ngClass]="
                                        activeVersion != 0
                                            ? 'disabled-input'
                                            : ''
                                    "
                                    class="fraction-field"
                                    type="number"
                                    [value]="activeVersion == 0 ? extractNumerator(dataPointValues?.[idx]['value']) : extractNumerator(versions[idx]
									['dataPointValue'][activeVersion]['value'])"
                                    (change)="onNumeratorChange($event, idx)"
                                    onkeypress="return (/^[0-9&-]/g.test(event.key))"
                                />
                                <div class="fraction-slash">/</div>
                                <input
                                    [disabled]="activeVersion != 0"
                                    [ngClass]="
                                        activeVersion != 0
                                            ? 'disabled-input'
                                            : ''
                                    "
                                    class="fraction-field"
                                    type="number"
                                    [value]="activeVersion == 0 ? extractDenominator(dataPointValues?.[idx]['value']) : extractDenominator(versions[idx]
									['dataPointValue'][activeVersion]['value'])"
                                    (change)="onDenominatorChange($event, idx)"
                                    onkeypress="return (/^[0-9&-]/g.test(event.key))"
                                />
                            </div>
                        </div>

                        <!-- List Single Select -->
                        <div
                            *ngIf="
                                dataPoint['dataType'] ==
                                'List (Single Selection)'
                            "
                            class="single-list"
                        >
                            <div
                                *ngFor="
                                    let listOption of dataPoint[
                                        'listOptions'
                                    ].split(',');
									index as index
                                "
                                class="logSheet-form__datapoint__boolean logSheet-form__datapoint__single_list"
                            >
                                <input
                                    (click)="
                                        handleSingleSelect(
                                            idx,
                                            listOption
                                        )
                                    "
                                    [checked]="isSingleSelected(idx, listOption.trim())"
                                    type="radio"
                                    [id]="'option' + index"
                                    [name]="'option' + dataPoint['dataPointId']"
                                    [value]="listOption"
                                />
                                <label [for]="'option' + index">{{
                                    listOption
                                }}</label>
                            </div>
                        </div>

                        <!-- Multiple Single Select -->
                        <div
                            *ngIf="
                                dataPoint['dataType'] ==
                                'List (Multiple Selection)'
                            "
                            class="multi-list"
                        >
                            <div
                                *ngFor="
                                    let listOption of dataPoint[
                                        'listOptions'
                                    ].split(',');
                                    index as index
                                "
                                class="logSheet-form__datapoint__boolean"
                            >
                                <app-checked-box
                                    [index]="index"
                                    [label]="listOption"
                                    [icon]="
                                        !isMultiSelected(idx, listOption.trim())
                                            ? '../assets/checkbox-selected.svg'
                                            : '../assets/checkbox-unselected.svg'
                                    "
                                    (onClickIcon)="
                                        handleMultiSelect(
                                            idx,
                                            listOption.trim()
                                        )
                                    "
                                >
                                </app-checked-box>
                            </div>
                        </div>

                        <!-- TIMESTAMP -->
                        <div
                            *ngIf="dataPoint['dataType'] == 'Timestamp'"
                            class="logSheet-form__datapoint__timestamp"
                        >
                            <div
                                class="custom-input date-picker-container"
                                [ngClass]="
                                    activeVersion != 0 ? 'disabled-input' : ''
                                "
                                [owlDateTimeTrigger]="dateTimePicker"
                            >
                                <span
                                    [ngClass]="
                                        dataPointValues[idx]['value'] == ''
                                            ? 'log-placeholder'
                                            : ''
                                    "
                                >
                                    {{
                                        dataPointValues[idx]['value'] == ''
                                            ? 'Add date and time'
                                            : activeVersion == 0
                                            ? dataPointValues[idx]['value']
                                            : versions[idx]['dataPointValue'][
                                                  activeVersion
                                              ]['value']
                                    }}
                                </span>

                                <input
                                    [id]="dataPoint['dataPointId']"
                                    [(ngModel)]="dataPointValues[idx]['value']"
                                    [value]="
                                        activeVersion == 0
                                            ? dataPointValues[idx]['value']
                                            : versions[idx]['dataPointValue'][
                                                  activeVersion
                                              ]['value']
                                    "
                                    [owlDateTime]="dateTimePicker"
                                    placeholder="Add Date and Time"
                                    class="date-picker"
                                    [disabled]="activeVersion != 0"
                                    (ngModelChange)="
                                        dateTimeFormatter(
                                            dataPointValues[idx]['value'],
                                            idx
                                        )
                                    "
                                    [ngClass]="
                                        activeVersion != 0
                                            ? 'disabled-input'
                                            : ''
                                    "
                                    name="fromdate"
                                />
                            </div>

                            <owl-date-time
                                #dateTimePicker
                                [hour12Timer]="true"
                            ></owl-date-time>

                            <button
                                type="button"
                                class="btn-fill"
                                [disabled]="activeVersion != 0"
                                [ngClass]="
                                    activeVersion != 0 ? 'disabled-btn' : ''
                                "
                                (click)="setCurrentTimestamp(idx)"
                            >
                                Current Timestamp
                            </button>
                        </div>
                    </div>

                    <!-- NOTE -->
                    <div
                        class="logSheet-form__note"
                        [ngStyle]="{
                            'align-self':
                                dataPoint['dataType'] ===
                                    'List (Multiple Selection)' ||
                                dataPoint['dataType'] ===
                                    'List (Single Selection)'
                                    ? 'baseline'
                                    : 'end'
                        }"
                    >
                        <button
                            type="button"
                            class="logSheet-form__note-btn"
                            (click)="
                                activeVersion == 0 ? toggleNoteMenu(idx) : null
                            "
                        >
                            <img
                                class="add-icon"
                                *ngIf=" activeVersion == 0
                                            ? dataPointValues[idx]['note'] == ''
                                            : versions[idx]['dataPointValue'][
                                                  activeVersion
                                              ]['note'] == ''"
                                src="../../../assets/plus.svg"
                                alt=" add icon"
                            />
                            <img
                                 *ngIf=" activeVersion == 0
                                            ? dataPointValues[idx]['note'] != ''
                                            : versions[idx]['dataPointValue'][
                                                  activeVersion
                                              ]['note'] != ''"
                                src="../../../assets/edit_pencil_2.svg"
                                alt=" edit icon"
                            />
                        </button>
                        <div
                            *ngIf="
                                isNoteMenuVisible && idx == selectedDataPointIdx
                            "
                            class="note-menu"
                        >
                            <div class="note-menu__header">
                                {{
                                    logSheet['logSheetTemplated'][
                                        'logSheetName'
                                    ]
                                }}
                            </div>
                            <button
                                *ngIf="!isEditing()"
                                type="button"
                                class="note-textBtn"
                                (click)="toggleNoteModal()"
                            >
                                <img
                                    src="../../../assets/plus.svg"
                                    alt=" icon"
                                />
                                Add Note
                            </button>
                            <div *ngIf="isEditing()">
                                <button
                                    type="button"
                                    class="note-textBtn"
                                    (click)="toggleNoteModal()"
                                >
                                    <img
                                        style="filter: invert() brightness()"
                                        src="../../../assets/edit-pencil.svg"
                                        alt=" icon"
                                    />
                                    Edit Note
                                </button>
                                <div class="underline"></div>
                                <button
                                    type="button"
                                    class="note-textBtn"
                                    (click)="showDeactivateModal()"
                                >
                                    <img
                                        src="../../../assets/delete.svg"
                                        alt=" icon"
                                    />
                                    Delete Note
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ERROR MESSAGE -->
                <div
                    class="error"
                    *ngIf="
                        invalidFields[idx.toString()] &&
                        dataPointValues[idx]['note'].length === 0
                    "
                >
                    <img src="../../assets/alert.svg" alt="" />
                    <p>
                        {{ invalidFields[idx.toString()] }}
                    </p>
                </div>
            </div>
        </div>
        <button
            class="btn-fill logSheet-form-submit-btn"
            [disabled]="!isEnabled()"
            [ngClass]="!isEnabled() ? 'disabled-btn' : ''"
            (click)="isEnabled() ? saveLogSheet() : null"
        >
            Submit Change
        </button>
    </div>
</div>

<div *ngIf="showNoteModal" class="modal-backdrop">
    <div class="modal-body">
        <span
            >{{ !isEditing() ? 'Add' : 'Edit' }} note for
            {{ dataPoints[selectedDataPointIdx]['dataPointName'] }}</span
        >
        <p>
            {{ !isEditing() ? 'Add' : 'Edit' }}
            note below
        </p>
        <textarea
            [(ngModel)]="note"
            type="text"
            rows="6"
            maxlength="1024"
            class="custom-input"
        ></textarea>

        <div>
            <button
                class="btn-outline"
                (click)="toggleNoteModal()"
                type="button"
            >
                Cancel
            </button>
            <button
                (click)="isNoteSubmitBtnEnabled() ? updateNote() : null"
                [disabled]="!isNoteSubmitBtnEnabled()"
                type="submit"
                class="btn-fill-yellow"
                [ngClass]="!isNoteSubmitBtnEnabled() ? 'disabled-btn' : ''"
            >
                Done
            </button>
        </div>
    </div>
</div>
<app-success-toast *ngIf="this.toastService.showToast"></app-success-toast>
<app-deactivate-modal
    (onCancel)="showDeactivateMenu = false"
    (onSave)="deleteNote()"
    actionButtonTitle="Delete"
    *ngIf="showDeactivateMenu"
    [title]="deactivateText.title"
    [message1]="deactivateText.message1"
    [message2]="deactivateText.message2"
/>
