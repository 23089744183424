<!-- @format -->

<div
	class="custom-dropdown"
	(click)="disabled ? null : toggleSelect()"
	(clickOutside)="close($event)">
	<div
		[style]="inputStyle"
		class="custom-dropdown__default"
		[ngClass]="getCustomCss">
		<span>{{ label }}</span>
		<img
			src="../../assets/dropdown.svg"
			alt="dropdown icon" />
	</div>
	<ul
		[style]="inputWrapperStyle"
		class="custom-dropdown__options"
		[ngClass]="getOptionsCss"
		*ngIf="showOptions">
		<li
			*ngFor="let option of options"
			(click)="
				selectOption(
					option[fields[0]],
					option[fields[1]],
					option.isDeleted,
					option?.status,
					option?.days
				)
			">
			{{ option[fields[1]] }}
		</li>
	</ul>
</div>
