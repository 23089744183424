/** @format */

import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { HomeComponent } from "./pages/home/home.component"
import { AnalyticsComponent } from "./pages/analytics/analytics.component"
import { SettingsComponent } from "./pages/settings/settings.component"
import { LoginComponent } from "./pages/login/login.component"
import { DashboardComponent } from "./pages/dashboard/dashboard.component"
import { CompanyComponent } from "./components/settings/company/company.component"
import { AddCompanyComponent } from "./components/settings/add-company/add-company.component"
import { EditCompanyComponent } from "./components/settings/edit-company/edit-company.component"
import { PlantComponent } from "./components/settings/plant/plant.component"
import { AuthorisatonErrorComponent } from "./pages/authorisaton-error/authorisaton-error.component"
import { AddPlantComponent } from "./components/settings/add-plant/add-plant.component"
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component"
import { EditPlantComponent } from "./components/settings/edit-plant/edit-plant.component"
import { DataPointComponent } from "./components/settings/data-point/data-point.component"
import { AddDataPointComponent } from "./components/settings/add-data-point/add-data-point.component"
import { EditDataPointComponent } from "./components/settings/edit-data-point/edit-data-point.component"
import { AddUnitComponent } from "./components/settings/add-unit/add-unit.component"
import { UnitComponent } from "./components/settings/unit/unit.component"
import { EditUnitComponent } from "./components/settings/edit-unit/edit-unit.component"
import { LogsheetComponent } from "./components/settings/logsheet/logsheet.component"
import { AddNonAdminGroupComponent } from "./components/settings/add-group/add-non-admin-group.component"
import { GroupsComponent } from "./components/settings/groups/groups.component"
import { AddLogsheetComponent } from "./components/settings/add-logsheet/add-logsheet.component"
import { ScheduleLogsheetComponent } from "./components/settings/schedule-logsheet/schedule-logsheet.component"
import { EditLogsheetComponent } from "./components/settings/edit-logsheet/edit-logsheet.component"
import { ModificationWindowComponent } from "./components/settings/modification-window/modification-window.component"
import { AssignLogsheetComponent } from "./components/settings/assign-logsheet/assign-logsheet.component"
import { AddSuperUserComponent } from "./components/settings/add-super-user/add-super-user.component"
import { AddAdminUserComponent } from "./components/settings/add-admin-user/add-admin-user.component"
import { UserComponent } from "./components/settings/user/user.component"
import { AddNonAdminUserComponent } from "./components/settings/add-non-admin-user/add-non-admin-user.component"
import { EditNonAdminUserComponent } from "./components/settings/edit-non-admin-user/edit-non-admin-user.component"
import { ProfileComponent } from "./components/settings/profile/profile.component"
import { EditAdminUserComponent } from "./components/settings/edit-admin-user/edit-admin-user.component"
import { AuditComponent } from "./components/settings/audit/audit.component"
import { VerifyEmailComponent } from "./pages/verify-email/verify-email.component"
import { AccountCompleteComponent } from "./pages/account-complete/account-complete.component"
import { OverviewComponent } from "./pages/overview/overview.component"
import { UnitLogComponent } from "./components/overview/unit-log/unit-log.component"
import { UnitLogEmptyComponent } from "./components/overview/unit-log-empty/unit-log-empty.component"
import { LogSheetFormComponent } from "./components/home/log-sheet-form/log-sheet-form.component"
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component"

const routes: Routes = [
	{
		path: "auth/login",
		component: LoginComponent,
	},
	{
		path: "auth/resetPassword/:token",
		component: ResetPasswordComponent,
	},
	{
		path: "forgot-password",
		component: ForgotPasswordComponent,
	},
	{
		path: "account/verify-email",
		component: VerifyEmailComponent,
	},
	{
		path: "account-complete",
		component: AccountCompleteComponent,
	},
	{
		path: "",
		redirectTo: "auth/login",
		pathMatch: "full",
	},
	{
		path: "",
		component: DashboardComponent,
		children: [
			{
				path: "home",
				component: HomeComponent,
				children: [
					{
						path: "",
						redirectTo: "home",
						pathMatch: "full",
					},
					{
						path: "overview",
						component: OverviewComponent,
						children: [
							{
								path: "unit",
								component: UnitLogEmptyComponent,
							},
							{
								path: "unit/:id",
								component: UnitLogComponent,
							},
							{
								path: "unit/:id/log-sheet-form",
								component: LogSheetFormComponent,
							},
						],
					},
				],
			},

			{
				path: "analytics",
				component: AnalyticsComponent,
			},
			{
				path: "settings",
				component: SettingsComponent,
				children: [
					{
						path: "",
						redirectTo: "companies",
						pathMatch: "full",
					},
					{
						path: "companies",
						component: CompanyComponent,
					},
					{
						path: "companies/add-company",
						component: AddCompanyComponent,
					},
					{
						path: "companies/edit-company/:id",
						component: EditCompanyComponent,
					},
					{
						path: "plants",
						component: PlantComponent,
					},
					{
						path: "plants/add-plant",
						component: AddPlantComponent,
					},
					{
						path: "plants/edit-plant/:id",
						component: EditPlantComponent,
					},
					{
						path: "data-points",
						component: DataPointComponent,
					},
					{
						path: "data-points/add-data-point",
						component: AddDataPointComponent,
					},
					{
						path: "data-points/edit-data-point/:id",
						component: EditDataPointComponent,
					},
					{
						path: "units",
						component: UnitComponent,
					},
					{
						path: "plants/edit-plant/:id",
						component: EditPlantComponent,
					},
					{
						path: "units/add-unit",
						component: AddUnitComponent,
					},
					{
						path: "units/edit-unit/:id",
						component: EditUnitComponent,
					},
					{
						path: "logsheets",
						component: LogsheetComponent,
					},
					{
						path: "groups/add-group",
						component: AddNonAdminGroupComponent,
					},
					{
						path: "groups",
						component: GroupsComponent,
					},
					{
						path: "logsheets/add-logsheet",
						component: AddLogsheetComponent,
					},
					{
						path: "logsheets/schedule-logsheet/:id",
						component: ScheduleLogsheetComponent,
					},
					{
						path: "logsheets/edit-logsheet/:id",
						component: EditLogsheetComponent,
					},
					{
						path: "modification-window",
						component: ModificationWindowComponent,
					},
					{
						path: "logsheets/assign-logsheet/:id",
						component: AssignLogsheetComponent,
					},
					{
						path: "user/add-super-user",
						component: AddSuperUserComponent,
					},
					{
						path: "user/add-admin-user",
						component: AddAdminUserComponent,
					},
					{
						path: "user",
						component: UserComponent,
					},
					{
						path: "user/add-non-admin-user",
						component: AddNonAdminUserComponent,
					},
					{
						path: "user/edit-non-admin-user",
						component: EditNonAdminUserComponent,
					},
					{
						path: "user/edit-admin-user",
						component: EditAdminUserComponent,
					},
					{
						path: "profile",
						component: ProfileComponent,
					},
					{
						path: "audit",
						component: AuditComponent,
					},
				],
			},
		],
	},
	{
		path: "error",
		component: AuthorisatonErrorComponent,
	},
	// {
	// 	path: "**",
	// 	redirectTo: "auth/login",
	// }
]
@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
