import { Injectable } from '@angular/core'
import * as packageJsonModule from '../../../package.json';

const packageJson: { version: string } = packageJsonModule;

@Injectable({
    providedIn: 'root',
})
export class VersionService {
  version = packageJson.version;

  constructor() { }
}
