import { Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../auth/auth.service'
import { fromEvent, merge, Subscription, timer } from 'rxjs'
import { mapTo, switchMap, takeUntil } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class InactivityService {
    private readonly inactivityTime = 10 * 60 * 1000 // 10 minutes in milliseconds
    private activityEvents$: any
    private userInactive$ = timer(this.inactivityTime)
    private subscription: Subscription = new Subscription()

    constructor(
        private router: Router,
        private ngZone: NgZone,
        private authService: AuthService
    ) {
        this.activityEvents$ = merge(
            fromEvent(window, 'mousemove').pipe(mapTo(true)),
            fromEvent(window, 'keydown').pipe(mapTo(true)),
            fromEvent(window, 'scroll').pipe(mapTo(true)),
            fromEvent(window, 'touchstart').pipe(mapTo(true))
        )
        this.checkUserActivity()
    }

    private checkUserActivity() {
        this.ngZone.runOutsideAngular(() => {
            const activity$ = this.activityEvents$.pipe(
                switchMap(() => {
                    this.userInactive$ = timer(this.inactivityTime)
                    return this.userInactive$
                })
            )

            this.subscription.add(
                activity$.subscribe(() => {
                    this.ngZone.run(() => this.handleInactivity())
                })
            )
        })
    }

    private handleInactivity() {
        // alert('User is inactive. Logging out...')
        this.authService.logout()
        localStorage.removeItem('lastName')
        localStorage.removeItem('firstName')
        localStorage.removeItem('token')
        localStorage.removeItem('companyId')
        localStorage.removeItem('role')
        localStorage.removeItem('assignedPlants'),
        localStorage.removeItem('email'),
        localStorage.setItem('sessionInvalid', 'true')
        this.router.navigate(['auth/login'])
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
