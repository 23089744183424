/** @format */

import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { DataPoint } from "src/app/models/datapoint.model"
import { Plant } from "src/app/models/plant.model"
import { Unit } from "src/app/models/unit.model"
import { CompanyService } from "src/app/services/company/company.service"
import { DataPointService } from "src/app/services/data-point/data-point.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { PlantService } from "src/app/services/plant/plant.service"
import { ToastService } from "src/app/services/toast/toast.service"
import { UnitService } from "src/app/services/unit/unit.service"

@Component({
	selector: "app-data-point",
	templateUrl: "./data-point.component.html",
	styleUrls: ["./data-point.component.css"],
})
export class DataPointComponent {
	activeItem = 0
	assignedPlants: any = []
	companies: Company[] = []
	currentRole: any = ""
	dataPoints: DataPoint[] = []
	firstRender: boolean = false
	plants: Plant[] = []
	resetPlant = false
	resetUnit = false
	selectedDp: any = "";
	showDeactivateMenu= false
	showMenu = false
	successAddText = "Data Point successfully added!"
	successUpdateText = "Data Point successfully updated!"
	units: Unit[] = []

	//pagination data
	currentPage: number = 1
	data: Plant[] = []
	pageSize: number = 7
	totalPages: number = 1
	loopArray: number[] = []

	errorMessage = {
		success: true,
		message: ''
	}

	deactivateText = {
		title: "Deactivate Data Point",
		message1: "",
		message2: "This action is irreversible. Do you wish to proceed?"
	}
	
	emptyStateDataPoints = {
		imgPath: "../../assets/datapoint.svg",
		message1: "No data points to display",
		message2: "Created data points will appear here.",
	}

	emptyStateCompanies = {
		imgPath: "../../assets/datapoint.svg",
		message1: "Select a company to see associated data points listed here",
		message2: "",
	}

	emptyStatePlants = {
		imgPath: "../../assets/datapoint.svg",
		message1: "Select a plant to see associated data points listed here",
		message2: "",
	}

	emptyStateUnits = {
		imgPath: "../../assets/datapoint.svg",
		message1: "Select a unit to see associated data points listed here",
		message2: "",
	}

	get values() {
		return {
			data: this.data,
			currentPage: this.currentPage, 
			totalPages: this.totalPages, 
			loadData: this.loadData, 
			loopArray: this.loopArray 
		}
	}

	plantDetails: Plant = {
		plantId: 0,
		plantName: "",
		isDeleted: false,
	}

	selectedValues = {
		company: {
			companyId: 0,
			companyName: "",
			isDeleted: false
		},
		plant: {
			plantId: 0,
			plantName: "",
			isDeleted: false
		},
		unit: {
			unitId: 0,
			unitName: "",
			isDeleted: false
		},
	}
	
	constructor(
		private route: ActivatedRoute,
		private plantService: PlantService,
		private companyService: CompanyService,
		private dataPointService: DataPointService,
		private unitService : UnitService,
		private router: Router,
		public toastService: ToastService,
		private errorService: ErrorHandlerService
	) {}

	activateAddDataPointBtn() {
		if (this.currentRole === "Super Admin") {
			if (
				this.selectedValues.company.companyId === 0 ||
				this.selectedValues.plant.plantId === 0 ||
				this.selectedValues.unit.unitId === 0
			) {
				return false
			} else if (
				this.selectedValues.company.isDeleted ||
				this.selectedValues.plant.isDeleted ||
				this.selectedValues.unit.isDeleted
			) {
				return false
			} else {
				return true
			}
		} else if (this.currentRole === "Admin") {
			if (
				this.selectedValues.plant.plantId === 0 ||
				this.selectedValues.unit.unitId === 0
			) {
				return false
			} else if (
				this.selectedValues.plant.isDeleted ||
				this.selectedValues.unit.isDeleted
			) {
				return false
			} else {
				return true
			}
		}
		return true
	}

	handleDeactivate() {
		this.dataPointService.deactivateDataPoint(this.selectedDp)
			.subscribe({
				next: (response) => {
					this.handleSuccessToast('Deactivate_Datapoint');
					this.loadData(this.selectedValues.unit);
				},
				error: (error) => {
					this.errorService.handleError(error, 'Error deactivating datapoint')
				},
			})
	}

	handleSuccessToast(state:any) {
        if (state === 'Deactivate_Datapoint'){
            this.toastService.toastText = 'Data Point successfully deactivated!'
        }
		this.toastService.showToast = true;
		setTimeout(() => {
			this.toastService.showToast = false;
		}, 5000);
    }

	goToDynamic() {
		this.router.navigateByUrl("add-plant", { state: this.selectedValues })
	}

	loadData(selected: any) {
		this.selectedValues.unit = selected

		this.dataPointService
		.retrieveDataPointByUnitIdPaginated(this.selectedValues.unit.unitId.toString(), this.currentPage, this.pageSize, "true")
		.subscribe({
			next: (result: any) => {
				this.dataPoints = result.item1
				this.data = result.item1
				this.totalPages = result.item2
				this.updateLoopArray()
			},
			error: (err) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(err)
			},
		})
	}

    onPageChange(pageNumber: any) {
		this.currentPage = pageNumber
		this.loadData(this.selectedValues.unit)
	}

	retrievePlantsByCompanyId(selected: any, initial?: boolean) {
		if (!this.firstRender) {
			this.selectedValues = {
				...this.selectedValues,
				company: selected,
			}

			this.selectedValues.plant = {
				plantId: 0,
				plantName: "",
				isDeleted: false,
			}
			this.selectedValues.unit = {
				unitId: 0,
				unitName: "",
				isDeleted: false,
			}
			this.dataPoints = []
		}
		this.selectedValues.company = selected

		if (!initial) {
			if (this.selectedValues.plant.plantId > 0 || this.plants.length > 0) {
				this.resetPlant = true
				this.resetUnit = true
				this.selectedValues.plant = {
					plantId: 0,
					plantName: "",
					isDeleted: false,
				}
				this.selectedValues.unit = {
					unitId: 0,
					unitName: "",
					isDeleted: false,
				}
				this.plants = []
				this.units = []
				this.dataPoints = []
			}
		}

		this.plantService
			.retrieveCompanyPlants(this.selectedValues.company.companyId.toString())
			.subscribe({
				next: (response) => {
					this.plants = response
				},
				error: (err) => {
					this.errorMessage = this.errorService.handleRetrievalErrors(err)
				},
			})
		this.resetPlant = false
	}

	retrieveUnitsByPlantId(selected: any, initial?: boolean) {
		this.selectedValues.plant = selected

		if (!initial) {
			if (this.selectedValues.unit.unitId > 0 || this.units.length > 0) {
				this.resetUnit = true
				this.selectedValues.unit = {
					unitId: 0,
					unitName: "",
					isDeleted: false,
				}
				this.units = []
				this.dataPoints = []
			}
		}
		this.unitService
			.retrieveUnitPlant(this.selectedValues.plant.plantId.toString())
			.subscribe({
				next: (response) => {
					this.units = response
				},
				error: (err) => {
					this.errorMessage = this.errorService.handleRetrievalErrors(err)
				},
			})
		this.resetUnit = false
	}

	retrieveDataPointsByUnitId(selected: any) {
		this.selectedValues.unit = selected
	
		this.loadData(selected)
	}

	showDeactivateModal(name: string, dpId: any) {
		this.selectedDp = dpId;
		this.deactivateText.message1 = "You are about to deactivate " + name + " in Lightyear. Doing this will prevent creation of logsheets with this data point and remove this data point from existing log sheets."
		this.showDeactivateMenu = true
	}

	showPlantEmptyState() {
		if (
			this.currentRole === "Super Admin" &&
			this.selectedValues.company.companyId > 0 &&
			(this.plants.length === 0 || this.selectedValues.plant.plantId === 0) &&
			this.selectedValues.unit.unitId === 0 &&
			// this.plants.length === 0 &&
			this.dataPoints.length === 0
		) {
			return true
		} else if (
			this.currentRole === "Admin" &&
			(this.plants.length === 0 || this.selectedValues.plant.plantId === 0) &&
			this.selectedValues.unit.unitId === 0
		) {
			return true
		} else {
			return false
		}
	}

	toggleMenu(plantId: number) {
		this.activeItem = plantId
		this.showMenu = !this.showMenu
	}

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl("settings/data-points", {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
			},
		})
	}

	updateLoopArray() {
        this.loopArray = new Array(this.totalPages).fill(0).map((_, index) => index + 1);
    }

	ngOnInit(): void {
		this.currentRole = localStorage.getItem("role")
		this.firstRender = history.state.firstRender
		if (this.firstRender) {
			this.selectedValues = { ...history?.state?.selectedValues }
			this.retrievePlantsByCompanyId(this.selectedValues?.company, this.firstRender)
			this.retrieveUnitsByPlantId(this.selectedValues?.plant, this.firstRender)
			this.retrieveDataPointsByUnitId(this.selectedValues?.unit)
			this.firstRender = false
		}

		try {
			const storedAssignment = localStorage.getItem("assignedPlants")
			if (storedAssignment) {
				this.assignedPlants = JSON.parse(storedAssignment)
			} 
			else {
				console.error("No data found in local storage for key 'assignedPlants' ");
			}
		} catch (error) {
			console.error("Error parsing JSON data from local storage:", error);
		}

		if (this.currentRole === "Admin") {
			this.assignedPlants.map((assignment: Plant) => this.plants.push(assignment))
		} else {
			this.companyService.retrieveCompanies().subscribe({
				next: (companies) => {
					this.companies = companies
				},
				error: (response) => {
					this.errorMessage = this.errorService.handleRetrievalErrors(response)
				},
			})
		}

	}
}
