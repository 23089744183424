import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth/auth.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';
import { Group } from 'src/app/models/group.model';
import { GroupService } from 'src/app/services/group/group.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-add-non-admin-user',
  templateUrl: './add-non-admin-user.component.html',
  styleUrls: ['./add-non-admin-user.component.css']
})
export class AddNonAdminUserComponent {

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private router: Router,
    private toastService: ToastService,
    private authService: AuthService,
    private errorService: ErrorHandlerService
  ) {
  }
  

  selectedCompany = {
		companyId: 0,
		companyName: "",
    isDeleted: false
	}

  selectedPlant = {
		plantId: 0,
		plantName: "",
    isDeleted: false
	}

  selectedRole = "Non-Admin User";
  groups: Group[] = [];
  firstName = "";
  lastName = "";
  email = "";

  selectedGroup: Group = {
    groupId: 0,
    groupName: "",
    plantId: 0,
    isDeleted: false
  }

  specialStyle = {
    flex: 0.5,
    marginRight: "32px"
  };

  defaultStyle = {
      flex: 1,
  };


  currentRole:any = "";

  firstNameError:string = "First Name should be 2-35 characters in length";
  lastNameError:string = "Last Name should be 2-35 characters in length";
  emailError:string = "Please enter a valid email address";
  errorMessage: { success: boolean, message: string } = {
    success: true,
    message: ''
  }

  showFirstNameError: boolean = false;
  showLastNameError: boolean = false;
  showEmailError: boolean = false;

  handleSelectGroup(item: any) {
    this.selectedGroup = item;
  }

  activateSubmitBtn() {
    if (this.firstName.length < 2 || this.lastName.length < 2 || this.email.length < 2) {
      return false
    } else if (this.selectedGroup.groupId === 0) {
      return false;
    }
    return true
  }

  prevState:any = {}
  hasSpecialCharactersOrSpaces(str:string) {
    const regex = /^[a-zA-Z0-9'\-\s]*$/;
    return !regex.test(str);
  }

  isValidEmail(email:string) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  isFieldValid() {
    let valid = true;
    if (this.firstName.length > 35 || this.firstName.length < 2) {
      this.showFirstNameError = true;
      this.firstNameError = "First Name should be 2-35 characters in length";
      valid = false;
    } else if (this.hasSpecialCharactersOrSpaces(this.firstName)) {
      this.showFirstNameError = true;
      this.firstNameError = "Only single quote ( ' ) and hyphen ( - ) are allowed as special characters";
      valid = false;
    } else {
      this.showFirstNameError = false;
    }

    if (this.lastName.length > 35 || this.lastName.length < 2) {
      this.showLastNameError = true;
      this.lastNameError = "Last Name should be 2-35 characters in length";
      valid = false;
    } else if (this.hasSpecialCharactersOrSpaces(this.lastName)) {
      this.showLastNameError = true;
      this.lastNameError = "Only single quote ( ' ) and hyphen ( - ) are allowed as special characters";
      valid = false;
    } else {
      this.showLastNameError = false;
    }

    if (!this.isValidEmail(this.email)) {
      this.showEmailError = true;
      this.emailError = "Please enter a valid email address";
      valid = false;
    } else {
      this.showEmailError = false;
    }
    return valid;
  }
  addNonAdminUser() {
    const validateFields = this.isFieldValid();
    if (!validateFields) return;
    const payload = {
      "Username": this.email,
      "Roles": ["Operator"],
      "LastName": this.lastName,
      "FirstName": this.firstName,
      "ids": [
          this.selectedGroup.groupId
      ],
      "plantId": this.selectedPlant.plantId
    }
    this.authService.register(payload).subscribe({
      next: (request) => {
        this.router.navigate(['settings/user'], { state: {details: this.prevState, prevPage: "Add-Non-Admin-User", success: true } });

      },
      error: (err) => {
          if (err.message = "Cannot create user with this email as it is already in use") {
            this.showEmailError = true;
            this.emailError = err.message;
          }
      },
    })

  }

  pageDetails:string = "Add-Non-Admin-User"

  ngOnInit() {
    this.currentRole = localStorage.getItem('role');
    this.selectedCompany = history.state[0];
    this.selectedPlant = history.state[1];  

    this.prevState = {
      company: this.selectedCompany,
      plant: this.selectedPlant,
      group: this.selectedGroup

    }
    

    this.groupService.retrieveGroupPlant(this.selectedPlant.plantId)
        .subscribe({
            next: (response) => {
                this.groups = response
            },
            error: (err) => {
                this.errorMessage = this.errorService.handleRetrievalErrors(err)
            },
        })
  }
}
