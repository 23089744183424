<div class="dropdown-container">
    <label *ngIf="showLabel"  class="custom-label required">{{label}}</label>
    <div class="custom-dropdown dropdown" (click)="toggleAction()">
        <div [ngStyle]="disabled ? specialStyle : {}" class="custom-dropdown__default" [ngClass]="displayDropdown ? 'active' : ''">
            <span [ngClass]="disabled ? 'input-disable' : 'input-label'" >{{selectedItem}}</span>
            <img [ngClass]="disabled ? 'dropdown-disable' : ''" src="../../assets/dropdown.svg" alt="dropdown icon" />
        </div>
        <div class="custom-dropdown__options" *ngIf="displayDropdown">
            <li
                *ngFor="let item of items"
                (click)="
                    setSelectedItem(item?.companyId || item?.plantId, item?.companyName || item?.plantName, item?.isDeleted, item?.company)
                "
            >
                {{ item?.companyName || item?.plantName }}
            </li>
        </div>
    </div>
</div>
