/** @format */

import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { Plant } from "src/app/models/plant.model"
import { CompanyService } from "src/app/services/company/company.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { PlantService } from "src/app/services/plant/plant.service"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: "app-add-plant",
	templateUrl: "./add-plant.component.html",
	styleUrls: ["./add-plant.component.css"],
})
export class AddPlantComponent implements OnInit {
	selectedCompany = {
		companyId: 0,
		companyName: "",
	}
	showCompanies = false

	addPlantRequest = {
		plantId: 0,
		companyId: 0,
		company: "",
		plantName: "",
		isDeleted: false,
	}

	errorMessage: any = {
		success: true,
		message: "",
	}

	companies: Company[] = []
	plants: any
	retrievedCompany: any

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private plantService: PlantService,
		private companyService: CompanyService,
		private errorService: ErrorHandlerService,
		public toastService: ToastService,
	) {}

	ngOnInit(): void {
		this.selectedCompany = history.state

		this.companyService.retrieveCompanies().subscribe({
			next: (companies) => {
				this.companies = companies
			},
			error: (response) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(response)
			},
		})

		this.route.paramMap.subscribe({
			next: (params) => {
				const id = params.get("id")

				if (id) {
					this.companyService.retrieveCompany(id).subscribe({
						next: (response) => {
							this.retrievedCompany = response
						},
						error: (err) => {
							this.errorMessage = this.errorService.handleRetrievalErrors(err)
						},
					})
				}
			},
		})
	}

	transferData() {
		this.router.navigateByUrl("settings/plants", {
			state: {
				selectedCompany: this.selectedCompany,
				firstRender: true
			}
		})
	}

	addPlant() {
		this.addPlantRequest.companyId = this.selectedCompany.companyId
		this.plantService.addPlant(this.addPlantRequest).subscribe({
			next: (request) => {
				this.transferData()
			},
			error: (error) => {
				this.errorService.handleError(error, 'Error adding plant')
			},
		})
	}
}
