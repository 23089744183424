/** @format */

import { moveItemInArray } from "@angular/cdk/drag-drop"
import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { DataPoint } from "src/app/models/datapoint.model"
import { Logsheet } from "src/app/models/logsheet.model"
import { DataPointService } from "src/app/services/data-point/data-point.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { LogsheetService } from "src/app/services/logsheet/logsheet.service"
import { DialogService } from "src/app/services/modal/dialog.service"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: 'app-edit-logsheet',
	templateUrl: './edit-logsheet.component.html',
	styleUrls: [
		'./edit-logsheet.component.css',
		'../add-logsheet/add-logsheet.component.css',
	],
})
export class EditLogsheetComponent {
	selectedValues = {
		company: {
			companyId: 0,
			companyName: 'Company',
		},
		plant: {
			plantId: 0,
			plantName: 'Plant',
		},
		unit: {
			unitId: 0,
			unitName: 'Unit',
		},
	}
	logSheetName: string = ''
	constructor(
		private dataPointService: DataPointService,
		private route: ActivatedRoute,
		private router: Router,
		public dialogService: DialogService,
		public toastService: ToastService,
		private logsheetService: LogsheetService,
		private errorService: ErrorHandlerService,
	) {}

	activeDataPoints: DataPoint[] = []
	itemArray: any = []
	selectDataPoints: any = []
	activeStep = 1
	currentRole: any
	errorMessage = {
		success: true,
		message: '',
	}
	firstRender = false
	searchText: String = ''
	logSheetTemplateId: any = ''
	idArray: any = []
	editLogsheetRequest: Logsheet = {
		logSheetTemplateId: 0,
		logSheetName: '',
		isDeleted: false,
		scheduleType: '',
		datapointId: [] as number[],
	}
	logSheetNameUpdate = ''
	showModal: boolean = false

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl('settings/logsheets', {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
			},
		})
	}

	setActiveStep(step: number) {
		this.activeStep = step
	}

	isSelectedDataPoint(dataPoint: DataPoint) {
		var index = this.selectDataPoints.findIndex(
			(x: DataPoint) => x.dataPointId == dataPoint.dataPointId,
		)
		return index >= 0
	}

	closeModal() {
		this.showModal = false
	}

	addDataPoint(data: DataPoint) {
		this.selectDataPoints.push(data)
	}

	removeDataPoint(index: number) {
		this.selectDataPoints.splice(index, 1)
	}

	ngOnInit(): void {
		this.currentRole = localStorage.getItem('role')
		this.selectedValues = history.state?.selectedValues
		this.logSheetName = history?.state?.logSheetName
		this.route.paramMap.subscribe({
			next: (params) => {
				const id = params.get('id')

				if (id) {
					// retrieve latest logsheet relative
					this.logsheetService
						.retrieveLogsheetRelative(id)
						.subscribe({
							next: (response) => {
								this.logSheetTemplateId =
									response.logSheetTemplateId
								this.logsheetService
									.retrieveLogsheet(
										response.logSheetTemplateId.toString(),
									)
									.subscribe({
										next: (response) => {
											this.editLogsheetRequest = response
											this.logSheetNameUpdate =
												response?.logSheetName
											this.itemArray =
												response?.dataPoints
											for (
												let i = 0;
												i < this.itemArray.length;
												i++
											) {
												this.selectDataPoints.push({
													dataPointId:
														this.itemArray[i]
															.dataPointId,
													dataPointName:
														this.itemArray[i]
															.dataPointsDetails[0]
															?.dataPointName,
													dataPointDescription:
														this.itemArray[i]
															.dataPointsDetails[0]
															?.dataPointDescription,
												})
											}
										},
										error: (response) => {
											this.errorMessage =
												this.errorService.handleRetrievalErrors(
													response,
												)
										},
									})
							},
							error: (response) => {
								this.errorMessage =
									this.errorService.handleRetrievalErrors(
										response,
									)
							},
						})
				}
			},
		})
		this.dataPointService
			.retrieveDataPointByUnitId(
				this.selectedValues.unit.unitId.toString(),
			)
			.subscribe({
				next: (dataPoints) => {
					this.activeDataPoints = dataPoints
				},
				error: (err) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(err)
				},
			})
	}
	isValidLogsheetName() {
		return (
			this.editLogsheetRequest.logSheetName.length >= 3 &&
			this.editLogsheetRequest.logSheetName.length <= 50
		)
	}

	transform(searchText: string): any[] {
		if (!this.activeDataPoints) {
			return []
		}
		if (!searchText) {
			return this.activeDataPoints
		}
		searchText = searchText.toLocaleLowerCase()

		return this.activeDataPoints.filter((data) => {
			return data.dataPointName.toLocaleLowerCase().includes(searchText)
		})
	}

	enableNextBtn() {
		if (
			(this.activeStep === 1 && !this.isValidLogsheetName()) ||
			(this.activeStep === 2 && this.selectDataPoints.length == 0)
		) {
			return false
		}
		return true
	}

	drop(event: any) {
		moveItemInArray(
			this.selectDataPoints,
			event?.previousIndex,
			event?.currentIndex,
		)
	}

	editLogsheet() {
		for (let i = 0; i < this.selectDataPoints?.length; i++) {
			this.idArray.push(this.selectDataPoints[i]?.dataPointId)
		}
		this.editLogsheetRequest.datapointId = this.idArray
		this.logsheetService
			.updateLogSheet(this.logSheetTemplateId, this.editLogsheetRequest)
			.subscribe({
				next: (res) => {
					this.transferData()
					this.toastService.showToast = true
					this.toastService.toastText =
						'Log Sheet successfully updated!'
					setTimeout(() => {
						this.toastService.showToast = false
					}, 5000)
				},
				error: (res) => {
					// fall back
					this.logsheetService
						.retrieveLogsheetRelative(this.logSheetTemplateId)
						.subscribe({
							next: (resp: any) => {
								let load = this.editLogsheetRequest
								load.logSheetTemplateId =
									resp.logSheetTemplateId

								//
								this.logsheetService
									.updateLogSheet(
										resp.logSheetTemplateId,
										load,
									)
									.subscribe({
										next: (res) => {
											this.transferData()
											this.toastService.showToast = true
											this.toastService.toastText =
												'Log Sheet successfully updated!'
											setTimeout(() => {
												this.toastService.showToast =
													false
											}, 5000)
										},
										error: (error) => {
											this.errorService.handleError(
												error,
												'Error updating logsheet',
											)
										},
									})
								//
							},
							error: (error) => {
								this.errorService.handleError(
									error,
									'Error updating logsheet',
								)
							},
						})
				},
			})
	}
}
