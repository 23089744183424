<div class="forgot__password-wrapper">
    <div class="forgot__password">
        <div class="forgot__password-header">
            <img src="../../../assets/logo.svg" class="forgot__password-header-img" alt="" />

            <span class="forgot__password-header-divider"></span>

            <span class="forgot__password-header-text">Lightyear</span>
        </div>

        <div>
            <p class="forgot__password-text">Enter your registered email and a link to reset your password will be sent.</p>
        </div>

        <div *ngIf="model.message" [ngClass]="model.success ? 'forgot__password-success' : 'forgot__password-error'">
            <p>{{ model.message }}</p>
        </div>

        <div class="email-wrapper">
            <div class="label">
                <div class="email-label">Email</div>
            </div>

            <div class="email-inputfield">
                <div class="email-input">
                    <input type="email" name="email" placeholder="Email address" id="" #emailInput>
                </div>
            </div>
        </div>
        
        <div class="forgot__password-btn">
            <button (click)="handleForgetPassword()">Reset Password</button>
        </div>

        <div class="back-container" (click)="handleBackButton()">
            <p class="back-button">Back to login page</p>
        </div>
    </div>
</div>