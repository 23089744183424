<div class="login-wrapper">
    <app-modal *ngIf="displayPopup"  [message]="'Your password was updated successfully'" [buttonTitle]="'Ok'" [action]="handleLogin" />
    <form class='login' (ngSubmit)="handleResetSubmit()">
        <div class="login-header">
            <img src="../../../assets/logo.svg" class="login-header-img" alt="" />
            <span class="login-header-divider"></span>
            <span class="login-header-text">Lightyear</span>
        </div>
        <div>
            <p class="login-text">Enter the information below to login.</p>
        </div>
        <div *ngIf="model.success === false && displayPopup === false" class="login-error">
            <p>{{model.message}}</p>
        </div>

        <div class="password-wrapper">
            <div class="label">
                <div class="password-label">New Password</div>
                <button class="tool-tip" type="button" (click)="toggleOverlay()">ⓘ</button>
            </div>
            <div class="overlay" *ngIf="displayOverlay">
                <div class="overlay-header">New password must be at least 9 characters long and include the below elements:</div>
                <ul class="overlay-list">
                    <li class="overlay-item">At least 1 uppercase letter</li>
                    <li class="overlay-item">At least 1 lowercase letter</li>
                    <li class="overlay-item">At least 1 special character (!@#$%^&amp;*()_+)</li>
                    <li class="overlay-item">At least 1 number</li>
                    <li class="overlay-item">No space</li>
                </ul>
            </div>
            <div class="password-inputfield">
                
                <div class="password-input">
                    <input type="password" name="confirmPassword" [(ngModel)]="model.newPassword" placeholder="New Password" autocomplete="off" id="">
                </div>
            </div>
           
        
            <div class="password-inputfield">
                <div class="password-text">Confirm New Password</div>
                <div class="password-input">
                    <input type="password" name="confirmPassword" [(ngModel)]="model.confirmPassword" placeholder="Confirm Password" autocomplete="off" id="">
                </div>
            </div>
        </div>
        
        <div class="login-btn">
            <button [disabled]="displayPopup" [ngStyle]="displayPopup ? {'opacity': '0.5', cursor: 'default'} : {}">Update Password</button>
        </div>
    </form>
</div>