<!-- @format -->

<div class="card custom-section">
	<h1 class="section-title">Edit company</h1>
	<p class="subtext">Edit company details using the form below.</p>
	<form
		#form="ngForm"
		(ngSubmit)="editCompany()">
		<div class="form-group">
			<div class="fieldset">
				<label for="company_name">Name</label>
				<input
					type="text"
					name="company_name"
					id="company_name"
					[(ngModel)]="editCompanyRequest.companyName" />
			</div>
			<div class="fieldset">
				<label for="company_abbreviation">Abbreviation</label>
				<input
					type="text"
					name="company_abbreviation"
					id="company_abbreviation"
					[(ngModel)]="editCompanyRequest.companyAbbreviation" />
				<input
					type="hidden"
					name="company_is_deleted"
					id="company_is_deleted"
					[(ngModel)]="editCompanyRequest.isDeleted" />
			</div>
		</div>
		<div class="btn-group">
			<button
				class="cancel-btn"
				routerLink="../..">
				Cancel
			</button>
			<button
				type="submit"
				class="submit-btn">
				Update Company
			</button>
		</div>
	</form>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
