import { Component, EventEmitter, Input, Output } from "@angular/core"

@Component({
  selector: 'app-checked-box',
  templateUrl: './checked-box.component.html',
  styleUrls: ['./checked-box.component.css']
})
export class CheckedBoxComponent {
  @Input() label: string = ""
  @Input() icon:string = ""
  @Output() onClickIcon: EventEmitter<any> = new EventEmitter()
  @Input() index:any = ""

  selectIcon() {
		this.onClickIcon.emit();
	}
}
