import { Component, Input, ChangeDetectorRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Company } from 'src/app/models/company.model';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.css']
})
export class CustomDropdownComponent {
  @Input() label: string = "";
  @Input() selectedItem: string = "";
  @Input() items: Array<any> = [];
  @Input() disabled: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() clearSelected: boolean = false;
  @Input() defaultSelected: string = "";
  // @Input() setSelected: Function = (item: any) => {}
  @Output() setSelected = new EventEmitter<any>();

  currentRole: any = "";
  ngOnInit(): void {
    this.currentRole = localStorage.getItem('role');
  }

  displayDropdown: boolean = false;
  toggleAction() {
    if (this.disabled) {
      return;
    }
    this.displayDropdown = !this.displayDropdown;
  }

  specialStyle = {
    background: "var(--default-gray-200)",
    borderColor: "transparent"
  };

  // setSelectedItem(id:any, name:any, isDeleted:any, company?:any) {
  //   this.displayDropdown = !this.displayDropdown;
  // }

  ngOnChanges(changes: any) {
    // if (this.currentRole !== 'Admin') {
    //   this.items = changes?.items?.currentValue
    //   if (this.clearSelected) {
    //     this.selectedItem = this.defaultSelected;
    //   }
    // }
  }

  setSelectedItem(id:any, name:any, isDeleted:any, company?:any) {
    this.selectedItem = name;
    this.setSelected.emit(
    {
      id: id,
      name: name,
      isDeleted,
      company
    });
  }
}
