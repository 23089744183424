import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Group } from 'src/app/models/group.model';
import { Unit } from 'src/app/models/unit.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  baseUrl : string = environment.apiUrl

  constructor(private http: HttpClient) { }

  retrieveGroupPlant(id: number) : Observable<Group[]> {
    return this.http.get<Group[]>(this.baseUrl + '/group/plant/' + id)
  }

  retrieveGroupPlantPaginated(id: number, pageNumber: number, pageSize: number) : Observable<Group[]> {
    return this.http.get<Group[]>(`${this.baseUrl}/group/plant/${id}/${pageNumber}/${pageSize}`)
  }

  addGroup(item : Group) : Observable<Group> {
    return this.http.post<Group>(this.baseUrl + '/userGroup', item)
  }

  deactivateGroup(id: number) : Observable<Group> {
    return this.http.patch<Group>(this.baseUrl + '/group/deactivate/' + id, {})
  }

}
