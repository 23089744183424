/** @format */

import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { ToastService } from "src/app/services/toast/toast.service"
import { GroupService } from "src/app/services/group/group.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"

@Component({
	selector: "app-add-non-admin-group",
	templateUrl: "./add-non-admin-group.component.html",
	styleUrls: ["./add-non-admin-group.component.css"],
})
export class AddNonAdminGroupComponent {
	selectedValues = {
		company: {
			companyId: 0,
			companyName: "",
		},
		plant: {
			plantId: 0,
			plantName: "",
		},
	}

	addNonAdminGroupRequest = {
		groupId: 0,
		groupName: "",
		// dataPointName: '',
		// dataType: '',
		// unitOfMeasurement: '',
		// dataPointMin: 0,
		// dataPointMax: 0,
		// dataPointDescription: '',
		isDeleted: false,
		status: "active",
		// unitId: 0,
	}

	errorMessage: any = {
		success: true,
		message: "",
	}

	companies: Company[] = []
	plants: any
	retrievedCompany: any
	currentRole: any = ""

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public toastService: ToastService,
		private groupService: GroupService,
		private errorService: ErrorHandlerService
	) {}

	ngOnInit(): void {
		this.selectedValues.company = history.state.company
		this.selectedValues.plant = history.state.plant
		this.currentRole = localStorage.getItem("role")
	}

	activateSubmitBtn() {
		if (this.addNonAdminGroupRequest.groupName.length < 3) {
			return false
		}
		return true
	}

	// firstRender = true

	transferData() {
		// this.firstRender = true
		this.router.navigateByUrl("settings/groups", {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: true,
			},
		})
	}

	addNonAdminGroup() {
		const item = {
			groupName: this.addNonAdminGroupRequest?.groupName,
			plantId: this.selectedValues?.plant?.plantId
		}

		this.groupService.addGroup(item)
			.subscribe({
				next: (response) => {
					this.router.navigate(['settings/groups'], { state: { selectedValues: { ...this.selectedValues }, firstRender: true, prevPage: "Add_Group", success: true } });
				},
				error: (error) => {
					this.errorService.handleError(error, 'Error adding non-admin group')
				},
			})

	}
}
