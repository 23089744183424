<!-- @format -->
<app-success-toast *ngIf="toastService.showToast" />
<div class="custom-section card">
	<div class="section-header">
		<div>
			<h1 class="section-title">Units</h1>
		</div>
		<div>
			<button
				type="button"
				[ngClass]="!activateAddUnitBtn() ? 'disabled-btn' : ''"
				[routerLink]="!activateAddUnitBtn() ? [] : ['add-unit']"
				[state]="selectedValues">
				Add a unit
			</button>
		</div>
	</div>
	<div class="dropdown-group">
		<app-dropdown
			*ngIf="currentRole === 'Super Admin'"
			[options]="companies"
			selectedItem="{{ selectedValues.company }}"
			(onSelectOption)="setSelectedCompany($event)"
			[fields]="['companyId', 'companyName']"
			[ngStyle]="currentRole === 'Admin' ? specialStyle : defaultStyle"
			[label]="
				selectedValues.company.companyName === ''
					? 'Choose Company'
					: selectedValues.company.companyName
			"></app-dropdown>

		<app-dropdown
			[disabled]="
				selectedValues.company.companyName.length === 0 &&
				currentRole === 'Super Admin'
			"
			[options]="plants"
			selectedItem="{{ selectedValues.plant }}"
			(onSelectOption)="setPlant($event)"
			[fields]="['plantId', 'plantName']"
			[ngStyle]="currentRole === 'Admin' ? specialStyle : defaultStyle"
			[label]="
				resetPlant || selectedValues.plant.plantName === ''
					? 'Choose Plant'
					: selectedValues.plant.plantName
			"></app-dropdown>
	</div>

	<table class="custom-table">
		<thead>
			<tr>
				<th>Unit</th>
				<th>Status</th>
				<th>
					<img
						src="../../assets/dotted-menu.svg"
						alt="menu icon" />
				</th>
			</tr>
		</thead>
		<tbody *ngFor="let unit of units">
			<tr>
				<td>{{ unit.unitName }}</td>
				<td>{{ unit.isDeleted ? "Inactive" : "Active" }}</td>
				<td>
					<img
						*ngIf="!unit.isDeleted"
						src="../../assets/dotted-menu.svg"
						alt="menu icon"
						(click)="toggleMenu(unit.unitId)" />

					<div
						[ngClass]="
							!showMenu || unit.unitId != activeItem
								? 'd-none'
								: ''
						"
						class="custom-table__menu">
						<div>
							{{ unit.unitName }}
						</div>

						<a
							[routerLink]="[
								'/settings',
								'units',
								'edit-unit',
								unit.unitId
							]"
							[state]="selectedValues">
							<img
								src="../../assets/edit-pencil.svg"
								alt="menu icon" />
							<span>Edit Unit</span>
						</a>
						<div class="underline"></div>
						<div
							(click)="
								showDeactivateModal(unit.unitName, unit.unitId);
								showMenu = false
							">
							<img
								src="../../assets/delete.svg"
								alt="menu icon" />
							<span>Deactivate</span>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="center">
		<app-empty-state [data]="emptyStateCompanies"  *ngIf="
			selectedValues.company.companyName.length === 0 && currentRole !== 'Admin'
		"/>
	</div>
	<div class="center">
		<app-empty-state [data]="emptyStatePlants" *ngIf="
		(selectedValues.plant.plantName.length === 0 &&
			selectedValues.company.companyName.length > 0) ||
		(selectedValues.plant.plantName.length === 0 && currentRole === 'Admin')
	"/>
	</div>
	<div class="center">
		<app-empty-state [data]="emptyState" *ngIf="
			selectedValues.plant.plantName.length > 0 &&
			(selectedValues.company.companyName.length > 0 ||
				selectedValues.plant.plantName.length > 0) &&
			units.length === 0
		"/>
	</div>
	<app-pagination [values]="values" (pageChange)="onPageChange($event)" *ngIf="totalPages > 0"/>
</div>
<app-deactivate-modal
	(onCancel)="showDeactivateMenu = false"
	*ngIf="showDeactivateMenu"
	[title]="deactivateText.title"
	[message1]="deactivateText.message1"
	[message2]="deactivateText.message2"
	(onSave)="handleDeactivate()" />
