/** @format */

import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { DataPointValue } from "src/app/models/data-point-value.model"
import { Logsheet } from "src/app/models/logsheet.model"
import { environment } from "src/environments/environment"
import * as moment from 'moment';
import 'moment-timezone';


@Injectable({
	providedIn: 'root',
})
export class LogSheetFormService {
	baseUrl: string = environment.apiUrl;
	constructor(private http: HttpClient) {}
	saveLogSheet(
		id: string,
		data: DataPointValue[],
		isSaving: boolean,
	): Observable<DataPointValue> {
		return this.http.post<DataPointValue>(
			this.baseUrl + '/DataPointLog/' + id,
			{ dataPointIds: data, isSaving: isSaving },
		);
	}

	retrieveLogSheetVersions(id: number): Observable<Logsheet[]> {
		return this.http.get<Logsheet[]>(
			this.baseUrl + `/logsheet/logs-history?logsheetId=${id}`,
		);
	}
}
