/** @format */

import { ActivatedRoute, Router } from "@angular/router"
import { Component } from "@angular/core"

import { CompanyService } from "src/app/services/company/company.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { DialogService } from "src/app/services/modal/dialog.service"
import { Plant } from "src/app/models/plant.model"
import { PlantService } from "src/app/services/plant/plant.service"
import { ToastService } from "src/app/services/toast/toast.service"

import constants from "src/app/constants"

@Component({
	selector: "app-add-admin-user",
	templateUrl: "./add-admin-user.component.html",
	styleUrls: ["./add-admin-user.component.css"],
})
export class AddAdminUserComponent {
	selectedValues = {
		company: {
			companyId: 0,
			companyName: "",
			isDeleted: false,
		},
		plant: {
			plantId: 0,
			plantName: "",
			isDeleted: false,
		},
	}

	constructor(
		private plantService: PlantService,
		private route: ActivatedRoute,
		private router: Router,
		public dialogService: DialogService,
		private userService: CompanyService,
		public toastService: ToastService, // private logsheetService: LogsheetService,
		private errorService: ErrorHandlerService
	) {}

	addAdminRequest = {
		firstName: "",
		lastName: "",
		username: "",
		Roles: [] as string[],
		ids: [] as number[],
	}

	errorMessage: any = {
		success: true,
		message: "",
	}

	activePlants: Plant[] = []
	selectedPlants: any = []
	regex = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}'
	activeStep = 1
	currentRole: any
	companyId: any

	addAdmin = false

	firstRender = false
	searchText: String = ""

	setActiveStep(step: number) {
		this.activeStep = step
	}

	isSelectedPlant(plant: Plant) {
		var index = this.selectedPlants.findIndex((x: Plant) => x == plant)
		return index >= 0
	}

	showSaveModal() {
		this.addAdmin = true
	}

	closeModal() {
		this.addAdmin = false
	}

	addPlant(data: Plant) {
		this.selectedPlants.push(data)
		this.addAdminRequest.ids.push(data.plantId)
	}

	removePlant(index: number) {
		this.selectedPlants.splice(index, 1)
		this.addAdminRequest.ids.splice(index, 1)
	}

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl("settings/user", {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
				prevPage: constants.addAdminUser,
				success: true
			},
		})
	}

	ngOnInit(): void {
		this.currentRole = localStorage.getItem("role")
		this.selectedValues = history.state
		let companyIdToUse: any
		
		if (this.currentRole === "Admin") {
			this.companyId = localStorage.getItem("companyId")
			companyIdToUse = this.companyId
		}

		if (this.currentRole === "Super Admin") {
			companyIdToUse = this.selectedValues.company.companyId.toString()
		}

		this.plantService.retrieveCompanyPlants(companyIdToUse).subscribe({
			next: (plants) => {
				this.activePlants = plants				
			},
			error: (error) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(error)
			},
		})
	}


	isValidStepOne() {
		if (
			this.addAdminRequest.firstName.length >= 2 &&
			this.addAdminRequest.lastName.length >= 2 &&
			this.addAdminRequest.username.match(this.regex)
		) {
			return true
		}
		return false
	}

	enableNextBtn() {
		if (
			(this.activeStep === 1 && !this.isValidStepOne()) ||
			(this.activeStep === 2 && this.selectedPlants.length == 0)
		) {
			return false
		}
		return true
	}

	addAdminUser() {
		// Add variables to request object
		this.addAdminRequest.Roles.push("Admin")
		
		this.userService.addAdminUser(this.addAdminRequest).subscribe({
			next: (response) => {
				this.transferData()
				this.toastService.showToast = true
				this.toastService.toastText = "User successfully added!"
				setTimeout(() => {
					this.toastService.showToast = false
				}, 5000)
			},
			error: (error) => {
				this.errorService.handleError(error, 'Error creating admin user')
			},
		})
	}
}
