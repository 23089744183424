/** @format */

import { Injectable } from "@angular/core"

@Injectable({
	providedIn: "root",
})
export class DialogService {
	showModal = false
	onSave: any
	constructor() {}
}
