<!-- @format -->

<div class="sidebar">
	<div class="sidebar-header">
		<img
			src="../../../assets/logo.svg"
			class="sidebar-header-img"
			alt="" />
		<span class="sidebar-header-divider"></span>
		<span class="sidebar-header-text">Lightyear</span>
	</div>
	<nav>
		<a
			routerLinkActive="active"
			routerLink="home"
			(click)="resetHomeScreen()"
			class="nav-item">
			<div class="">
				<img
					src="../../assets/home.svg"
					alt="" />
			</div>
			Home</a
		>
		<a
			routerLinkActive="active"
			routerLink="analytics"
			class="nav-item">
			<div>
				<img
					src="../../assets/analytics.svg"
					alt="" />
			</div>
			Analytics</a
		>
		<a
			routerLinkActive="active"
			routerLink="settings"
			class="nav-item">
			<div>
				<img
					src="../../assets/settings.svg"
					alt="" 
				/>
			</div>
			Settings</a
		>
	</nav>
</div>
