<!-- @format -->

<div class="card custom-section">
	<h1 class="section-title">Add a plant</h1>
	<span class="subtext"
		>Fill out the plant details using the form below.</span
	>
	<form
		#form="ngForm"
		style="margin-top: 2rem"
		(ngSubmit)="addPlant()">
		<label
			for=""
			class="custom-label required"
			>Company Name</label
		>
		<app-dropdown
			[disabled]="true"
			selectedItem="{{ selectedCompany }}"
			label="{{ selectedCompany.companyName }}"></app-dropdown>
		<div class="form-group"></div>

		<label
			for="plant_name"
			class="custom-label required"
			>Plant Name</label
		>
		<input
			type="text"
			class="custom-input"
			name="plant_name"
			id="plant_name"
			[(ngModel)]="addPlantRequest.plantName" />
		<div
			*ngIf="!errorMessage.success"
			class="error">
			<img
				src="../../assets/alert.svg"
				alt="" />
			<p>
				{{ errorMessage.message }}
			</p>
		</div>
		<input
			type="hidden"
			name="company_is_deleted"
			id="company_is_deleted"
			[(ngModel)]="addPlantRequest.isDeleted" />
		<div class="btn-group">
			<button
				class="btn-outline"
				(click)="transferData()">
				<!-- routerLink=".." -->
				Cancel
			</button>
			<button
				type="submit"
				class="btn-fill">
				Add Plant
			</button>
		</div>
	</form>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
