import { Component, ElementRef, ViewChild } from '@angular/core'
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

interface ResponseModel {
	message: string
	success: boolean
}

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
	@ViewChild('emailInput') emailInput: ElementRef | undefined;
	
	model: ResponseModel = {
		message: '',
		success: false
	}

	constructor(private router: Router, private authService: AuthService) { }

	handleBackButton() {
		this.router.navigate(['/auth/login']);
	}

	handleForgetPassword() {
		const email = this.emailInput?.nativeElement.value;
		this.authService.handleForgetPassword({ email }).subscribe({
			next: (res: any) => {
				this.model.message = res.message;
				this.model.success = res.success;
			},
			error: (error) => {
				this.model.message = error?.error?.message;
				this.model.success = error?.error?.success;
			}
		})
	}
}
