import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.css']
})
export class SubMenuComponent {
  // uses data from parent class
  @Input() subTitle = '';
  @Input () subMenuInfo: any = []
}
