import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Auth } from 'src/app/models/auth.model'
import { AuthService } from 'src/app/services/auth/auth.service'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
    model: any = {
      success: true,
      message: '',
      attempts: 0,
      isLocked: false,
      status: 0
    }
    
    displayPopup: boolean = false;
    displayOverlay: boolean = false;

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
      const token = this.route.snapshot.paramMap.get('token');
      this.model.token = token;
    }

    handleLogin() {   
      const role = localStorage.getItem("role");

      role === "Operator" 
      ? this.router.navigateByUrl('/account-complete', { state: { passwordUpdate: true } } )
      : this.router.navigate(['/auth/login']);
    }

    toggleOverlay() {
      this.displayOverlay = !this.displayOverlay;
    }

    handleResetSubmit() {
      this.authService.handleResetSubmit(this.model).subscribe({
        next: (res:any) => {
          this.displayPopup = true;
        },
        error: (error) => {
          this.model.message = error?.error?.message;
          this.model.success = error?.error?.success;

        }
     })
    }
}
