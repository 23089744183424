/** @format */

import { NgModule, LOCALE_ID } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { NgIdleModule } from "@ng-idle/core"

import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { HeaderComponent } from "./components/header/header.component"
import { SidebarComponent } from "./components/sidebar/sidebar.component"
import { SettingsComponent } from "./pages/settings/settings.component"
import { AnalyticsComponent } from "./pages/analytics/analytics.component"
import { HomeComponent } from "./pages/home/home.component"
import { LoginComponent } from "./pages/login/login.component"
import { DashboardComponent } from "./pages/dashboard/dashboard.component"
import { AddCompanyComponent } from "./components/settings/add-company/add-company.component"
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CompanyComponent } from "./components/settings/company/company.component"
import { SubMenuComponent } from "./components/sub-menu/sub-menu.component"
import { EditCompanyComponent } from "./components/settings/edit-company/edit-company.component"
import { PlantComponent } from "./components/settings/plant/plant.component"
import { EmptyStateComponent } from "./components/empty-state/empty-state.component"
import { AddPlantComponent } from "./components/settings/add-plant/add-plant.component"
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component"
import { ModalComponent } from "./components/modal/modal.component"
import { EditPlantComponent } from "./components/settings/edit-plant/edit-plant.component"
import { AddUnitComponent } from "./components/settings/add-unit/add-unit.component"
import { CustomDropdownComponent } from "./components/custom-dropdown/custom-dropdown.component"
import { TokenInterceptorService } from "./services/token-interceptor/token-inteceptor.service"
import { AuthorisatonErrorComponent } from "./pages/authorisaton-error/authorisaton-error.component"
import { SuccessToastComponent } from ".//components/success-toast/success-toast.component"
import { SaveModalComponent } from "./components/save-modal/save-modal.component"
import { DataPointComponent } from "./components/settings/data-point/data-point.component"
import { AddDataPointComponent } from "./components/settings/add-data-point/add-data-point.component"
import { EditDataPointComponent } from "./components/settings/edit-data-point/edit-data-point.component"
import { DropdownComponent } from "./components/dropdown/dropdown.component"
import { UnitComponent } from "./components/settings/unit/unit.component"
import { AddNonAdminGroupComponent } from "./components/settings/add-group/add-non-admin-group.component"
import { EditUnitComponent } from "./components/settings/edit-unit/edit-unit.component"
import { GroupsComponent } from "./components/settings/groups/groups.component"
import { AddLogsheetComponent } from "./components/settings/add-logsheet/add-logsheet.component"
import { SearchPipe } from "./pipes/search.pipe"
import { DragDropModule } from "@angular/cdk/drag-drop"
import { CheckedCardComponent } from "./components/checked-card/checked-card.component"
import { ScheduleLogsheetComponent } from "./components/settings/schedule-logsheet/schedule-logsheet.component"
import { CustomSwitchComponent } from "./components/custom-switch/custom-switch.component"
import { CustomDateComponent } from "./components/custom-date/custom-date.component"
import { LogsheetComponent } from "./components/settings/logsheet/logsheet.component"
import { EditLogsheetComponent } from "./components/settings/edit-logsheet/edit-logsheet.component"
import { ModificationWindowComponent } from "./components/settings/modification-window/modification-window.component"
import { AssignLogsheetComponent } from "./components/settings/assign-logsheet/assign-logsheet.component"
import { AddSuperUserComponent } from "./components/settings/add-super-user/add-super-user.component"
import { AddAdminUserComponent } from "./components/settings/add-admin-user/add-admin-user.component"
import { EditAdminUserComponent } from "./components/settings/edit-admin-user/edit-admin-user.component"
import { UserComponent } from "./components/settings/user/user.component"
import { AddNonAdminUserComponent } from "./components/settings/add-non-admin-user/add-non-admin-user.component"
import { EditNonAdminUserComponent } from "./components/settings/edit-non-admin-user/edit-non-admin-user.component"
import { ProfileComponent } from "./components/settings/profile/profile.component"
import { DeactivateModalComponent } from "./components/deactivate-modal/deactivate-modal.component"
import { AuditComponent } from "./components/settings/audit/audit.component"
import {
	DatePipe,
	HashLocationStrategy,
	LocationStrategy,
} from "@angular/common"
import { AuditModalComponent } from "./components/audit-modal/audit-modal.component"
import { VerifyEmailComponent } from "./pages/verify-email/verify-email.component"
import { LogSheetFormComponent } from "./components/home/log-sheet-form/log-sheet-form.component"

import {
	OwlDateTimeModule,
	OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"

import { OverviewComponent } from "./pages/overview/overview.component"
import { UnitLogComponent } from "./components/overview/unit-log/unit-log.component"
import { UnitLogEmptyComponent } from "./components/overview/unit-log-empty/unit-log-empty.component"
import { SpinnerComponent } from "./components/spinner/spinner.component"
import { LoaderInterceptor } from "./interceptors/loader.interceptor"
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component"
import { PaginationComponent } from "./components/pagination/pagination.component";
import { CheckedBoxComponent } from './components/checked-box/checked-box.component'
import { InactivityService } from "./services/inactivity-service/inactivity-service.service"
@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		SidebarComponent,
		SettingsComponent,
		AnalyticsComponent,
		HomeComponent,
		LoginComponent,
		DashboardComponent,
		AddCompanyComponent,
		CompanyComponent,
		SubMenuComponent,
		EditCompanyComponent,
		PlantComponent,
		EmptyStateComponent,
		AuthorisatonErrorComponent,
		AddPlantComponent,
		AddPlantComponent,
		UnitComponent,
		ResetPasswordComponent,
		ModalComponent,
		EditPlantComponent,
		AddUnitComponent,
		CustomDropdownComponent,
		SuccessToastComponent,
		SaveModalComponent,
		DataPointComponent,
		AddDataPointComponent,
		EditDataPointComponent,
		DropdownComponent,
		AddPlantComponent,
		EditUnitComponent,
		AddNonAdminGroupComponent,
		GroupsComponent,
		AddLogsheetComponent,
		LogsheetComponent,
		SearchPipe,
		CheckedCardComponent,
		EditLogsheetComponent,
		ModificationWindowComponent,
		ScheduleLogsheetComponent,
		CustomSwitchComponent,
		CustomDateComponent,
		EditLogsheetComponent,
		AssignLogsheetComponent,
		AddSuperUserComponent,
		AddAdminUserComponent,
		EditAdminUserComponent,
		AssignLogsheetComponent,
		UserComponent,
		AddNonAdminUserComponent,
		EditNonAdminUserComponent,
		ProfileComponent,
		DeactivateModalComponent,
		AuditComponent,
		AuditModalComponent,
		VerifyEmailComponent,
		LogSheetFormComponent,
		OverviewComponent,
		UnitLogComponent,
		UnitLogEmptyComponent,
		SpinnerComponent,
		ForgotPasswordComponent,
		PaginationComponent,
  CheckedBoxComponent,
	],
	imports: [
		BrowserModule,
		NgIdleModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		DragDropModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true,
		},
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptor,
			multi: true,
		},
		InactivityService,
		DatePipe,
	],

	bootstrap: [AppComponent],
})
export class AppModule {}
