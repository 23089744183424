/** @format */

import { Component, OnInit, Pipe, PipeTransform } from "@angular/core"
import { DataPoint } from "src/app/models/datapoint.model"
import { DataPointService } from "src/app/services/data-point/data-point.service"
import { ActivatedRoute, Router } from "@angular/router"
import { SearchPipe } from "src/app/pipes/search.pipe"
import { moveItemInArray } from "@angular/cdk/drag-drop"
import { DialogService } from "src/app/services/modal/dialog.service"
import { ToastService } from "src/app/services/toast/toast.service"
import { LogsheetService } from "src/app/services/logsheet/logsheet.service"
import { Logsheet } from "src/app/models/logsheet.model"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
@Component({
	selector: "app-add-logsheet",
	templateUrl: "./add-logsheet.component.html",
	styleUrls: ["./add-logsheet.component.css"],
})
export class AddLogsheetComponent implements OnInit {
	selectedValues = {
		company: {
			companyId: 0,
			companyName: "",
		},
		plant: {
			plantId: 0,
			plantName: "",
		},
		unit: {
			unitId: 0,
			unitName: "",
		},
	}

	constructor(
		private dataPointService: DataPointService,
		private route: ActivatedRoute,
		private router: Router,
		public dialogService: DialogService,
		public toastService: ToastService,
		private logsheetService: LogsheetService,
		private errorService: ErrorHandlerService
	) {}

	addLogsheetRequest = {
		logSheetTemplateId: 0,
		logSheetName: "",
		isDeleted: false,
		datapointId: [] as number[],
		unitId: 0,
		isEnabled: true,
	}

	errorMessage: any = {
		success: true,
		message: "",
	}

	activeDataPoints: DataPoint[] = []
	selectDataPoints: any = []
	activeStep = 1
	currentRole: any

	firstRender = false
	searchText: String = ""

	setActiveStep(step: number) {
		this.activeStep = step
	}

	isSelectedDataPoint(dataPoint: DataPoint) {
		var index = this.selectDataPoints.findIndex(
			(x: DataPoint) => x == dataPoint,
		)
		return index >= 0
	}

	showSaveModal() {
		this.dialogService.showModal = true
	}

	isValidLogsheetName() {
		return (
			this.addLogsheetRequest.logSheetName.length >= 3 &&
			this.addLogsheetRequest.logSheetName.length <= 50
		)
	}

	addDataPoint(data: DataPoint) {
		this.selectDataPoints.push(data)
		this.addLogsheetRequest.datapointId.push(data.dataPointId)
	}

	removeDataPoint(index: number) {
		this.selectDataPoints.splice(index, 1)
		this.addLogsheetRequest.datapointId.splice(index, 1)
	}

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl("settings/logsheets", {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
			},
		})
	}

	ngOnInit(): void {
		this.currentRole = localStorage.getItem("role")
		this.selectedValues = history.state

		this.dataPointService
			.retrieveDataPointByUnitId(
				this.selectedValues.unit.unitId.toString(),
			)
			.subscribe({
				next: (dataPoints) => {
					this.activeDataPoints = dataPoints
				},
				error: (err) => {
					this.errorMessage = this.errorService.handleRetrievalErrors(err)
				},
			})
	}

	enableNextBtn() {
		if (
			(this.activeStep === 1 && !this.isValidLogsheetName()) ||
			(this.activeStep === 2 && this.selectDataPoints.length == 0)
		) {
			return false
		}
		return true
	}

	drop(event: any) {
		moveItemInArray(
			this.selectDataPoints,
			event?.previousIndex,
			event?.currentIndex,
		)
	}

	addLogsheet() {
		// Add variables to request object
		this.addLogsheetRequest.unitId = this.selectedValues.unit.unitId

		this.logsheetService.addLogSheet(this.addLogsheetRequest).subscribe({
			next: (response) => {
				this.transferData()
				this.toastService.showToast = true
				this.toastService.toastText = "Log Sheet successfully added!"
				setTimeout(() => {
					this.toastService.showToast = false
				}, 5000)
			},
			error: (response) => {
				this.errorService.handleError(response, 'Error adding a Log Sheet')
			},
		})
	}
}
