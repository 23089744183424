/** @format */

import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { Logsheet } from "src/app/models/logsheet.model"
import { Plant } from "src/app/models/plant.model"
import { Unit } from "src/app/models/unit.model"
import { CompanyService } from "src/app/services/company/company.service"
import { DataPointService } from "src/app/services/data-point/data-point.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { LogsheetService } from "src/app/services/logsheet/logsheet.service"
import { PlantService } from "src/app/services/plant/plant.service"
import { ToastService } from "src/app/services/toast/toast.service"
import { UnitService } from "src/app/services/unit/unit.service"

@Component({
	selector: 'app-logsheet',
	templateUrl: './logsheet.component.html',
	styleUrls: ['./logsheet.component.css'],
})
export class LogsheetComponent {
	companies: Company[] = []
	plants: Plant[] = []
	units: Unit[] = []
	logsheets: Logsheet[] = []
	currentRole: any = ''
	assignedPlants: any = []
	firstRender: boolean = history?.state?.firstRender ?? false
	showDeactivateMenu = false
	showMenu = false
	activeItem = 0
	$: any
	selectedLogsheet: number = 0

	//pagination data
	currentPage: number = 1
	data: Plant[] = []
	pageSize: number = 5
	totalPages: number = 1
	loopArray: number[] = []

	deactivateText = {
		title: 'Deactivate Sheet',
		message1: '',
		message2: 'This action is irreversible. Do you wish to proceed?',
	}

	emptyStateCompanies = {
		imgPath: '../../assets/logsheet.svg',
		message1: 'Select a company to see associated log sheets listed here',
		message2: '',
	}

	emptyStatePlants = {
		imgPath: '../../assets/logsheet.svg',
		message1: 'Select a plant to see associated log sheets listed here',
		message2: '',
	}

	emptyStateUnits = {
		imgPath: '../../assets/logsheet.svg',
		message1: 'Select a unit to see associated log sheets listed here',
		message2: '',
	}

	emptyStateDataPoints = {
		imgPath: '../../assets/logsheet.svg',
		message1: 'No log sheets to display',
		message2: 'Created log sheets will appear here.',
	}

	errorMessage = {
		success: true,
		message: '',
	}

	get values() {
		return {
			data: this.data,
			currentPage: this.currentPage,
			totalPages: this.totalPages,
			loadData: this.loadData,
			loopArray: this.loopArray,
		}
	}

	plantDetails: Plant = {
		plantId: 0,
		plantName: '',
		isDeleted: false,
	}

	selectedValues = history?.state?.selectedValues ?? {
		company: {
			companyId: 0,
			companyName: '',
			isDeleted: false,
		},
		plant: {
			plantId: 0,
			plantName: '',
			isDeleted: false,
		},
		unit: {
			unitId: 0,
			unitName: '',
			isDeleted: false,
			// status: false,
		},
		id: 0,
	}

	constructor(
		private route: ActivatedRoute,
		private plantService: PlantService,
		private companyService: CompanyService,
		private dataPointService: DataPointService,
		private unitService: UnitService,
		private router: Router,
		public toastService: ToastService,
		public logsheetService: LogsheetService,
		private errorService: ErrorHandlerService,
	) {}

	activateAddLogSheetBtn() {
		if (this.currentRole === 'Super Admin') {
			if (
				this.selectedValues?.company?.companyId === 0 ||
				this.selectedValues?.plant?.plantId === 0 ||
				this.selectedValues?.unit?.unitId === 0
			) {
				return false
			} else if (
				this.selectedValues?.company?.isDeleted ||
				this.selectedValues?.plant?.isDeleted ||
				this.selectedValues?.unit?.isDeleted
			) {
				return false
			} else {
				return true
			}
		} else if (this.currentRole === 'Admin') {
			if (
				this.selectedValues?.plant?.plantId === 0 ||
				this.selectedValues?.unit?.unitId === 0
			) {
				return false
			} else if (
				this.selectedValues?.plant?.isDeleted ||
				this.selectedValues?.unit?.isDeleted
			) {
				return false
			} else {
				return true
			}
		}
		return true
	}

	handleDeactivate() {
		this.logsheetService
			.deactivateLogSheet(this.selectedLogsheet)
			.subscribe({
				next: (response) => {
					this.retrieveLogsheetsByUnitId(this.selectedValues?.unit)
					this.handleSuccessToast('LogSheet-Deactivated')
				},
				error: (err) => {
					// fallback
					this.logsheetService
						.retrieveLogsheetRelative(
							this.selectedLogsheet.toString(),
						)
						.subscribe({
							next: (resp: any) => {
								let id = resp.logSheetTemplateId

								this.logsheetService
									.deactivateLogSheet(id)
									.subscribe({
										next: (response) => {
											this.retrieveLogsheetsByUnitId(
												this.selectedValues?.unit,
											)
											this.handleSuccessToast(
												'LogSheet-Deactivated',
											)
										},
										error: (error) => {
											this.errorService.handleError(
												error,
												'Error deactivating logsheet',
											)
										},
									})
							},
							error: (error) => {
								this.errorService.handleError(
									error,
									'Error deactivating logsheet',
								)
							},
						})
				},
			})
	}

	handleSuccessToast(state: any) {
		if (state === 'LogSheet-Deactivated') {
			this.toastService.toastText = 'Log Sheet succesfully deactivated!'
		}
		this.toastService.showToast = true
		setTimeout(() => {
			this.toastService.showToast = false
		}, 5000)
	}

	loadData(selected: any) {
		this.selectedValues = selected

		this.logsheetService
			.retrieveLogsheetByUnitPaginated(
				this.selectedValues?.unit?.unitId,
				this.currentPage,
				this.pageSize,
			)
			.subscribe({
				next: (result: any) => {
					this.logsheets = result.item1
					this.data = result.item1
					this.totalPages = result.item2
					this.updateLoopArray()
				},
				error: (err) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(err)
				},
			})
	}

	onPageChange(pageNumber: any) {
		this.currentPage = pageNumber
		this.loadData(this.selectedValues)
	}

	retrieveLogsheetsByUnitId(selected: any) {
		if (!this.firstRender) {
			this.selectedValues = {
				...this.selectedValues,
				unit: selected,
			}
		}

		this.loadData(this.selectedValues)
	}

	retrievePlantsByCompanyId(selected: any) {
		if (!this.firstRender) {
			this.selectedValues = {
				...this.selectedValues,
				company: selected,
			}

			this.selectedValues.plant = {
				plantId: 0,
				plantName: '',
				isDeleted: false,
			}
			this.selectedValues.unit = {
				unitId: 0,
				unitName: '',
				isDeleted: false,
			}
			this.logsheets = []
		}
		this.plantService
			.retrieveCompanyPlants(
				this.selectedValues?.company?.companyId.toString(),
			)
			.subscribe({
				next: (response) => {
					this.plants = response
				},
				error: (err) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(err)
				},
			})
	}

	retrieveUnitsByPlantId(selected: any) {
		if (!this.firstRender) {
			this.selectedValues = {
				...this.selectedValues,
				plant: selected,
			}

			this.selectedValues.unit = {
				unitId: 0,
				unitName: '',
				isDeleted: false,
			}
			this.logsheets = []
		}

		this.unitService
			.retrieveUnitPlant(this.selectedValues?.plant?.plantId.toString())
			.subscribe({
				next: (response) => {
					this.units = response
				},
				error: (err) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(err)
				},
			})
	}

	showDeactivateModal(name: string, id: number) {
		this.selectedLogsheet = id
		this.deactivateText.message1 =
			'You are about to deactivate ' +
			name +
			' in Lightyear. Doing this will prevent further filling of this log sheet.'
		this.showDeactivateMenu = true
	}

	showPlantEmptyState() {
		if (
			this.currentRole === 'Super Admin' &&
			this.selectedValues?.company?.companyId > 0 &&
			(this.plants?.length === 0 ||
				this.selectedValues?.plant?.plantId === 0) &&
			this.selectedValues?.unit?.unitId === 0
		) {
			return true
		} else if (
			this.currentRole === 'Admin' &&
			(this.plants?.length === 0 ||
				this.selectedValues?.plant?.plantId === 0) &&
			this.selectedValues?.unit?.unitId === 0
		) {
			return true
		} else {
			return false
		}
	}

	toggleMenu(plantId: number) {
		this.activeItem = plantId
		this.showMenu = !this.showMenu
	}

	transferData(path: string, id: number, logSheetName: string) {
		this.firstRender = true
		this.router.navigateByUrl(`settings/logsheets/${path}/${id}`, {
			state: {
				selectedValues: { ...this.selectedValues },
				logSheetName: logSheetName,
			},
		})
	}

	updateLoopArray() {
		this.loopArray = new Array(this.totalPages)
			.fill(0)
			.map((_, index) => index + 1)
	}

	ngOnInit(): void {
		this.currentRole = localStorage.getItem('role')
		this.firstRender = history.state.firstRender

		if (this.firstRender == true) {
			this.selectedValues = { ...history?.state?.selectedValues }

			this.retrievePlantsByCompanyId(this.selectedValues?.company)
			this.retrieveUnitsByPlantId(this.selectedValues?.plant)
			this.retrieveLogsheetsByUnitId(this.selectedValues?.unit)
			this.firstRender = false
		}

		try {
			const storedAssignment = localStorage.getItem('assignedPlants')
			if (storedAssignment) {
				this.assignedPlants = JSON.parse(storedAssignment)
			} else {
				console.error(
					"No data found in local storage for key 'assignedPlants' ",
				)
			}
		} catch (error) {
			console.error('Error parsing JSON data from local storage:', error)
		}

		if (this.currentRole === 'Admin') {
			this.assignedPlants.map((assignment: Plant) =>
				this.plants.push(assignment),
			)
		} else {
			this.companyService.retrieveCompanies().subscribe({
				next: (companies) => {
					this.companies = companies
				},
				error: (response) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(response)
				},
			})
		}
	}
}
