/** @format */

import { Component, HostListener, Inject, LOCALE_ID } from '@angular/core'
import { formatDate, Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastService } from 'src/app/services/toast/toast.service'
import { DatePipe } from '@angular/common'
import { LogSheetFormService } from 'src/app/services/log-sheet-form/log-sheet-form.service'
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service'
import { LogsheetService } from 'src/app/services/logsheet/logsheet.service'
import { environment } from 'src/environments/environment'
import * as _ from 'lodash';

@Component({
	selector: 'app-log-sheet-form',
	templateUrl: './log-sheet-form.component.html',
	styleUrls: ['./log-sheet-form.component.css'],
})
export class LogSheetFormComponent {
	isVersionMenuVisible: boolean = false;
	isNoteMenuVisible: boolean = false;
	selectedDataPointIdx: number = 0;
	showNoteModal: boolean = false;
	note: string = '';
	showDeactivateMenu = false;
	activeVersion: number = 0;
	versions: any = [];
	baseUrl: string = environment.apiUrl;

	logSheet: any = {};

	lastModifiedDate = '';
	errorMessage: { success: boolean; message: string } = {
		success: true,
		message: '',
	};

	initialDataPointValues: any = [];
	dataPoints: any = [];
	dataPointValues: any = [];
	currentState: any = [];

	deactivateText = {
		title: 'Delete Note',
		message1: '',
		message2: '',
	};

	errorMsgEmpty = 'This field is empty, please input a value or add a note';
	errorMsgNAN = 'Please enter a valid number';
	errorMsgMinValue = 'Please enter at least 2 characters';
	errorMsgDenominator = 'The Denominator cannot be set to zero';
	errorIntegerOnly = 'Only integers are accepted';
	errorMsgRange =
		'This entry is out of range. Please revise entry or leave a note.';

	constructor(
		@Inject(LOCALE_ID) public locale: string,
		private route: ActivatedRoute,
		private router: Router,
		public toastService: ToastService,
		private logSheetFormService: LogSheetFormService,
		private logSheetService: LogsheetService,
		private errorService: ErrorHandlerService,
		private location: Location,
		private datePipe: DatePipe,
	) {}

	stringToListConverter(list: string) {
		if (list === '' || list == null) {
			return [];
		}
		return list.split(',').map((e: any) => e.trim());
	}

	handleMultiSelect(dataPointIdx: any, option: string) {
		let selectedValues = this.stringToListConverter(
			this.dataPointValues[dataPointIdx]?.value,
		);

		if (selectedValues?.indexOf(option) != -1) {
			selectedValues = [
				...selectedValues?.filter((e: any) => e.trim() != option),
			];
		} else {
			selectedValues = [...selectedValues, option];
		}
		this.dataPointValues[dataPointIdx]['value'] = selectedValues?.join(',');
	}

	isMultiSelected(dataPointIdx: any, option: string) {
		if (
			(this.activeVersion == 0 &&
				this.stringToListConverter(
					this.dataPointValues?.[dataPointIdx]['value'],
				)?.indexOf(option) >= 0) ||
			(this.activeVersion > 0 &&
				this.stringToListConverter(
					this.versions?.[dataPointIdx]?.['dataPointValue'][
						this.activeVersion
					]?.['value'],
				)?.indexOf(option) != -1)
		) {
			return true;
		}
		return false;
	}

	isSingleSelected(dataPointIdx: any, option: string) {
		if (
			(this.activeVersion == 0 &&
				this.dataPointValues?.[dataPointIdx]['value'].toLowerCase() ==
					option.toLowerCase()) ||
			(this.activeVersion > 0 &&
				this.versions?.[dataPointIdx]?.['dataPointValue'][
					this.activeVersion
				]?.['value']
					.toLowerCase()
					.trim() == option.toLowerCase())
		) {
			return true;
		}
		return false;
	}

	singleSelect: any = {};
	handleSingleSelect(idx: any, option: any) {
		this.dataPointValues[idx].value = option.trim();
	}

	isChecked(idx: number, option: string): boolean {
		return this.dataPointValues[idx].value === option;
	}

	ngOnInit(): void {
		this.logSheet = history.state.logSheet ?? {};
		if (
			this.logSheet.logSheetTemplated.scheduleType.toLowerCase() !==
			'ondemand'
		) {
			this.updateLogsheetStatus('inProgress');
		}

		this.dataPoints =
			this.logSheet['logSheetTemplated']['dataPointDetails'];
		this.initialDataPointValues = this.getDataPointValues(this.dataPoints);
		this.dataPointValues = this.getDataPointValues(this.dataPoints);

		if (
			this.logSheet['nonAdminEditCount'] +
				this.logSheet['adminEditCount'] >
			0
		) {
			this.retrievePreviousVersions();
		}
	}

	getDataPointValues(value: any) {
		let data = [];
		for (let e of value) {
			data.push({
				dataPointId: e['dataPointId'],
				value:
					e['dataPointValue'].length == 0
						? ''
						: e['dataPointValue'][0]['value'],
				note:
					e['dataPointValue'].length == 0
						? ''
						: e['dataPointValue'][0]['note'],
			});
		}

		return data;
	}

	customComparator(value1: any, value2: any): boolean | undefined {
		if (typeof value1 === 'number' || typeof value2 === 'number') {
			return value1 == value2; // Loose equality
		}
		return undefined; // Let _.isEqualWith handle strict equality
	}

	isEnabled() {
		if (
			this.activeVersion != 0 ||
			_.isEqualWith(
				this.initialDataPointValues,
				this.dataPointValues,
				this.customComparator,
			)
		) {
			return false;
		}
		return true;
	}

	@HostListener('window:beforeunload', ['$event'])
	unloadHandler(event: Event) {
		this.updateLogsheetStatus(this.logSheet.status, '0');
		const status = { status: this.logSheet.status, currentUser: '0' };
		const isSuccess = navigator.sendBeacon(
			this.baseUrl + '/logsheet/logs/' + this.logSheet.logSheetId,
			JSON.stringify(status),
		);

		event.returnValue = isSuccess;
	}

	extractNumerator(value: string) {
		if (value.length === 0) {
			return '';
		}
		return value.split('/')[0];
	}

	extractDenominator(value: string) {
		if (value.length === 0) {
			return '';
		}
		return value.split('/')[1];
	}

	onNumeratorChange(e: Event, idx: number) {
		if (this.dataPointValues[idx]?.value?.length === 0) {
			this.dataPointValues[idx].value = `${
				(e?.target as HTMLInputElement).value
			}/`;
			return;
		}
		const denominator = this.extractDenominator(
			this.dataPointValues[idx]?.value,
		);
		this.dataPointValues[idx].value = `${
			(e.target as HTMLInputElement).value
		}/${denominator}`;
	}

	onDenominatorChange(e: any, idx: number) {
		if (this.dataPointValues[idx]?.value?.length === 0) {
			this.dataPointValues[idx].value = `/${e.target.value}`;
			return;
		}
		const numerator = this.extractNumerator(
			this.dataPointValues[idx]?.value,
		);
		this.dataPointValues[idx].value = `${numerator}/${e.target.value}`;
	}

	updateLogsheetStatus(status: string, currentUser?: string) {
		this.logSheetService
			.updateLogSheetStatus(this.logSheet.logSheetId, {
				status: status,
				currentUser,
			})
			.subscribe({
				next: (res) => {},
				error: (err) => {
					this.errorService.handleError(
						err,
						'Could not update logsheet status',
					);
				},
			});
	}

	ngOnDestroy() {
		// reverts logsheet status to original state
		this.updateLogsheetStatus(this.logSheet.status, '0');
	}

	dateTimeFormatter(date: string, idx: number): void {
		this.dataPointValues[idx]['value'] = this.datePipe.transform(
			date,
			'MMMM d, y, h:mm a',
		);
	}

	retrievePreviousVersions(): void {
		this.logSheetFormService
			.retrieveLogSheetVersions(this.logSheet['logSheetId'])
			.subscribe({
				next: (response) => {
					this.versions =
						response[0]['logSheetTemplated']['dataPointDetails'];
				},
				error: (err) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(err);
				},
			});
	}

	setActiveVersion(value: number): void {
		this.activeVersion = value;
	}
	toggleVersions(): void {
		this.isVersionMenuVisible = !this.isVersionMenuVisible;
	}

	toggleNoteMenu(idx: number): void {
		this.selectedDataPointIdx = idx;

		this.isNoteMenuVisible = !this.isNoteMenuVisible;
	}

	toggleNoteModal(): void {
		this.isNoteMenuVisible = false;
		this.showNoteModal = !this.showNoteModal;
		if (this.showNoteModal) {
			this.note =
				this.dataPointValues[this.selectedDataPointIdx]['note'] ?? '';
		} else {
			this.note = '';
		}
	}

	showDeactivateModal(): void {
		this.isNoteMenuVisible = false;
		this.deactivateText.message1 =
			'You are about to delete ' +
			this.dataPoints[this.selectedDataPointIdx]['dataPointName'] +
			' Note. This change is irreversible. Do you wish to proceed? ';
		this.showDeactivateMenu = true;
	}

	isNoteSubmitBtnEnabled(): boolean {
		if (
			this.note.trim().length < 2 ||
			(this.isEditing() &&
				this.note ==
					this.dataPointValues[this.selectedDataPointIdx]['note'])
		) {
			return false;
		}
		return true;
	}

	isEditing(): boolean {
		if (
			this.dataPointValues[this.selectedDataPointIdx]['note'] == '' ||
			this.dataPointValues[this.selectedDataPointIdx]['note'] == null
		) {
			return false;
		}
		return true;
	}

	invalidFields: any = {};

	isEmpty(obj: any) {
		return Object.entries(obj).length === 0;
	}

	hasTrueForDPId(dpId: string): boolean {
		if (this.singleSelect.hasOwnProperty(dpId)) {
			const dpIdValues = Object.values(this.singleSelect[dpId]);
			return dpIdValues.includes(true);
		}
		return false;
	}

	validateFields(): void {
		let invalidFields = {};
		for (let i = 0; i < this.dataPointValues?.length; i++) {
			if (this.dataPointValues[i]['note'].length > 0) {
				// break
			} else if (
				this.dataPointValues[i]['value'] == null ||
				this.dataPointValues[i]['value'].toString() == '' ||
				(this.dataPoints[i]['dataType'] == 'Fraction' &&
					(this.extractNumerator(this.dataPointValues[i]['value']) ==
						'' ||
						this.extractDenominator(
							this.dataPointValues[i]['value'],
						) == ''))
			) {
				invalidFields = { ...invalidFields, [i]: this.errorMsgEmpty };
			} else if (
				this.dataPoints[i]['dataType'] == 'Text' &&
				this.dataPointValues[i]['value'].length < 2
			) {
				invalidFields = {
					...invalidFields,
					[i]: this.errorMsgMinValue,
				};
			} else if (
				this.dataPoints[i]['dataType'] == 'Number' &&
				(parseFloat(this.dataPointValues[i]['value']) <
					this.dataPoints[i]['dataPointMin'] ||
					parseFloat(this.dataPointValues[i]['value']) >
						this.dataPoints[i]['dataPointMax'])
			) {
				invalidFields = { ...invalidFields, [i]: this.errorMsgRange };
			} else if (
				this.dataPoints[i]['dataType'] == 'Fraction' &&
				parseInt(
					this.extractDenominator(this.dataPointValues[i]['value']),
				) == 0
			) {
				invalidFields = {
					...invalidFields,
					[i]: this.errorMsgDenominator,
				};
			}
		}

		this.invalidFields = invalidFields;
	}

	updateNote(): void {
		let isEditing = this.isEditing();
		this.dataPointValues[this.selectedDataPointIdx]['note'] =
			this.note.trim();
		this.toggleNoteModal();
		this.toastService.showToast = true;
		this.toastService.toastText = isEditing
			? 'Note Edited!'
			: 'Note Added!';
		setTimeout(() => {
			this.toastService.showToast = false;
		}, 5000);
	}

	deleteNote(): void {
		this.dataPointValues[this.selectedDataPointIdx]['note'] = '';

		this.note = '';
		this.showDeactivateMenu = false;
		this.toastService.showToast = true;
		this.toastService.toastText = 'Note Deleted!';
		setTimeout(() => {
			this.toastService.showToast = false;
		}, 5000);
	}

	setCurrentTimestamp(idx: number): void {
		let date = new Date().toISOString();
		this.dataPointValues[idx]['value'] = this.datePipe.transform(
			date,
			'MMMM d, y h:mm a',
		);
	}

	printPage() {
		window.print();
	}

	saveLogSheet(): void {
		this.validateFields();
		// populate values

		if (Object.keys(this.invalidFields).length == 0) {
			this.logSheetFormService
				.saveLogSheet(
					this.logSheet['logSheetId'],
					this.dataPointValues,
					false,
				)
				.subscribe({
					next: (response) => {
						if (
							this.logSheet.logSheetTemplated.scheduleType.toLowerCase() !==
							'ondemand'
						) {
							this.logSheet.status = 'executed';
						} else {
							this.logSheet.status = 'available';
						}
						this.toastService.showToast = true;
						this.toastService.toastText = 'Submitted Successfully!';
						this.location.back();
						setTimeout(() => {
							this.toastService.showToast = false;
						}, 5000);
					},
					error: (error) => {
						this.errorService.handleError(
							error,
							'Error submitting logsheet changes',
						);
					},
				});
		}
	}
}
