import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Auth } from 'src/app/models/auth.model';
import { BehaviorSubject, map } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl: string = environment.apiUrl
   
 
 private currentUserSource = new BehaviorSubject<Auth | null>(null)
  currentUser$ = this.currentUserSource.asObservable();
  constructor(private http: HttpClient) {
   }

  login(model: any) {
    return this.http.post<Auth>(this.baseUrl + '/auth/login', model).pipe(
      map((response: Auth) => {
        const user = response;
        if (user) {
          localStorage.setItem('token', user?.message?.jwtToken);
          localStorage.setItem('role', user.message.role)
          localStorage.setItem('firstName', user.message.firstName)
          localStorage.setItem('lastName', user.message.lastName)
          localStorage.setItem('email', model.username)
          localStorage.setItem('assignedPlants', JSON.stringify(user.message.assignedPlants))
          this.currentUserSource.next(user)
        }
      })
    )
  }

  getUserDetails() {
    return this.http.get<any>(this.baseUrl + '/auth/user-details', {}).pipe(
      map((response: any) => {
        const user = response;
        return user;
      })
    )
  }

  deactivateUser(email:string) {
    return this.http.patch<any>(this.baseUrl + '/auth/deactivate-user', {email}).pipe(
      map((response: any) => {
        const user = response;
        return user;
      })
    )
  }

  updateUserDetails(model: any) {
    return this.http.patch<any>(this.baseUrl + '/auth/update-details', model).pipe(
      map((response: any) => {
        const user = response;
        return user;
      })
    )
  }

  getToken() {
    return localStorage.getItem('token');
  }

  
  handleForgetPassword(model: any) {
    return this.http.post<Auth>(this.baseUrl + '/user/requestPasswordReset', model).pipe()
  }

  handleResetSubmit(model: any) {
    return this.http.post<Auth>(this.baseUrl + '/user/resetPassword', model).pipe(
      map((response: Auth) => {
        const user = response;
        // disable auto login of user on password update
        if (user) {
          // localStorage.setItem('token', user.message.jwtToken)
          localStorage.setItem('role', user.message.role)
          // this.currentUserSource.next(user)
        }
      })
    )
  }

    handleVerifyEmail(model: any) {
    return this.http.post<Auth>(this.baseUrl + '/auth/update-password', model).pipe(
      map((response: Auth) => {
        const user = response;
        if (user) {
          localStorage.setItem('role', user.role[0])
        }
      })
    )
  }

  setCurrentUser(user: Auth) {
    this.currentUserSource.next(user)
  }
  
  logout() {
    localStorage.removeItem('token');
    this.currentUserSource.next(null);
  }

  register(model: any) {
    return this.http.post<Auth>(this.baseUrl + '/auth/register', model)
  }

  updateUser(id: number, payload: any) {
    return this.http.patch<Auth>(this.baseUrl + `/auth/edit-non-admin-user/${id}`, payload)
  }


  // /api/Auth/edit-non-admin-user/{id}
  
  // register(model: any){
  //   return this.http.post<Auth>(this.baseUrl + '/auth/register', model).pipe(
  //     map((response: Auth) => {
  //       const user = response;
  //       if (user) {
  //         localStorage.setItem('user', JSON.stringify(user))
  //         this.currentUserSource.next(user)
  //       }
  //     })
  //   )
  // }
}
