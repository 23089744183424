<div class="list_checkbox">
    <img
        (click)="selectIcon()"
        class="img_checkbox"
        src={{icon}}
        alt="checkbox"
    />
    <div class="list_label">
        {{label}}
    </div>
</div>