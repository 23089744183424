<!-- @format -->

<div class="unit-wrapper">
	<div class="unit-navigation">
		<img
			src="../../assets/back-arrow-icon.svg"
			alt="back navigation" />
		<div class="prev-page">User</div>
		<img
			src="../../assets/forward-arrow-icon.svg"
			alt="back navigation" />
		<div class="forward-page">Edit User</div>
	</div>
	<div
		class="card add-unit"
		style="overflow: scroll">
		<h1 class="section-title">Edit a Non Admin User</h1>
		<p class="subtext">{{ subtext }}</p>
		<form
			#form="ngForm"
			(ngSubmit)="activateSubmitBtn() ? editNonAdminUser() : null"
			style="margin-top: 2rem">
			<div class="input-container">
				<div class="input-item">
					<div class="profile-container">
						<img
							src="../../assets/profile-icon.svg"
							alt="" />
						<div class="details-container">
							<span class="details-header"
								>{{ selectedUser.firstName }}
								{{ selectedUser.lastName }}</span
							>
							<span class="details-sub">{{
								selectedUser.email
							}}</span>
						</div>
					</div>
				</div>
				<div
					class="input-item"
					*ngIf="currentRole === 'Super Admin'">
					<label class="custom-label required">Company</label>
					<app-dropdown
						[inputStyle]="dropdownStyles"
						[inputWrapperStyle]="dropdownWrapperStyle"
						*ngIf="currentRole === 'Super Admin'"
						[label]="
							resetCompany
								? 'Choose Company'
								: selectedCompany.companyName ||
								  'Choose Company'
						"
						[disabled]="true"
						[options]="companies"
						selectedItem="{{ selectedCompany }}"
						(onSelectOption)="retrievePlantsByCompanyId($event)"
						[fields]="['companyId', 'companyName']">
					</app-dropdown>
				</div>
			</div>

			<div class="input-container">
				<div class="input-item">
					<label class="custom-label required">Plant</label>
					<app-dropdown
						[inputStyle]="dropdownStyles"
						[inputWrapperStyle]="dropdownWrapperStyle"
						[disabled]="true"
						[options]="plants"
						selectedItem="{{ selectedPlant }}"
						(onSelectOption)="retrieveUnitsByPlantId($event)"
						[fields]="['plantId', 'plantName']"
						[label]="
							resetPlant || selectedPlant.plantId == 0
								? 'Choose Plant'
								: selectedPlant.plantName
						">
					</app-dropdown>
				</div>

				<div class="input-item">
					<label class="custom-label required">Group</label>
					<app-dropdown
						[disabled]="selectedPlant.plantId === 0"
						(onSelectOption)="handleSelectGroup($event)"
						[options]="groups"
						[fields]="['groupId', 'groupName']"
						[label]="
							resetPlant || selectedGroup.groupId == 0
								? 'Choose Group'
								: selectedGroup.groupName
						">
					</app-dropdown>
				</div>
			</div>
			<div class="btn-group">
				<a
					routerLink=".."
					[state]="[selectedCompany, selectedPlant, pageDetails]"
					class="btn-outline">
					Cancel
				</a>
				<button
					[ngClass]="!activateSubmitBtn() ? 'disabled-btn' : ''"
					class="btn-fill"
					type="submit">
					Update User
				</button>
			</div>
		</form>
	</div>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
