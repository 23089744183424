import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Auth } from 'src/app/models/auth.model'
import { AuthService } from 'src/app/services/auth/auth.service'
import { VersionService } from 'src/app/shared/version.service'
import { environment } from 'src/environments/environment'
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent {  
    model: any = {
      success: true,
      message: '',
      attempts: 0,
      isLocked: false,
      status: 0,
      year: new Date().getFullYear(),
      version : ''
    }

    constructor(private authService: AuthService, private router: Router, private versionService: VersionService) {
        this.model.version = versionService.version ?? '1.0.0-beta1'
    }
    
    showExpire = false;

    ngOnInit(): void {
        const item = localStorage.getItem("sessionInvalid");
        if (item === "true") {
            localStorage.removeItem("sessionInvalid");
            this.showExpire = true;
        }
    }

    login() {
        this.authService.login(this.model).subscribe({
            next: () => {
                this.router.navigate(['/home'])
            },
            error: (error) => {
                this.model = error?.error;
            }
        })
    }

    handleForgetPassword() {
        this.router.navigate(['/forgot-password'])
    }
}
