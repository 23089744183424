import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/company.model';
import { Group } from 'src/app/models/group.model';
import { Plant } from 'src/app/models/plant.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';
import { GroupService } from 'src/app/services/group/group.service';
import { PlantService } from 'src/app/services/plant/plant.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-edit-non-admin-user',
  templateUrl: './edit-non-admin-user.component.html',
  styleUrls: ['./edit-non-admin-user.component.css']
})
export class EditNonAdminUserComponent {

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private plantService: PlantService,
		private companyService: CompanyService,
    private router: Router,
    public toastService: ToastService,
    private authService: AuthService,
    private errorService: ErrorHandlerService
  ) {
  }

  subtext:string = "Select a Group to edit."
  companies: Company[] = []
	plants: Plant[] = []
  groups: Group[] = []

  selectedCompany = {
		companyId: 0,
		companyName: "",
    isDeleted: false
	}

  selectedPlant = {
		plantId: 0,
		plantName: "",
    isDeleted: false
	}

  selectedRole = "Non-Admin User";
  firstName = "";
  lastName = "";
  email = "";
  resetPlant = false
  resetCompany = false;
  currentRole:any = "";
  selectedUser: User = {
    userId: 0,
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    group: "",
    isDeleted: false
  }
  errorMessage = {
		success: true,
		message: ''
	}

  dropdownStyles = {
    // width: '260px',
    // height: '43px',
    // marginRight: '25px',
    // borderColor: 'var(--default-gray-400, #b3bdc6)'
  };

  dropdownWrapperStyle = {
    // width: '260px',
    // top:'42px',
    // borderColor: 'var(--default-gray-400, #b3bdc6)'
  }
  currentGroup: any = ""

  editNonAdminUser() {
    const payload:any = {
      groupName: this.selectedGroup.groupName,
      PrevGroup: this.currentGroup.groupName
    }
    
    this.authService.updateUser(this.selectedUser.userId, payload).subscribe({
        next: (request) => {
          this.router.navigate(['settings/user'], { state: {details: this.prevState, prevPage: "Edit-Non-Admin-User", success: true } });
  
        },
        error: (error) => {
          this.errorService.handleError(error, 'Error updating non-admin user')
        },
      })
  }

  retrievePlantsByCompanyId(selected: any, firstRender?: any) {
		this.selectedCompany = selected
    
		if (this.selectedPlant.plantId > 0 || this.plants.length > 0) {
      if (firstRender) {

      } else {
        this.resetPlant = true
        this.selectedPlant = {
          plantId: 0,
          plantName: "",
          isDeleted: false,
        }

        this.selectedGroup = {
          groupId: 0,
          groupName: "",
          plantId: 0,
          isDeleted: false
        }

        this.plants = []
      };
      
		}
    

		this.plantService
			.retrieveCompanyPlants(this.selectedCompany.companyId.toString())
			.subscribe({
				next: (response) => {
					this.plants = response.filter(item => !item.isDeleted);
				},
				error: (err) => {
					this.errorMessage = this.errorService.handleRetrievalErrors(err)
				},
			})
    this.resetPlant = false
	}


  resetFields() {

    this.selectedPlant = {
      plantId: 0,
      plantName: "",
      isDeleted: false,
    }
  

  
    if (this.currentRole === "Super Admin") {
      this.selectedCompany = {
        companyId: 0,
        companyName: "",
        isDeleted: false,
      }
    }    
  }

  retrieveUnitsByPlantId(selected: any) {
    // get modification window details
    this.selectedPlant = selected
    this.selectedGroup = {
      groupId: 0,
      groupName: "",
      plantId: 0,
      isDeleted: false
    }

    this.groupService.retrieveGroupPlant(this.selectedPlant.plantId)
        .subscribe({
            next: (response) => {
                this.groups = response
            },
            error: (err) => {
              this.errorMessage = this.errorService.handleRetrievalErrors(err)
            },
        })
	}

  selectedGroup: Group = {
    groupId: 0,
    groupName: "",
    plantId: 0,
    isDeleted: false
  }

  handleSelectGroup(item: any) {
    this.selectedGroup = item;
  }

  activateSubmitBtn() {
    if (this.selectedGroup.groupId === 0) {
      return false
    } else if (this.prevState.company.companyId === this.selectedCompany.companyId &&
              this.prevState.plant.plantId === this.selectedPlant.plantId &&
              this.prevState.group.groupId === this.selectedGroup.groupId
      ) {
      return false;
    }
    return true
  }

  prevState:any = {}
  pageDetails:string = "Add-Non-Admin-User"
  ngOnInit() {
    this.currentRole = localStorage.getItem('role');
	this.selectedCompany = history.state?.selectedValues?.company
	this.selectedPlant = history.state?.selectedValues?.plant
	this.selectedUser = history?.state?.data

	this.selectedGroup = history?.state?.data?.group[0]
	this.currentGroup = history?.state?.data?.group[0]

    if (this.currentRole === "Admin") {
      this.subtext = "Select Plant and Group below to edit."
    }

    this.prevState = {
      company: this.selectedCompany,
      plant: this.selectedPlant,
      group: this.selectedGroup

    }

    

    this.companyService.retrieveCompanies().subscribe({
			next: (companies) => {
				this.companies = companies.filter(item => !item.isDeleted)
        if (this.currentRole === "Admin") {
          this.plantService.retrieveAllPlants()
            .subscribe({
              next: (response) => {
                  this.plants = response
              },
              error: (err) => {
                this.errorMessage = this.errorService.handleRetrievalErrors(err)
              },
          })
        } else {
          this.retrievePlantsByCompanyId(this.selectedCompany, true)
        }
        
			},
			error: (response) => {
        this.errorMessage = this.errorService.handleRetrievalErrors(response)
			},
		})

    this.groupService.retrieveGroupPlant(this.selectedPlant.plantId)
        .subscribe({
            next: (response) => {
                this.groups = response
            },
            error: (err) => {
              this.errorMessage = this.errorService.handleRetrievalErrors(err)
            },
        })
  }
}
