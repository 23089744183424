/** @format */

import { Component } from "@angular/core"
import { Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { CompanyService } from "src/app/services/company/company.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: "app-company",
	templateUrl: "./company.component.html",
	styleUrls: ["./company.component.css"],
})
export class CompanyComponent {
	activeItem = 0
	currentRole: any = "";
	companies: Company[] = []
	showDeactivateMenu = false
	showMenu = false
	selectedCompanyId:any = "";
	
	//pagination data
	currentPage: number = 1
	data: Company[] = []
	pageSize: number = 7
	totalPages: number = 1
	loopArray: number[] = []
	
	deactivateText = {
		title: "Deactivate Company",
		message1: "",
		message2: "This action is irreversible. Do you wish to proceed?",
	}

	errorMessage = {
		success: true,
		message: ''
	}

	emptyStateData = {
		imgPath: "../../assets/company.svg",
		message1: "No companies to display.",
		message2: "Created companies will appear here.",
	}

	constructor(
		private companyService: CompanyService, 
		private router: Router, 
		public toastService: ToastService, 
		private errorService: ErrorHandlerService
	) {}

	get values() {
		return {
			data: this.data,
			currentPage: this.currentPage, 
			totalPages: this.totalPages, 
			loadData: this.loadData, 
			loopArray: this.loopArray 
		}
	}

	handleDeactivate() {
		this.companyService
		.deactivateCompany(this.selectedCompanyId)
		.subscribe({
			next: (response) => {
				this.companyService.retrieveCompanies().subscribe({
					next: (companies) => {
						this.companies = companies
						this.handleSuccessToast("Company-Deactivated")
					},
					error: (response) => {
						this.errorMessage = this.errorService.handleRetrievalErrors(response)
					},
				})
			},
			error: (error) => {
				this.errorService.handleError(error, 'Error deactivating company')
			},
		})
	}
	
	handleSuccessToast(state: any) {
		if (state === "Company-Deactivated") {
			this.toastService.toastText = "Company successfully deactivated!"
		}
		this.toastService.showToast = true
		setTimeout(() => {
			this.toastService.showToast = false
		}, 5000)
	}

	loadData() {
		if(this.currentRole === "Super Admin") {
			this.companyService.retrieveCompaniesPaginated(this.values.currentPage, this.pageSize).subscribe((result: any) => {
				this.companies = result.companies;
				this.data = result.companies
				this.totalPages = result.totalPages;
				this.updateLoopArray()
			});
		}
	}

	onPageChange(pageNumber: number) {
		this.currentPage = pageNumber;
		this.loadData();
	}

	showDeactivateModal(name: string, companyId: any) {
		this.selectedCompanyId = companyId
		this.deactivateText.message1 =
			"You are about to deactivate " +
			name +
			" in Lightyear. Doing this will deactivate all objects related to this company."
		this.showDeactivateMenu = true
	}

	toggleMenu(companyId: number) {
		this.activeItem = companyId
		this.showMenu = !this.showMenu
	}

	updateLoopArray() {
        this.loopArray = new Array(this.totalPages).fill(0).map((_, index) => index + 1);
    }

	ngOnInit(): void {
		this.currentRole = localStorage.getItem('role');
		if (this.currentRole === "Admin") {
			this.router.navigate(["/settings/units"])
		}
		this.loadData();
	}
}
