import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Audit } from 'src/app/models/audit.model';
import { DialogService } from 'src/app/services/modal/dialog.service';

@Component({
  selector: 'app-audit-modal',
  templateUrl: './audit-modal.component.html',
  styleUrls: ['./audit-modal.component.css']
})
export class AuditModalComponent {
  
  @Input() values:any= []
  constructor(public dialogService: DialogService, private datePipe: DatePipe
    ){}
  
  stringToDate( date: string) {
      const newDate = new Date(date);
      const customFormat = 'dd-MMM-yyyy h:mma';
      const formattedDate = this.datePipe.transform(newDate, customFormat);
      return formattedDate;
  }
  @Input() data: Audit = {
    eventId: 0,
    eventTime: '',
    eventDetails: '',
    performedBy: '',
    applicationSource: '',
    userType: '',
    performedOn: '',
    prevStateObj: {},
    currentStateObj: {},
    association: ''
  }
@Output() onCancel: EventEmitter<any> = new EventEmitter<any>()



	toggleModal() {
		this.onCancel.emit()
    
	}
}
