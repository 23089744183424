import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Audit, Change } from 'src/app/models/audit.model';
import { AuditService } from 'src/app/services/audit/audit.service';
import { DataPointService } from 'src/app/services/data-point/data-point.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';

@Component({
	selector: 'app-audit',
	templateUrl: './audit.component.html',
	styleUrls: ['./audit.component.css']
})
export class AuditComponent {
	showDetails = false
	auditDetails: any = {}
	audits: Audit[] = []
	currentPage: number = 1
	pageSize = 7
	totalPages = 10
	loopArray = new Array(this.pageSize).fill(0).map((_, index) => index + 1)
	currentIncrement = this.pageSize
	selectedPage = {
		backgroundColor: "#0F2642",
		color: "white",
		borderRadius: "4px",
	}
	errorMessage = {
		success: true,
		message: ''
	}

	constructor(
		private auditService: AuditService, 
		private datePipe: DatePipe, 
		private errorService: ErrorHandlerService,
		private datapointService: DataPointService
	) { }

	stringToDate(date: string) {
		const newDate = new Date(date);
		const customFormat = 'dd-MMM-yyyy h:mma';
		const formattedDate = this.datePipe.transform(newDate, customFormat);
		return formattedDate;
	}
	differences: any[] = [];

	changeName(name: string) {
		if (name.includes('dataPoints[')) {
			let index = name.lastIndexOf('.'); // Find the index of the last dot
			if (index !== -1) { // Check if a dot was found
				let result = name.substring(index + 1); // Extract the part after the dot
				if (result == 'dataPointDescription') return 'DataPoint Description';
				if (result == 'dataPointName') return 'DataPoint Name';
			}
		}
		if (name == 'dataPointName' || name == 'companyName' || name == 'plantName' || name == 'unitName' || name == 'logSheetName' || name == 'dataPointTitle') return 'Name';
		if (name == 'companyAbbreviation') return 'Abbreviation';
		if (name == 'dataPointDescription') return 'Description';
		if (name == 'currentValue') return 'Value';
		if (name == 'type') return 'Duration';
		if (name == 'unitOfMeasurement') return 'Unit of Measurement';
		if (name == 'dataType') return 'Type';
		if (name == 'dataPointMax') return 'Max';
		if (name == 'dataPointMin') return 'Min';
		if (name == 'firstName') return 'First Name';
		if (name == 'lastName') return 'Last Name';
		if (name == 'email') return 'Email';
		if (name == 'datapointName') return name

		else {
			return name;
		}
	}

	getDatapointName(id: number) {
		this
	}

	findChangedValues(previousObject: any, nextObject: any) {
		const differencesInValues: any[] = [];

		const previousKeys = this.getAllKeys(previousObject);
		const nextKeys = this.getAllKeys(nextObject);

		const similarKeys = previousKeys.filter(key => nextKeys.includes(key));
		const deletedKeys = previousKeys.filter(key => !nextKeys.includes(key));
		const addedKeys = nextKeys.filter(key => !previousKeys.includes(key));

		for (let key of deletedKeys) {
			const previousValue = this.readKey(previousObject, key);
			if (typeof previousValue === "object" || key.includes('Id') || key.includes('orderOnSheet') || previousValue == '') {
				continue;
			}
			differencesInValues.push({
				key: this.changeName(key),
				oldValue: previousValue,
				newValue: '-'
			});
		}

		for (let key of addedKeys) {
			if (!key.includes('value') || key.includes('dataPointName')) {
				continue
			}
			const nextValue = this.readKey(nextObject, key);
			if (typeof nextValue === "object" || key.includes('Id') || key.includes('orderOnSheet') || nextValue == '') {
				continue;
			}

			if(key.includes('valueDataPoint')) {
				differencesInValues.push({
					key: "DataPoint Name",
					oldValue: '-',
					newValue: nextValue
				});
				continue;
			}

			differencesInValues.push({
				key: key.includes('value') ? nextObject[key.replace(/\.value/g, "")]['dataPointName'] : this.changeName(key),
				oldValue: '-',
				newValue: nextValue
			});
		}

		for (let key of similarKeys) {
			const previousValue = this.readKey(previousObject, key);
			const nextValue = this.readKey(nextObject, key);
			if (typeof previousValue === "object" || typeof nextValue === "object" || key.includes('Id') || key.includes('orderOnSheet') || key.includes('dataPointName')) {
				continue;
			}
			if (previousValue !== nextValue) {
				differencesInValues.push({
					key: key.includes('value') ? previousObject[key.replace(/\.value/g, "")]['dataPointName'] : this.changeName(key),
					oldValue: previousValue,
					newValue: nextValue
				});
			}
		}

		this.differences = differencesInValues;

	}

	getAllKeys(obj: any, currentPath: string = ""): string[] {
		const keys: string[] = [];

		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				const newKey = currentPath ? `${currentPath}.${key}` : key;

				keys.push(newKey);

				if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
					keys.push(...this.getAllKeys(obj[key], newKey));
				} else if (Array.isArray(obj[key])) {
					obj[key].forEach((item: any, index: number) => {
						if (typeof item === "object") {
							const identifier = item.dataPointId !== undefined ? item.dataPointId : index;
							keys.push(...this.getAllKeys(item, `${newKey}[${identifier}]`));
						}
					});
				}
			}
		}

		return keys;
	}

	readKey(obj: any, keyStr: string): any {
		const keys = keyStr.split(".");
		let currentObj = obj;

		for (let key of keys) {
			if (key.includes("[")) {
				const identifier = parseInt(key.slice(key.indexOf("[") + 1, key.indexOf("]")), 10);
				const keyName = key.split("[")[0];

				const matchingObjs = currentObj[keyName].filter((item: any) => item.dataPointId === identifier);

				if (matchingObjs.length > 0) {
					currentObj = matchingObjs[0];
				} else {
					throw new Error(`Key ${keyName}[dataPointId=${identifier}] not found`);
				}

			} else {
				currentObj = currentObj[key];
			}
		}

		return currentObj;
	}

	retrieveAudits(reset?: any) {
		if (reset !== true) {
			this.currentPage = 1
		}
		const pageNumber = this.currentPage
		const pageSize = this.pageSize

		const item = {
			pageSize,
			pageNumber
		}
		this.auditService.retrieveAuditByPage(item).subscribe({
			next: (response: any) => {
				this.audits = response.auditLogs
				this.totalPages = response.totalPages
				this.loopArray = new Array(this.totalPages)
					.fill(0)
					.map((_, index) => index + 1)
			},
			error: (response) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(response)
			},
		})

	}
	ngOnInit(): void {
		this.retrieveAudits();
	}
	incrementArray(arr: any, increment: any) {
		return arr.map((item: any) => item + increment)
	}

	decrementArray(arr: any, decrement: any) {
		return arr.map((item: any) => item - decrement)
	}

	shiftPageRight() {
		const incrementedArray = this.incrementArray(
			this.loopArray,
			this.currentIncrement,
		)
		this.loopArray = incrementedArray
	}
	shiftPageLeft() {
		const decrementArray = this.decrementArray(
			this.loopArray,
			this.currentIncrement,
		)
		this.loopArray = decrementArray
	}
	setCurrentPage(page: any) {
		this.currentPage = page
		this.retrieveAudits(true)
	}
	performIncrement() {
		if (this.currentPage === this.totalPages) return
		const result = this.currentPage + 1
		this.setCurrentPage(result)
		if (this.currentPage > this.loopArray[this.loopArray.length - 1]) {
			this.shiftPageRight()
		}
	}

	performDecrement() {
		if (this.currentPage === 1) return
		const result = this.currentPage - 1
		this.setCurrentPage(result)
		if (this.currentPage < this.loopArray[0]) {
			this.shiftPageLeft()
		}
	}

}
