/** @format */

import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { DataPoint } from "src/app/models/datapoint.model"
import { Plant } from "src/app/models/plant.model"
import { Unit } from "src/app/models/unit.model"
import { CompanyService } from "src/app/services/company/company.service"
import { DataPointService } from "src/app/services/data-point/data-point.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { DialogService } from "src/app/services/modal/dialog.service"
import { PlantService } from "src/app/services/plant/plant.service"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: "app-edit-data-point",
	templateUrl: "./edit-data-point.component.html",
	styleUrls: ["./edit-data-point.component.css"],
})
export class EditDataPointComponent implements OnInit {
	selectedValues = {
		company: {
			companyId: 0,
			companyName: "",
		},
		plant: {
			plantId: 0,
			plantName: "",
		},
		unit: {
			unitId: 0,
			unitName: "",
		},
	}

	errorMessage: any = {
		success: true,
		message: "",
	}

	dataPointNameUpdate = ""
	dataPointMinUpdate = 0
	dataPointMaxUpdate = 0
	dataPointDescUpdate = ''
	dataPointType = ''
	dataPointUnitOfMeasurement = ''
	currentRole: any = "";

    editDataPointRequest = {
        dataPointId: 0,
        dataPointName: '',
        dataType: '',
        unitOfMeasurement: '',
        dataPointMin: 0,
        dataPointMax: 0,
        dataPointDescription: '',
        isDeleted: false,
        status: 'active',
        unitId: 0,
    }

	companies: Company[] = []
	plants: Plant[] = []
	units: Unit[] = []
	dataPoints: DataPoint[] = []
	retrievedCompany: any
	firstRender: boolean = false
	showModal:boolean = false

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private plantService: PlantService,
		private dataPointService: DataPointService,
		private companyService: CompanyService,
		public toastService: ToastService,
		public dialogService: DialogService,
		private errorService: ErrorHandlerService
	) {	}

	closeModal() {
		this.showModal = false
	}

	ngOnInit(): void {
		this.selectedValues = history.state
		this.currentRole = localStorage.getItem('role');
		if (this.currentRole != 'Admin') {
			this.companyService.retrieveCompanies().subscribe({
				next: (companies) => {
					this.companies = companies
				},
				error: (response) => {
					this.errorMessage = this.errorService.handleRetrievalErrors(response)
				},
			})
		}

		this.route.paramMap.subscribe({
			next: (params) => {
				const id = params.get("id")

				if (id) {
					this.dataPointService.retrieveDataPointById(id).subscribe({
						next: (response) => {
							this.editDataPointRequest = response
							this.dataPointNameUpdate = response?.dataPointName,
							this.dataPointMinUpdate = response?.dataPointMin
							this.dataPointMaxUpdate = response?.dataPointMax
							this.dataPointDescUpdate = response?.dataPointDescription
							this.dataPointUnitOfMeasurement = response?.unitOfMeasurement
							this.dataPointType = response?.dataType
						},
						error: (err) => {
							this.errorMessage = this.errorService.handleRetrievalErrors(err)
						},
					})
				}
			},
		})

		// this.selectedCompany = history.state
	}

	activateSubmitBtn() {
		if (!this.changeWasMade()) {
			return false
		} else
        if (this.editDataPointRequest.dataPointName.length > 0 && 
			this.editDataPointRequest.dataType === 'Number' && 
			this.editDataPointRequest.dataPointMin >= 0 && 
			this.editDataPointRequest.dataPointMax > 0) {
			return true
		} else if (this.editDataPointRequest.dataPointName.length > 0 &&
			this.editDataPointRequest.dataType != 'Number') {
			return true
		} 
		return true
    }

	changeWasMade() {
		if (this.dataPointType === 'Number' && 
		this.editDataPointRequest.dataPointName === this.dataPointNameUpdate && 
		this.editDataPointRequest.dataPointDescription === this.dataPointDescUpdate &&
		this.editDataPointRequest.unitOfMeasurement === this.dataPointUnitOfMeasurement &&
		this.editDataPointRequest.dataPointMin == this.dataPointMinUpdate &&
		this.editDataPointRequest.dataPointMax == this.dataPointMaxUpdate) {
			return false
		}
		else if (this.dataPointType != 'Number' &&
		this.editDataPointRequest.dataPointName === this.dataPointNameUpdate && 
		this.editDataPointRequest.dataPointDescription === this.dataPointDescUpdate
		) {
			return false
		}
		return true
	}


	transferData() {
		this.firstRender = true
		this.router.navigateByUrl("settings/data-points", {
			state: {
				selectedValues: this.selectedValues,
				firstRender: this.firstRender,
			},
		})
	}

	editDataPoint() {
		this.editDataPointRequest.unitId = this.selectedValues.unit.unitId
		this.dataPointService
		.editDataPoint(this.editDataPointRequest.dataPointId, this.editDataPointRequest)
		.subscribe({
			next: (request) => {
				this.transferData()
				this.toastService.showToast = true
				this.toastService.toastText = 'Data Point successfully updated!'
				setTimeout(() => {
					this.toastService.showToast = false
				}, 5000)
			},
			error: (error) => {
				this.errorService.handleError(error, 'Error updating datapoint')
			},
		})
	}
}
