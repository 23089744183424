import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/company.model';
import { Plant } from 'src/app/models/plant.model';
import { Unit } from 'src/app/models/unit.model';
import { CompanyService } from 'src/app/services/company/company.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';
import { DialogService } from 'src/app/services/modal/dialog.service';
import { PlantService } from 'src/app/services/plant/plant.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UnitService } from 'src/app/services/unit/unit.service';

@Component({
  selector: 'app-edit-unit',
  templateUrl: './edit-unit.component.html',
  styleUrls: ['./edit-unit.component.css']
})
export class EditUnitComponent {
  errorMessage: any = {
    success: true,
    message: ''
  }

  editUnitRequest: Unit = {
    unitId: 0,
    plant: [],
    unitName: '',
    isDeleted: false
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private plantService: PlantService,
    private companyService: CompanyService,
    private unitService: UnitService,
    public toastService: ToastService,
    public dialogService: DialogService,
    private errorService: ErrorHandlerService
  ) { }

  selectedValues = {
		company: {
			companyId: 0,
			companyName: "",
			isDeleted: false
		},
		plant: {
			plantId: 0,
			plantName: "",
			isDeleted: false
		},
	}
  unitNameUpdate = '';
  companies: Company[] = [];
  plants: Plant[] = [];
  showModal = false

  closeModal() {
    this.showModal = false
  }
  ngOnInit() {
    this.selectedValues = {
      company: { ...history.state.company },
      plant: { ...history.state.plant },
    }
    this.route.paramMap.subscribe({
      next: (params) => {
        const id = params.get('id')
        if (id) {
          this.unitService.retrieveUnit(id).subscribe({
            next: (response) => {
              this.editUnitRequest = response
              this.unitNameUpdate = response.unitName
              this.setPlant(response);
            },
            error: (response) => {
              this.errorMessage = this.errorService.handleRetrievalErrors(response)
            }
          })
        }
      },
    })
  }

  setPlant(item: any) {
    this.selectedValues.plant = {
      plantId: item.plantId,
      plantName: item.plantName,
      isDeleted: item.plantIsDeleted
    };
  }

  setCompany(item: any) {
    this.selectedValues.company = {
      companyId: item.companyId,
      companyName: item.companyName,
      isDeleted: item.isDeleted
    };

    this.plantService
      .retrieveCompanyPlants(item.id.toString())
      .subscribe({
        next: (response) => {
          this.plants = response
        },
        error: (err) => {
          this.errorMessage = this.errorService.handleRetrievalErrors(err)
        },
      })
  }

  transferData(isSuccess?: boolean) {
		this.router.navigateByUrl("settings/units", {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: true,
        prevPage: isSuccess ? 'Edit_Unit' : '',
			},
		})
	}

  editUnit() {
    this.unitService
      .editUnit(this.editUnitRequest.unitId, this.editUnitRequest)
      .subscribe({
        next: (response) => {
          this.transferData(true)
          this.toastService.showToast = true
          setTimeout(() => {
            this.toastService.showToast = false
          }, 5000)
        },
        error: (error) => {
          this.errorService.handleError(error, 'Error updating unit')
        },
      })

  }
}
