<!-- @format -->

<div class="card custom-section">
	<h1 class="section-title">Edit a plant</h1>
	<p class="subtext">Edit company details using the form below.</p>
	<form
		#form="ngForm"
		style="margin-top: 2rem"
		(ngSubmit)="updatePlantModal = true">
		<div style="margin-bottom: 24px">
			<label
				for=""
				class="custom-label required"
				>Company Name</label
			>
			<app-dropdown
				[disabled]="true"
				selectedItem="{{ selectedCompany }}"
				[fields]="['companyId', 'companyName']"
				label="{{ selectedCompany.companyName }}"></app-dropdown>
		</div>
		<label
			for="plant_name"
			class="custom-label required"
			>Plant Name</label
		>
		<input
			type="text"
			class="custom-input"
			name="plant_name"
			id="plant_name"
			[(ngModel)]="editPlantRequest.plantName" />
		<div
			*ngIf="!errorMessage.success"
			class="error">
			<img
				src="../../assets/alert.svg"
				alt="" />
			<p>
				{{ errorMessage.message }}
			</p>
		</div>
		<input
			type="hidden"
			name="company_is_deleted"
			id="company_is_deleted"
			[(ngModel)]="editPlantRequest.isDeleted" />
		<div class="btn-group">
			<button
				(click)="transferData()"
				class="btn-outline"
				routerLink="../..">
				Cancel
			</button>
			<button
				type="submit"
				class="btn-fill"
				[ngClass]="
					plantNameUpdate === editPlantRequest.plantName
						? 'disabled-btn'
						: ''
				">
				Update Plant
			</button>
		</div>
	</form>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-save-modal
	*ngIf="updatePlantModal"
	(closeModal)="closeUpdatePlantModal()"
	(onSave)="editPlant()"></app-save-modal>
