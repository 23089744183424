<!-- @format -->

<app-save-modal (onSave)="handleClose()" *ngIf="isExpired"  style="left: 0;" [showCancelButton]="false" [actionButtonTitle]="'Close'" [subTitle]="'The modification window for this log sheet has passed and therefore cannot be edited. Please contact a system administrator'" [title]="'Modification Window Expired'">
</app-save-modal>

<div class="logs-container">
	
	<div class="heading">
		<h2 class="heading">{{ logSheetTemplateName }}</h2>
		<div class="schedule-container">
			<span class="date-error">{{ filterErrorMessage }}</span>
			<div class="label tool-tip">
				<button
					type="button"
					(click)="toggleOverlay()">
					ⓘ
				</button>
				<div
					class="overlay"
					*ngIf="displayOverlay">
						Logsheet Cards will be filtered by scheduled time
				</div>
			</div>
			<div class="date-container">
				<!-- <div
					class="overlay"
					*ngIf="displayOverlay">
					<div class="overlay-header">
						Logsheet Cards will be filtered by scheduled time
					</div>
				</div> -->
				<div class="date-picker">
					<input
						type="date"
						name="startDate"
						(change)="setStartDate()"
						[(ngModel)]="startDate"
						value="startDateFormatted"
						placeholder="Start Date" />
					<span>to</span>
					<input
						type="date"
						name="endDate"
						[min]="startDate"
						(change)="setEndDate()"
						[(ngModel)]="endDate"
						value="endDateFormatted"
						placeholder="End Date" />
				</div>
			</div>
		</div>
	</div>
	<div class="log-container">
		<div
			class="log-item"
			[ngClass]="{
				'disable-bg': log.status == 'inProgress' && log.logSheetTemplated.scheduleType.toLowerCase() !== 'ondemand',
				'reposition-container': !log.executionTime
			}"
			*ngFor="let log of filteredLogs ? filteredLogs : logs">
			<div class="log-heading">
				<a
					(click)="checkLogsheet(log)"
					class="log-name"
					[ngClass]="(
						log.status === 'inProgress' 
						&& log.currentUser !== currentUser 
						&& role !== 'Super Admin' 
						&& log.logSheetTemplated.scheduleType.toLowerCase() !== 'ondemand'
						) ? 'logsheet-disabled' : ''"
				>
					{{ log.versionNumber > 0 ? log.logSheetTemplated.logSheetName + ' V' + log.versionNumber : log.logSheetTemplated.logSheetName }}
				</a>
				<p class="schedule-type" [ngClass]="getStatusColor(log.status)">{{ capitalize(convertToTitleCase(log.status)) }}</p>
			</div>
			<div class="log-info" [ngClass]="!log.executionTime ? 'hide-field' : '' ">
				<p *ngIf="log.currentUser" class="current-user">
					<img src="../../../assets/user-log-icon.svg" alt="user-icon" />
					{{ log.currentUser }}
				</p>
				<p>
					<img
						src="../../../assets/calendar.svg"
						alt="calendar" />{{
						stringToDate(log.executionTime) ||
							stringToDate(log.lastModifiedDate)
					}}
				</p>
				<p>
					<img
						src="../../../assets/clock.svg"
						alt="clock" />{{
						stringToTime(log.executionTime) ||
							stringToTime(log.lastModifiedDate)
					}}
				</p>
			</div>
			<div class="log-info" [ngClass]="!log.isGeneratedOnDemandLogSheet ? 'hide-field' : '' ">
				<p *ngIf="log.currentUser" class="current-user">
					<img src="../../../assets/user-log-icon.svg" alt="user-icon" />
					{{ log.currentUser }}
				</p>
				<p>
					<img
						src="../../../assets/calendar.svg"
						alt="calendar" />{{
							stringToDate(log.lastModifiedDate)
					}}
				</p>
				<p>
					<img
						src="../../../assets/clock.svg"
						alt="clock" />{{
							stringToTime(log.lastModifiedDate)
					}}
				</p>
			</div>
			<div class="log-edits">
				<div class="edits-container">
					<div>
						<span class="total-edits"
							>{{
								log.adminEditCount + log.nonAdminEditCount || 0
							}}
							edits</span
						>
						<span>{{ log.adminEditCount || 0 }} Admin</span>
						<span>{{ log.nonAdminEditCount || 0 }} Non-Admin</span>
					</div>

					<button 
						type="button"
						(click)="resetLogsheetStatus(log)"
						class="reset-status"
						*ngIf="log.status == 'inProgress' && (log.currentUser === currentUser || role === 'Super Admin')"
					>
						<img
						src="../../../assets/reset-white.svg"
						alt="reset" />
						Reset
					</button>
				</div>
			</div>
		</div>
	</div>
	<div
		class="pagination"
		*ngIf="logs.length !== 0">
		<button
			[style.opacity]="currentPage === 1 ? 0.5 : 1"
			type="button"
			(click)="performDecrement()">
			<img
				class="arrow flipped"
				src="../../assets/triangle-icon.svg"
				alt="navigate back" />
		</button>
		<div *ngFor="let item of loopArray; let i = index">
			<button
				*ngIf="
					item <= totalPages &&
					currentPage > 2 &&
					currentPage < totalPages - 1
						? item >= currentPage - 2 && item <= currentPage + 2
						: 'null' && currentPage === 1
						? item >= currentPage && item <= currentPage + 4
						: 'null' && currentPage === 2
						? item >= currentPage - 1 && item <= currentPage + 3
						: 'null' && currentPage === totalPages - 1
						? item >= currentPage - 3 && item <= currentPage + 1
						: 'null' && currentPage === totalPages
						? item >= currentPage - 4 && item <= currentPage
						: 'null'
				"
				[style]="currentPage === item ? selectedPage : ''"
				(click)="setCurrentPage(item)"
				class="page-number">
				{{ item }}
			</button>
		</div>
		<button
			[style.opacity]="currentPage === totalPages ? 0.5 : 1"
			type="button"
			(click)="performIncrement()">
			<img
				class="arrow"
				src="../../assets/triangle-icon.svg"
				alt="navigate forward" />
		</button>
	</div>

	<div
		class="center"
		*ngIf="logs.length === 0 && !filteredLogs">
		<app-empty-state [data]="emptyStateLogs" />
	</div>
	<div
		class="center"
		*ngIf="filteredLogs && filteredLogs.length === 0">
		<app-empty-state [data]="emptyFilteredLogs" />
	</div>
</div>
<app-success-toast *ngIf="this.toastService.showToast"></app-success-toast>