import { Component } from '@angular/core';

@Component({
  selector: 'app-account-complete',
  templateUrl: './account-complete.component.html',
  styleUrls: ['./account-complete.component.css']
})
export class AccountCompleteComponent {
  constructor() {}
  isPasswordUpdate: boolean = false
  heading = "Account Creation Complete"

  ngOnInit() {
    this.isPasswordUpdate = history.state.passwordUpdate
    this.heading = this.isPasswordUpdate ? "Password Update Complete" : this.heading
  }
}
