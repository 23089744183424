import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { Logsheet } from "src/app/models/logsheet.model"
import { Plant } from "src/app/models/plant.model"
import { Unit } from "src/app/models/unit.model"
import { CompanyService } from "src/app/services/company/company.service"
import { DataPointService } from "src/app/services/data-point/data-point.service"
import { LogsheetService } from "src/app/services/logsheet/logsheet.service"
import { PlantService } from "src/app/services/plant/plant.service"
import { ToastService } from "src/app/services/toast/toast.service"
import { UnitService } from "src/app/services/unit/unit.service"
import { OverviewService } from 'src/app/services/overview/overview.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent {
	plantDetails: Plant = {
		plantId: 0,
		plantName: "",
		isDeleted: false,
	}
	showDeactivateMenu = false
	showMenu = false
	showOverview = false;
	activeItem = 0
	$: any
	toggleMenu(plantId: number) {
		this.activeItem = plantId
		this.showMenu = !this.showMenu
	}
	toggleOverview() {
		this.overviewService.toggleOverview();
	}
	selectedLogsheet: number = 0;
	companies: Company[] = []
	plants: Plant[] = []
	units: Unit[] = []
	logsheets: Logsheet[] = []
	currentRole: any = ""
	assignedPlants: any = []
	firstRender: boolean = history?.state?.firstRender ?? false
	condition: boolean = this.currentRole !== 'Super Admin' || this.currentRole !== '' ? true : false;
	plantId = 0
	currentPage: number = 1
	pageSize = 9
	totalPages = 10
	loopArray = new Array(this.pageSize).fill(0).map((_, index) => index + 1)
	currentIncrement = this.pageSize
	selectedPage = {
		backgroundColor: "#0F2642",
		color: "white",
		borderRadius: "4px",
	}
	selectedValues = history?.state?.selectedValues ?? {
		company: {
			companyId: 0,
			companyName: "",
			isDeleted: false,
		},
		plant: {
			plantId: 0,
			plantName: "",
			isDeleted: false,
		}
	}
	errorMessage = {
		success: true,
		message: ''
	}

	constructor(
		private route: ActivatedRoute,
		private plantService: PlantService,
		private companyService: CompanyService,
		private dataPointService: DataPointService,
		private unitService: UnitService,
		private router: Router,
		public toastService: ToastService,
		public logsheetService: LogsheetService,
		public overviewService: OverviewService,
		private errorService: ErrorHandlerService
	) { }

	retrieveLogsheetsByUnitId(unit: any) {
		for (let i = 0; i < unit.length; i++) {
			this.logsheetService
				.retrieveLogsheetByUnit(unit[i].unitId)
				.subscribe({
					next: (response) => {
						unit[i].logsheets = response;
						unit[i].logsheets = unit[i].logsheets.filter((f: any)=> f.displayStatus != 'deactivated')

					},
					error: (err) => {
						this.errorMessage = this.errorService.handleRetrievalErrors(err)
					},
				})
		}

	}

	retrieveUnitsByPlantId(selected: any) {
		if (!this.firstRender) {
			this.selectedValues = {
				...this.selectedValues,
				plant: selected,
			}

			this.selectedValues.unit = {
				unitId: 0,
				unitName: "",
				isDeleted: false,
			}
			this.logsheets = []
		}
		this.retrieveUnits(selected?.plantId)
	}

	showPlantEmptyState() {
		if (
			this.currentRole === "Super Admin" &&
			this.selectedValues?.company?.companyId > 0 &&
			(this.plants?.length === 0 ||
				this.selectedValues?.plant?.plantId === 0) &&
			this.selectedValues?.unit?.unitId === 0
		) {
			return true
		} else if (
			this.currentRole === "Admin" &&
			(this.plants?.length === 0 ||
				this.selectedValues?.plant?.plantId === 0) &&
			this.selectedValues?.unit?.unitId === 0
		) {
			return true
		} else {
			return false
		}
	}

	retrievePlantsByCompanyId(selected: any) {
		if (!this.firstRender) {
			this.selectedValues = {
				...this.selectedValues,
				company: selected,
			}

			this.selectedValues.plant = {
				plantId: 0,
				plantName: "",
				isDeleted: false,
			}
			this.selectedValues.unit = {
				unitId: 0,
				unitName: "",
				isDeleted: false,
			}
			this.units = []
		}
		this.plantService
			.retrieveCompanyPlants(
				this.selectedValues?.company?.companyId.toString(),
			)
			.subscribe({
				next: (response) => {
					this.plants = response
				},
				error: (err) => {
					this.errorMessage = this.errorService.handleRetrievalErrors(err)
				},
			})

	}

	ngOnInit(): void {
		this.currentRole = localStorage.getItem("role")
		this.firstRender = history.state.firstRender
		this.showOverview = false
		try {
			const storedAssignment = localStorage.getItem("assignedPlants")
			if (storedAssignment) {
				this.assignedPlants = JSON.parse(storedAssignment)
			} 
			else {
				console.error("No data found in local storage for key 'assignedPlants' ");
			}
		} catch (error) {
			console.error("Error parsing JSON data from local storage:", error);
		}
		if (this.firstRender == true) {
			this.selectedValues = { ...history?.state?.selectedValues }
			this.retrievePlantsByCompanyId(this.selectedValues?.company)
			this.retrieveUnitsByPlantId(this.selectedValues?.plant)
			this.firstRender = false
		}

		if (this.currentRole === "Admin") {
			this.assignedPlants.map((assignment: Plant) => this.plants.push(assignment))
		} else {
			this.companyService.retrieveCompanies().subscribe({
				next: (companies) => {
					this.companies = companies
				},
				error: (response) => {
					this.errorMessage = this.errorService.handleRetrievalErrors(response)
				},
			})
		}
	}

	retrieveUnits(plantId: any, reset?: any) {
		if (reset !== true) {
			this.currentPage = 1
		}
		const pageNumber = this.currentPage
		const pageSize = this.pageSize

		const item = {
			plantId,
			pageSize,
			pageNumber
		}

		this.unitService.retrieveUnitPlantByPage(item).subscribe({
			next: (response: any) => {
				this.units = response?.item1
				this.retrieveLogsheetsByUnitId(this.units);
				this.totalPages = response?.item2
				this.loopArray = new Array(this.totalPages)
					.fill(0)
					.map((_, index) => index + 1)
			},
			error: (response) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(response)
			},
		})
	}

	incrementArray(arr: any, increment: any) {
		return arr.map((item: any) => item + increment)
	}

	decrementArray(arr: any, decrement: any) {
		return arr.map((item: any) => item - decrement)
	}

	shiftPageRight() {
		const incrementedArray = this.incrementArray(
			this.loopArray,
			this.currentIncrement,
		)
		this.loopArray = incrementedArray
	}
	shiftPageLeft() {
		const decrementArray = this.decrementArray(
			this.loopArray,
			this.currentIncrement,
		)
		this.loopArray = decrementArray
	}
	setCurrentPage(page: any) {
		this.currentPage = page
		this.retrieveUnits(this.selectedValues?.plant.plantId, true)
	}
	performIncrement() {
		if (this.currentPage === this.totalPages) return
		const result = this.currentPage + 1
		this.setCurrentPage(result)
		if (this.currentPage > this.loopArray[this.loopArray.length - 1]) {
			this.shiftPageRight()
		}
	}

	performDecrement() {
		if (this.currentPage === 1) return
		const result = this.currentPage - 1
		this.setCurrentPage(result)
		if (this.currentPage < this.loopArray[0]) {
			this.shiftPageLeft()
		}
	}


	emptyStateCompanies = {
		imgPath: "../../assets/company.svg",
		message1: "Select from the dropdown list(s) above to view associated Units",
		message2: "",
	}

	emptyStatePlants = {
		imgPath: "../../assets/plant.svg",
		message1: "Select from the dropdown list(s) above to view associated Units",
		message2: "",
	}

	emptyStateUnits = {
		imgPath: "../../assets/unit.svg",
		message1: "No units to display",
		message2: "",
	}
}
