<div class="account-complete-wrapper">
    <img src="../../../assets/rocket.svg" class="rocket-image">
    <div class="main-content">
        <img 
            src="../../../assets/logo.svg"
            class="logo"
        >
        <div class="sub-content">
            <h1>{{ heading }}</h1>
            <p>You can now access the DataLogger app on one of the designated tablets.</p>
            <p>Please contact a system administrator if additional help is needed.</p>
            <div class="content-ending">
                <span class="line"></span>
                <span class="line" style="grid-column-start: 4;"></span>
            </div>
        </div>
    </div>
</div>
