/** @format */

import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { Plant } from "src/app/models/plant.model"
import { User } from "src/app/models/user.model"
import { AuthService } from "src/app/services/auth/auth.service"
import { CompanyService } from "src/app/services/company/company.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { PlantService } from "src/app/services/plant/plant.service"
import { ToastService } from "src/app/services/toast/toast.service"
import { UserService } from "src/app/services/user/user.service"

import constants from "src/app/constants"

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.css'],
})
export class UserComponent {
	constructor(
		private route: ActivatedRoute,
		private plantService: PlantService,
		private companyService: CompanyService,
		private router: Router,
		public toastService: ToastService,
		private userService: UserService,
		private authService: AuthService,
		private errorService: ErrorHandlerService,
	) {}

	activeItem = 0
	searchInput = ''
	showMenu = false
	showAddMenu = false
	user: User[] = []
	assignedPlants: any = []
	errorMessage = {
		success: true,
		message: '',
	}

	currentPage: number = 1
	pageSize = 7
	totalPages = 10
	loopArray = new Array(this.pageSize).fill(0).map((_, index) => index + 1)

	currentIncrement = this.pageSize

	onSearchChange(search: any) {
		const plantId = this.selectedPlant.plantId
		const pageNumber = this.currentPage
		const pageSize = this.pageSize

		const item: any = {
			pageSize,
			pageNumber,
			search,
			plantId,
		}

		this.userService.retrieveUsers(item).subscribe({
			next: (response: any) => {
				this.user = response.item1
				this.totalPages = response.item2
				this.loopArray = new Array(this.totalPages)
					.fill(0)
					.map((_, index) => index + 1)
			},
			error: (response) => {
				this.errorMessage =
					this.errorService.handleRetrievalErrors(response)
			},
		})
	}
	incrementArray(arr: any, increment: any) {
		return arr.map((item: any) => item + increment)
	}

	decrementArray(arr: any, decrement: any) {
		return arr.map((item: any) => item - decrement)
	}

	performIncrement() {
		if (this.currentPage === this.totalPages) return
		const result = this.currentPage + 1
		this.setCurrentPage(result)
		if (this.currentPage > this.loopArray[this.loopArray.length - 1]) {
			this.shiftPageRight()
		}
	}

	performDecrement() {
		if (this.currentPage === 1) return
		const result = this.currentPage - 1
		this.setCurrentPage(result)
		if (this.currentPage < this.loopArray[0]) {
			this.shiftPageLeft()
		}
	}

	removeNumbersGreaterThan(arr: any, x: any) {
		return arr.filter((number: any) => number <= x)
	}

	shiftPageRight() {
		const incrementedArray = this.incrementArray(
			this.loopArray,
			this.currentIncrement,
		)
		this.loopArray = incrementedArray
	}

	shiftPageLeft() {
		const decrementArray = this.decrementArray(
			this.loopArray,
			this.currentIncrement,
		)
		this.loopArray = decrementArray
	}

	editUserByRole(userRole: any) {
		if (userRole == 'Operator') {
			return 'edit-non-admin-user'
		} else if (userRole == 'Admin') {
			return 'edit-admin-user'
		}
		return 'edit-super-user'
	}

	transferData(data: any, role: any) {
		let path = this.editUserByRole(role)
		
		this.router.navigateByUrl(`settings/user/${path}`, {
			state: {
				selectedValues: {
					company: this.selectedCompany,
					plant: this.selectedPlant,
				},
				data: data,
			},
		})
	}

	// selectedValues = {
	// 	company: {
	// 		companyId: 0,
	// 		companyName: "",
	// 		isDeleted: false,
	// 	},
	// 	plant: {
	// 		plantId: 0,
	// 		plantName: "",
	// 		isDeleted: false,
	// 	},
	// }

	selectedCompany = {
		companyId: 0,
		companyName: '',
		isDeleted: false,
	}
	selectedPlant = {
		plantId: 0,
		plantName: '',
		isDeleted: false,
	}

	plantDetails: Plant = {
		plantId: 0,
		plantName: '',
		isDeleted: false,
	}

	companies: Company[] = []
	plants: Plant[] = []
	currentRole: any = ''

	resetPlant = false
	firstRender = false

	selectedPage = {
		backgroundColor: '#0F2642',
		color: 'white',
		borderRadius: '4px',
	}

	toggleAdd() {
		this.showAddMenu = !this.showAddMenu
	}

	setCurrentPage(page: any) {
		this.currentPage = page
		this.retrieveUnitsByPlantId(this.selectedPlant, true)
		// call user endpoint with new page and set this.user
	}

	showPlantEmptyState() {
		if (
			this.currentRole === 'Super Admin' &&
			this.selectedCompany.companyId > 0 &&
			(this.plants.length === 0 || this.selectedPlant.plantId === 0)
		) {
			return true
		} else if (
			this.currentRole === 'Admin' &&
			(this.plants.length === 0 || this.selectedPlant.plantId === 0)
		) {
			return true
		} else {
			return false
		}
	}

	retrievePlantsByCompanyId(selected: any) {
		if (!this.firstRender) {
			this.selectedCompany = selected

			if (this.selectedPlant.plantId > 0 || this.plants.length > 0) {
				this.resetPlant = true
				this.selectedPlant = {
					plantId: 0,
					plantName: '',
					isDeleted: false,
				}

				this.user = []
				this.plants = []
			}
			if (
				this.selectedCompany.companyId !== 0 &&
				this.selectedPlant.plantId === 0
			) {
				this.user = []
			}
		}
		this.plantService
			.retrieveCompanyPlants(this.selectedCompany.companyId.toString())
			.subscribe({
				next: (response) => {
					this.plants = response
				},
				error: (err) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(err)
				},
			})
		this.resetPlant = false
	}

	specialStyle = {
		flex: 0.5,
	}

	defaultStyle = {
		flex: 1,
	}
	activateSubmitBtn() {
		if (this.currentRole === 'Super Admin') {
			if (
				this.selectedCompany.companyId === 0 ||
				this.selectedPlant.plantId === 0
			) {
				return false
			} else if (
				this.selectedCompany.isDeleted ||
				this.selectedPlant.isDeleted
			) {
				return false
			} else {
				return true
			}
		} else if (this.currentRole === 'Admin') {
			if (this.selectedPlant.plantId === 0) {
				return false
			} else if (this.selectedPlant.isDeleted) {
				return false
			} else {
				return true
			}
		}
		return true
	}

	closeAddButton() {
		this.showAddMenu = false
	}
	retrieveUnitsByPlantId(selected: any, reset?: any) {
		if (reset !== true) {
			this.currentPage = 1
		}

		this.selectedPlant = selected
		const plantId = this.selectedPlant.plantId
		const pageNumber = this.currentPage
		const pageSize = this.pageSize

		// here
		const item = {
			pageSize,
			pageNumber,
			plantId,
		}
		this.userService.retrieveUsers(item).subscribe({
			next: (response: any) => {
				this.user = response.item1
				this.totalPages = response.item2
				this.loopArray = new Array(this.totalPages)
					.fill(0)
					.map((_, index) => index + 1)
			},
			error: (response) => {
				this.errorMessage =
					this.errorService.handleRetrievalErrors(response)
			},
		})
	}

	adminColor = {
		backgroundColor: '#F49025',
	}

	superAdminColor = {
		backgroundColor: '#069A51',
	}

	nonAdminColor = {
		backgroundColor: '#102F53',
	}

	getRoleColor(role: any) {
		if (role === 'Admin') {
			return this.adminColor
		}

		if (role === 'Super Admin') {
			return this.superAdminColor
		}

		return this.nonAdminColor
	}

	showToast: boolean = false
	toastText: string = ''

	toggleMenu(plantId: number) {
		this.activeItem = plantId
		this.showMenu = !this.showMenu
	}

	handleSuccessToast(state: any) {
		if (state === constants.addAdminUser)
			this.toastService.toastText = 'Admin User successfully added'

		if (state === constants.addSuperUser)
			this.toastService.toastText = 'Super Admin User successfully added!'

		if (state === constants.addNonAdminUser /*"Add-Non-Admin-User"*/)
			this.toastService.toastText = 'Non Admin User successfully added!'

		if (state === constants.editAdminUser /*"Edit-Admin-User"*/)
			this.toastService.toastText = 'Admin User successfully updated!'

		if (state === constants.editNonAdminUser /*"Edit-Non-Admin-User"*/)
			this.toastService.toastText = 'Non-Admin User successfully updated!'

		if (state === constants.editSuperAdminUser)
			this.toastService.toastText =
				'Super Admin User successfully updated'

		if (state === constants.userDeactivated /*"User-Deactivated"*/) {
			this.toastService.toastText = 'User succesfully deactivated!'
		}
		this.toastService.showToast = state || false
		setTimeout(() => {
			this.toastService.showToast = false
		}, 5000)
	}

	pageDetail = ''
	ngOnInit(): void {
		this.currentRole = localStorage.getItem('role')

		this.pageDetail = history.state[2]
		this.firstRender = history.state.firstRender
		if (this.firstRender == true) {
			this.selectedCompany = {
				...history?.state?.selectedValues?.company,
			}
			this.selectedPlant = { ...history?.state?.selectedValues?.plant }
			this.retrievePlantsByCompanyId(this.selectedCompany)
			this.retrieveUnitsByPlantId(this.selectedPlant)
			this.firstRender = false
		}

		try {
			const storedAssignment = localStorage.getItem('assignedPlants')
			if (storedAssignment) {
				this.assignedPlants = JSON.parse(storedAssignment)
			} else {
				console.error(
					"No data found in local storage for key 'assignedPlants' ",
				)
			}
		} catch (error) {
			console.error('Error parsing JSON data from local storage:', error)
		}

		this.handleSuccessToast(history.state.prevPage)

		if (this.currentRole === 'Super Admin') {
			this.companyService.retrieveCompanies().subscribe({
				next: (companies) => {
					this.companies = companies
				},
				error: (response) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(response)
				},
			})
		}

		// cancel button
		if (this.pageDetail === 'Add-Non-Admin-User') {
			if (this.currentRole === 'Admin') {
				this.assignedPlants.map((assignment: Plant) =>
					this.plants.push(assignment),
				)
			} else {
				this.retrievePlantsByCompanyId(history.state[0])
			}

			this.retrieveUnitsByPlantId(history.state[1])
		}

		// update button
		if (history.state.details) {
			if (this.currentRole === 'Admin') {
				this.assignedPlants.map((assignment: Plant) =>
					this.plants.push(assignment),
				)
			} else {
				this.retrievePlantsByCompanyId(history.state.details.company)
			}

			this.retrieveUnitsByPlantId(history.state.details.plant)
			history.replaceState({ details: history.state.details }, '')
		} else {
			if (this.currentRole === 'Admin') {
				this.assignedPlants.map((assignment: Plant) =>
					this.plants.push(assignment),
				)
			}
		}

		// generate super admins on load
		const pageNumber = this.currentPage
		const pageSize = this.pageSize

		const item = {
			pageSize,
			pageNumber,
		}

		if (
			(this.selectedPlant.plantId === 0 &&
				this.selectedCompany.companyId === 0 &&
				this.currentRole === 'Super Admin') ||
			(this.selectedPlant.plantId === 0 && this.currentRole === 'Admin')
		) {
			this.userService.retrieveUsers(item).subscribe({
				next: (response: any) => {
					this.user = response.item1
					this.totalPages = response.item2
					this.loopArray = new Array(this.totalPages)
						.fill(0)
						.map((_, index) => index + 1)
				},
				error: (response) => {
					this.errorMessage =
						this.errorService.handleRetrievalErrors(response)
				},
			})
		}
	}

	selectedEmail: string = ''
	handleDeactivate() {
		this.authService.deactivateUser(this.selectedEmail).subscribe({
			next: (res) => {
				this.retrieveUnitsByPlantId(this.selectedPlant)
				this.handleSuccessToast('User-Deactivated')
			},
			error: (error) => {
				this.errorService.handleError(
					error,
					'Error deactivating this user',
				)
			},
		})
	}
	showDeactivateMenu = false
	showDeactivateModal(
		firstName: string,
		lastName: string,
		role: string,
		email: string,
	) {
		if (this.currentRole === 'Admin' && role === 'Super Admin') {
			return
		}
		this.showMenu = false
		this.selectedEmail = email
		this.deactivateText.message1 =
			'You are about to deactivate ' +
			role +
			' ' +
			firstName +
			' ' +
			lastName +
			' in Lightyear. This person will lose access to any part of the Lightyear they previously had access to.'
		this.deactivateText.title = 'Deactivate ' + firstName + ' ' + lastName
		this.showDeactivateMenu = true
	}

	emptyStateCompanies = {
		imgPath: '../../assets/user-icon.svg',
		message1: 'Select a company to see associated users listed here',
		message2: '',
	}

	emptyStatePlants = {
		imgPath: '../../assets/user-icon.svg',
		message1: 'Select a plant to see associated users listed here',
		message2: '',
	}

	emptyStateDataPoints = {
		imgPath: '../../assets/user-icon.svg',
		message1:
			'There are no Admins & Non-admins associated with this Company and Plant',
		message2: 'Created user will appear here.',
	}

	deactivateText = {
		title: '',
		message1: '',
		message2: 'This action is irreversible. Do you wish to proceed?',
	}
}
