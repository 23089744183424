<!-- @format -->

<div class="modal-overlay">
	<div class="modal-body">
		<span class="title"
			><img
				src="../../../assets/red-warning.svg"
				alt="icon" />{{ title }}</span
		>
		<div class="message">
			<p>{{ message1 }}</p>
			<br />
			<p>{{ message2 }}</p>
		</div>
		<div>
			<button
				class="btn-outline"
				(click)="toggleModal()"
				type="button">
				Cancel
			</button>
			<button
				(click)="action()"
				type="submit"
				class="btn-fill-yellow">
				{{ actionButtonTitle }}
			</button>
		</div>
	</div>
</div>
