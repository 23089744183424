/** @format */

import {
	Component,
	Input,
	Output,
	EventEmitter,
	ElementRef,
} from "@angular/core"

@Component({
	selector: "app-dropdown",
	templateUrl: "./dropdown.component.html",
	styleUrls: ["./dropdown.component.css"],
})
export class DropdownComponent {
	@Output() onSelectOption: EventEmitter<any> = new EventEmitter<any>()
	@Input() label?: any = ""
	@Input() options: any = []
	@Input() disabled: boolean = false
	@Input() fields: any = []
	@Input() inputStyle: any = {}
	@Input() inputWrapperStyle: any = {}
	@Input() form?: boolean = false
	@Input() mainColor?: string = "#102f53"
	@Input() isError: boolean = false;

	@Input() selectedItem: any = {
		[this.fields[0]]: 0,
		[this.fields[1]]: "",
		[this.fields[2]]: 0,
		isDeleted: false,
	}

	showOptions = false
	defaultLabel = this.label
	constructor(private _eref: ElementRef) {
		document.addEventListener("click", this.close.bind(this))
	}

	selectOption(
		id: number,
		option: string,
		disabled: boolean,
		status: boolean,
		days?: number
	): void {
		this.selectedItem = {
			[this.fields[0]]: id,
			[this.fields[1]]: option,
			isDeleted: disabled,
			status: status,
			[this.fields[2]]: days
		}
		this.label = option
		this.onSelectOption.emit(this.selectedItem)
	}

	toggleSelect() {
		this.showOptions = !this.showOptions
	}
	close(event: any) {
		if (
			!this._eref.nativeElement.contains(event.target) &&
			this.showOptions
		) {
			this.toggleSelect()
		}
	}

	get getCustomCss(): string {
		if (this.disabled) {
		  return 'disabled';
		} else if (this.isError) {
		  return 'error-field';
		} else if (this.form && !this.showOptions) {
		  return 'form-style';
		} else if (this.showOptions && this.form) {
		  return 'form-style enabled';
		} else if (this.showOptions) {
		  return 'active';
		}
		return '';
	  }

	get getOptionsCss(): string {
		if (this.form) {
			return "form-style"
		}

		return ""
	}
}
