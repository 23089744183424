import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
	showToast = false
	isError = false
	toastText: string = ''
	constructor() {}
}
