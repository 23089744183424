import { Component, Input } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  @Input() message: string = ""
  @Input() buttonTitle: string = ""
  @Input() action: Function = () => {}

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
