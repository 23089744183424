import { Injectable } from '@angular/core'
import { ToastService } from '../toast/toast.service'

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlerService {

	constructor(public toast: ToastService) { }

	handleError(error: any, defaultMessage: string = 'Error performing requsted action') {
		if (error.hasOwnProperty('error')) {
			if (error.error.hasOwnProperty('success')) {
				this.showErrorToast(error.error.message)
			} else if (typeof error.error === 'string') {
				this.showErrorToast(error.error)
			} else {
				this.showErrorToast(defaultMessage)
			}
		} else {
			this.showErrorToast(defaultMessage)
		}
	}

	private showErrorToast(message: string) {
		this.toast.isError = true
		this.toast.showToast = true
		this.toast.toastText = message

		setTimeout(() => {
			this.toast.showToast = false
			this.toast.isError = false
			this.toast.toastText = ''
		}, 5000)
	}

	handleRetrievalErrors(error: any): { success: boolean, message: string } {
		let message = 'Error retrieving data'
	  
		if (error.hasOwnProperty('success')) {
			message = error.message
		} else if (typeof error === 'string') {
			message = error
		}
	  
		return { success: false, message }
	  }
	  

}
