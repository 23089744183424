import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from 'src/app/services/auth/auth.service'
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css', '../reset-password/reset-password.component.css']
})
export class VerifyEmailComponent {
model: any = {
      success: true,
      message: '',
      attempts: 0,
      isLocked: false,
      status: 0
    }
    role: string|null = '';
    displayPopup: boolean = false;
    displayOverlay: boolean = false;

    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
      
      this.route.queryParams.subscribe(params => {
    const token = params['token'];
      this.model.token = token;
    });
    }

    handleLogin() {
      const role = localStorage.getItem('role');
      role !== 'Operator' ?
      this.router.navigate(['/auth/login']) :
      this.router.navigate(['/account-complete']) ;
    }

    toggleOverlay() {
      this.displayOverlay = !this.displayOverlay;
    }

    handleResetSubmit() {
      this.authService.handleVerifyEmail(this.model).subscribe({
        next: (res:any) => {
          this.role = localStorage.getItem('role');
          this.displayPopup = true;
        },
        error: (error) => {
          this.model.message = error?.error?.message;
          this.model.success = error?.error?.success;

        }
     })
    }
}
