/** @format */

import { Component, EventEmitter, Input, Output } from "@angular/core"

@Component({
	selector: "app-custom-switch",
	templateUrl: "./custom-switch.component.html",
	styleUrls: ["./custom-switch.component.css"],
})
export class CustomSwitchComponent {
	@Output() onToggle: EventEmitter<any> = new EventEmitter<any>()
	// @Input() label?: any = ""
	@Input() enabled? = false
	@Input() disabled? = false

	toggleSwitch(): void {
		this.enabled = !this.enabled
		this.onToggle.emit(this.enabled)
	}
}
