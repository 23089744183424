<!-- @format -->

<div class="custom-section card">
	<div class="section-header">
		<div>
			<h1 class="section-title">Log Sheets</h1>
			<span class="subtext"
				>Manage the list of log sheets for units within Lightyear.</span
			>
		</div>
		<div>
			<button
				type="button"
				[state]="selectedValues"
				[ngClass]="!activateAddLogSheetBtn() ? 'disabled-btn' : ''"
				[routerLink]="
					!activateAddLogSheetBtn() ? [] : ['add-logsheet']
				">
				Add a Sheet
			</button>
		</div>
	</div>

	<!-- DROPDOWNS -->
	<div class="dropdown-group">
		<app-dropdown
			*ngIf="currentRole === 'Super Admin'"
			[disabled]="false"
			[options]="companies"
			selectedItem="{{ selectedValues.company }}"
			(onSelectOption)="retrievePlantsByCompanyId($event)"
			[fields]="['companyId', 'companyName']"
			[label]="
				selectedValues.company.companyName.length === 0
					? 'Choose Company'
					: selectedValues.company.companyName
			"></app-dropdown>
		<app-dropdown
			[disabled]="
				currentRole === 'Super Admin' &&
				selectedValues.company.companyId === 0
					? true
					: false
			"
			[options]="plants"
			selectedItem="{{ selectedValues.plant }}"
			(onSelectOption)="retrieveUnitsByPlantId($event)"
			[fields]="['plantId', 'plantName']"
			[label]="
				selectedValues.plant.plantName === ''
					? 'Choose Plant'
					: selectedValues.plant.plantName
			">
		</app-dropdown>
		<app-dropdown
			[disabled]="selectedValues.plant.plantId === 0 ? true : false"
			[options]="units"
			selectedItem="{{ selectedValues.unit }}"
			(onSelectOption)="retrieveLogsheetsByUnitId($event)"
			[fields]="['unitId', 'unitName']"
			[label]="
				selectedValues?.plant?.plantId === 0 ||
				selectedValues?.unit?.unitName === ''
					? 'Choose Unit'
					: selectedValues?.unit?.unitName
			">
		</app-dropdown>
	</div>

	<table class="custom-table">
		<thead>
			<tr>
				<th>Log Sheets</th>
				<th>Configuration</th>
				<th>Status</th>
				<th>
					<img
						src="../../assets/dotted-menu.svg"
						alt="menu icon" />
				</th>
			</tr>
		</thead>
		<tbody *ngFor="let logsheet of logsheets">
			<tr>
				<td style="width: auto">
					<div>
						<div class="header-title">
							{{ logsheet.logSheetName }}
						</div>
						<div class="group-label">
							<img
								alt="group icon"
								class="group-item"
								src="../../assets/group.svg" />
							<span class="group-name interval"
								>{{ logsheet.group?.length }} Assigned
								{{
									logsheet.group?.length === 1
										? "Group"
										: "Groups"
								}}</span
							>
						</div>
					</div>
				</td>
				<td>
					<div>
						<div
							class="header-title"
							[ngStyle]="
								logsheet.scheduleType === 'On Demand'
									? { 'margin-bottom': 0 }
									: {}
							">
							{{
								logsheet.scheduleType === "onDemand"
									? "on demand"
									: logsheet.scheduleType
							}}
						</div>
						<div
							class="pill-container"
							*ngIf="logsheet.scheduleType === 'Scheduled'">
							<div class="interval-pill">
								<span class="interval">Interval</span>
								<span class="pill-value"
									>Every {{ logsheet.scheduleInterval }}
									{{ logsheet.scheduleIntervalUnit }}</span
								>
							</div>
							<div class="interval-pill">
								<span class="interval">Overdue Mark</span>
								<span class="pill-value"
									>{{
										logsheet.overdueScheduleInterval
									}}%</span
								>
							</div>
						</div>
					</div>
				</td>
				<td>
					{{
						logsheet.displayStatus?.toLowerCase() === "new"
							? logsheet.displayStatus
							: logsheet.isDeleted
							? "Inactive"
							: "Active"
					}}
				</td>
				<td>
					<img
						*ngIf="!logsheet.isDeleted"
						src="../../assets/dotted-menu.svg"
						alt="menu icon"
						(click)="toggleMenu(logsheet.logSheetTemplateId)"
						style="cursor: pointer" />
					<div
						[ngClass]="
							!showMenu ||
							logsheet.logSheetTemplateId != activeItem
								? 'd-none'
								: ''
						"
						class="custom-table__menu">
						<div>
							{{ logsheet?.logSheetName }}
						</div>

						<a
							

							(click)="
								transferData(
									'edit-logsheet',
									logsheet.logSheetTemplateId,
									logsheet.logSheetName
								)
							"
							
							>
							<img
								src="../../assets/edit-pencil.svg"
								alt="menu icon" />
							<span>Edit Log Sheet</span>
						</a>

						<a
							(click)="
								transferData(
									'schedule-logsheet',
									logsheet.logSheetTemplateId,
									logsheet.logSheetName
								)
							"
							class="custom-table__menu-option">
							<img
								src="../../assets/time.svg"
								alt="menu icon" />
							<span>Schedule</span>
						</a>

						<a
							(click)="
								transferData(
									'assign-logsheet',
									logsheet.logSheetTemplateId,
									logsheet.logSheetName
								)
							"
							class="custom-table__menu-option">
							<img
								src="../../assets/assign.svg"
								alt="menu icon" />
							<span>Assign</span>
						</a>
						<div class="underline"></div>
						<div
							(click)="
								showDeactivateModal(
									logsheet.logSheetName,
									logsheet.logSheetTemplateId
								);
								showMenu = false
							">
							<img
								src="../../assets/delete.svg"
								alt="menu icon" />
							<span>Deactivate</span>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="center">
		<app-empty-state [data]="emptyStateCompanies" *ngIf="
			selectedValues?.company?.companyName.length === 0 &&
			currentRole === 'Super Admin'
		"/>
	</div>
	<div class="center">
		<app-empty-state [data]="emptyStatePlants" *ngIf="showPlantEmptyState()"/>
	</div>
	<div class="center">
		<app-empty-state [data]="emptyStateUnits" *ngIf="
			selectedValues?.plant?.plantId > 0 &&
			(selectedValues?.unit?.unitId === 0 || units.length === 0)
		"/>
	</div>
	<div class="center">
		<app-empty-state [data]="emptyStateDataPoints" *ngIf="
			selectedValues?.unit?.unitName?.length > 0 &&
			selectedValues?.plant?.plantId > 0 &&
			logsheets.length === 0
		"/>
	</div>
	<app-pagination [values]="values" (pageChange)="onPageChange($event)" *ngIf="totalPages > 0"/>
</div>

<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-deactivate-modal
	(onCancel)="showDeactivateMenu = false"
	*ngIf="showDeactivateMenu"
	[title]="deactivateText.title"
	[message1]="deactivateText.message1"
	(onSave)="handleDeactivate()"
	[message2]="deactivateText.message2" />
