import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/modal/dialog.service';
import { OverviewService } from 'src/app/services/overview/overview.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
    currentRole: any = ""
    name: string = ""
    initial: string = ''

    constructor(public dialogService: DialogService,
      private router: Router,
      public overviewService: OverviewService
      ) {}

    isToggleOpen:boolean = false;

    toggleDropdown() {
      this.isToggleOpen = !this.isToggleOpen;
    }
    setShowLogout(status:any) {
      this.isToggleOpen = false;
      this.dialogService.showModal = true
    }

    handleLogout() {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      this.router.navigate(["auth/login"])
      this.overviewService.resetToHome()
    }
    ngOnInit(): void {
      this.currentRole = localStorage.getItem('role');
      this.name = `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`
      this.initial = this.name.split(" ").map((part => part.charAt(0))).join('')
    }
}
