import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModificationWindow } from 'src/app/models/modification-window.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ModificationWindowServiceService {

  baseUrl : string = environment.apiUrl
  constructor(private http: HttpClient) { }

  getByPlantId(id: number) : Observable<ModificationWindow> {
    return this.http.get<ModificationWindow>(this.baseUrl + '/modificationWindow/' + id)
  }

  updateModificationWindow(id: number, updateModRequest : any) : Observable<ModificationWindow> {
    return this.http.patch<ModificationWindow>(this.baseUrl + '/modificationWindow/' + id, updateModRequest)
  }

  addModificationWindow(item : any) : Observable<ModificationWindow> {
    return this.http.post<ModificationWindow>(this.baseUrl + '/modificationWindow', item)
  }
}
