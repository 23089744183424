// overview.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OverviewService {
    private showOverviewSubject = new BehaviorSubject<boolean>(false);
    showOverview$ = this.showOverviewSubject.asObservable();

    toggleOverview() {
        this.showOverviewSubject.next(!this.showOverviewSubject.value);
    }

    resetToHome() {
        this.showOverviewSubject.next(false);
    }
}
