<div class="modal-overlay">
    <div class="modal-body">
        <span class="heading">Details</span>
        <img (click)="toggleModal()" src="../../../assets/close.svg" alt=" icon" />
        <div class="info-container">
            <div>
                <div>Application Source</div>
                <div>{{data.applicationSource}}</div>
            </div>
            <div>
                <div>Performed By</div>
                <div>{{data.performedBy}}</div>
            </div>
            <div *ngIf="(!data.prevStateObj && !data.currentStateObj) || data.performedOn == ' ' || data.performedOn == ''">
                <div>Performed On</div>
                <div>{{data.performedOn}}</div>
            </div>
            <div *ngIf="data.userType != ''">
                <div>User Type</div>
                <div>{{data.userType}}</div>
            </div>
            <div>
                <div>Timestamp</div>
                <div>{{stringToDate(data.eventTime)}}</div>
            </div>
            <div>
                <div>Action</div>
                <div>{{data.eventDetails}}</div>
            </div>
            <div *ngIf="data.eventDetails === 'Logsheet Edited'">
                <div>Association</div>
                <div>{{data?.association ?? ''}}</div>
            </div>
            <div class= 'differences'*ngIf="data.prevStateObj && data.currentStateObj && values !== []">
                <div>Object Difference</div>
                <div [ngClass]="{'table':values.length > 6}"><table>
                    <thead>
                        <tr>
                        <th>Field</th>
                        <th>Old State</th>
                        <th>New State</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr class="table-row" *ngFor="let item of values" >
                            <td>{{item.key}}</td>
                            <td>{{item.oldValue}}</td>
                            <td>{{item.newValue}}</td>
                        </tr>
                    </tbody>
                    
                </table></div>
            </div>
        </div>
    </div>
</div>