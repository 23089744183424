/** @format */

import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { Company } from "src/app/models/company.model"
import { CompanyService } from "src/app/services/company/company.service"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: "app-add-company",
	templateUrl: "./add-company.component.html",
	styleUrls: ["./add-company.component.css"],
})
export class AddCompanyComponent implements OnInit {
	addCompanyRequest: Company = {
		companyId: 0,
		companyName: "",
		companyAbbreviation: "",
		isDeleted: false,
	}

	constructor(
		private companyService: CompanyService,
		private router: Router,
		public errorService: ErrorHandlerService,
		public toastService: ToastService
	) {}

	ngOnInit(): void {}

	addCompany() {
		this.companyService.addCompany(this.addCompanyRequest).subscribe({
			next: (request) => {
				this.router.navigate(["settings/companies"])

				this.toastService.toastText = "Company successfully added!"

				this.toastService.showToast = true
				setTimeout(() => {
					this.toastService.showToast = false
					
				}, 5000)
			},
			error: (error) => {
				this.errorService.handleError(error, 'Error creating company')
			},
		})
	}
}
