import { Component } from '@angular/core';

@Component({
  selector: 'app-unit-log-empty',
  templateUrl: './unit-log-empty.component.html',
  styleUrls: ['./unit-log-empty.component.css']
})
export class UnitLogEmptyComponent {

  
  emptyStateLogs = {
		imgPath: "../../assets/logsheet.svg",
		message1: "Select a Log Sheet to view executed and upcoming logs."  
	}
}
