<!-- @format -->

<div class="custom-section card add-unit">
	<div class="section-header custom-header">
		<div class="page-title">
			<h1 class="section-title">Modification Window</h1>
			<div class="list-item">1. {{ instruction }}</div>
			<div class="list-item">
				2. Enter the time frame within which Admin users can modify data
				after Non-Admin users enter it
			</div>
		</div>
	</div>
	<!-- DROPDOWNS -->
	<div class="dropdown-group-custom">
		<app-dropdown
			[inputStyle]="dropdownStyles"
			[inputWrapperStyle]="dropdownWrapperStyle"
			*ngIf="currentRole === 'Super Admin'"
			[label]="
				resetCompany
					? 'Choose Company'
					: selectedCompany.companyName || 'Choose Company'
			"
			[disabled]="false"
			[options]="companies"
			selectedItem="{{ selectedCompany }}"
			(onSelectOption)="retrievePlantsByCompanyId($event)"
			[fields]="['companyId', 'companyName']">
		</app-dropdown>
		<app-dropdown
			[inputStyle]="dropdownStyles"
			[inputWrapperStyle]="dropdownWrapperStyle"
			[disabled]="
				currentRole === 'Super Admin' && selectedCompany.companyId === 0
					? true
					: false
			"
			[options]="plants"
			selectedItem="{{ selectedPlant }}"
			(onSelectOption)="retrieveUnitsByPlantId($event)"
			[fields]="['plantId', 'plantName']"
			[label]="
				resetPlant || selectedPlant.plantId == 0
					? 'Choose Plant'
					: selectedPlant.plantName
			">
		</app-dropdown>
	</div>

	<div class="current-window">
		<span>Current window for Admin(s):</span>
		<span class="available-window">{{
			windowTitle.includes("null") ? "No Window Available" : windowTitle
		}}</span>
	</div>
	<div class="current-window">
		<span>Current window for Operator(s):</span>
		<span class="available-window">{{
			operatorTitle.includes("null")
				? "No Window Available"
				: operatorTitle
		}}</span>
	</div>

	<div class="dropdown-group custom-group-dropdown">
		<div>
			<label
				for="data_point_name"
				class="custom-label">
				Number of Time Units
			</label>
			<input
				(input)="validateInput($event.target)"
				[disabled]="
					(currentRole === 'Super Admin' &&
						(selectedCompany.isDeleted ||
							selectedCompany.companyId === 0)) ||
					selectedPlant.isDeleted ||
					selectedPlant.plantId === 0
				"
				[(ngModel)]="
					currentRole === 'Super Admin'
						? adminCurrentValue
						: operatorCurrentValue
				"
				type="text"
				[ngClass]="selectedPlant.plantId == 0 ? 'disabled-input' : ''"
				class="custom-input input-field"
				[name]="
					currentRole === 'Super Admin'
						? 'adminCurrentValue'
						: 'operatorCurrentValue'
				"
				[id]="
					currentRole === 'Super Admin'
						? 'adminCurrentValue'
						: 'operatorCurrentValue'
				"
				placeholder="00" />
		</div>

		<div>
			<label
				for="data_point_name"
				class="custom-label">
				Time Unit
			</label>

			<app-dropdown
				[label]="renderTimeUnitLabel()"
				[options]="timeUnits"
				[selectedItem]="
					currentRole === 'Super Admin'
						? selectedTimeUnit
						: selectedOperatorTimeUnit
				"
				[disabled]="
					(currentRole === 'Super Admin' &&
						(selectedCompany.isDeleted ||
							selectedCompany.companyId === 0)) ||
					selectedPlant.isDeleted ||
					selectedPlant.plantId === 0
				"
				(onSelectOption)="setTimeUnit($event)"
				[fields]="['id', 'name']"></app-dropdown>
		</div>
	</div>
	<div class="btn-group modification-btn-group">
		<button
			(click)="resetFields()"
			class="btn-outline">
			Cancel
		</button>

		<button
			[ngClass]="!activateChangeWindowBtn() ? 'disabled-btn' : ''"
			(click)="!activateChangeWindowBtn() ? null : handleModSubmit()"
			type="submit"
			class="btn-fill">
			Change Window
		</button>
	</div>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
