<!-- @format -->

<div class="card">
	<div class="card__info">
		<h4 [ngClass]="{ selected: isSelected }">
			{{ title }}
		</h4>
		<p *ngIf="description">{{ description }}</p>
	</div>
	<div class="card__checkbox">
		<img
			class="card__checkbox"
			src="../assets/{{ icon }}"
			alt="checkbox"
			(click)="selectIcon()" />
		<div
			cdkDragHandle
			*ngIf="isDraggable">
			<img
				src="../assets/dragger.svg"
				alt="drag icon" />
			<img
				src="../assets/dragger.svg"
				alt="drag icon" />
			<img
				src="../assets/dragger.svg"
				alt="drag icon" />
		</div>
	</div>
</div>
