import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from 'src/app/models/company.model';
import { Users } from 'src/app/models/users.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  baseUrl: string = environment.apiUrl
  constructor(private http: HttpClient) { }

  addCompany(addCompanyRequest : Company) : Observable<Company> {
    return this.http.post<Company>(this.baseUrl + '/company', addCompanyRequest)
  }

  retrieveCompanies() : Observable<Company[]>{
    return this.http.get<Company[]>(this.baseUrl + '/company');
  }

  retrieveCompaniesPaginated(currentPage: number, pageSize: number): Observable<Company> {
    return this.http.get<Company>(`${this.baseUrl}/company/${currentPage}/${pageSize}`)
  }

  retrieveCompany(id: string) : Observable<Company> {
    return this.http.get<Company>(this.baseUrl + '/company/' + id)
  }

  updateCompany(id: number, updateCompanyRequest : Company) : Observable<Company> {
    return this.http.patch<Company>(this.baseUrl + '/company/' + id, updateCompanyRequest)
  }

  addAdminUser(addAdminUserRequest: Users) : Observable<Users> {
    return this.http.post<Users>(this.baseUrl + '/auth/register', addAdminUserRequest)
  }

  editAdminUser(id: number, addAdminUserRequest: { ids: number[]; }) : Observable<Users> {
    return this.http.post<Users>(this.baseUrl + `/usergroup/group-to-admin-user/${id}`, addAdminUserRequest)
  }

  deactivateCompany(id: number) : Observable<Company> {
    return this.http.patch<Company>(this.baseUrl + '/company/deactivate/' + id, {})
  }
}
