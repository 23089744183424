<!-- @format -->

<div class="card custom-section">
	<div class="header">
		<div>
			<h1 class="section-title">Add an Admin User</h1>
			<p class="subtext">
				Fill out the User details using the form below.
			</p>
		</div>
		<button
			type="button"
			class="btn-text"
			(click)="transferData()">
			Cancel
		</button>
	</div>
	<!-- STEPPER -->
	<div class="custom-stepper">
		<div
			class="custom-stepper__step"
			(click)="setActiveStep(1)"
			[ngClass]="activeStep === 1 ? 'active' : ''">
			<span>STEP 1</span>
			<small>Details</small>
		</div>
		<div
			class="custom-stepper__step"
			(click)="enableNextBtn() ? setActiveStep(2) : null"
			[ngClass]="activeStep === 2 ? 'active' : ''">
			<span>STEP 2</span>
			<small>Plant Assignment</small>
		</div>
	</div>

	<!-- STEP 1 -->
	<div
		*ngIf="activeStep == 1"
		class="custom-stepper__step1">
		<div class="form-group col-2">
			<div *ngIf="currentRole == 'Super Admin'">
				<label
					for=""
					class="custom-label required"
					>Company Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues.company.companyName }}"
					label="{{
						selectedValues.company.companyName
					}}"></app-dropdown>
			</div>
			<div>
				<label
					for=""
					class="custom-label required"
					>Plant Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues.plant.plantName }}"
					label="{{ selectedValues.plant.plantName }}"></app-dropdown>
			</div>
			<div>
				<label
					for="first_name"
					class="custom-label required"
					>First Name</label
				>
				<input
					type="text"
					class="custom-input"
					name="first_name"
					id="first_name"
					minlength="2"
					maxlength="35"
					[(ngModel)]="addAdminRequest.firstName" />
			</div>
			<div>
				<label
					for="last_name"
					class="custom-label required"
					>Last Name</label
				>
				<input
					type="text"
					class="custom-input"
					name="last_name"
					id="last_name"
					minlength="2"
					maxlength="35"
					[(ngModel)]="addAdminRequest.lastName" />
			</div>
		</div>
		<div>
			<label
				for="email"
				class="custom-label required"
				>Email</label
			>
			<input
				type="email"
				class="custom-input"
				name="email"
				id="email"
				maxlength="320"
				[(ngModel)]="addAdminRequest.username"
				#username="ngModel"
				required
				pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
		</div>
	</div>

	<!-- STEP 2 -->
	<div
		*ngIf="activeStep == 2"
		class="custom-stepper__step2">
		<div class="custom-stepper__step2-container">
			<!-- SECTION 1 -->
			<div class="section1">
				<div class="mr-28">
					<h3>Available Plant Assignments</h3>
					<p class="instructions">
						Select checkbox to Plant to current Plant Assignments
					</p>
				</div>
				<div class="search-bar mr-28">
					<img
						src="../assets/search.svg"
						alt="search" />
					<input
						type="text"
						placeholder="Search"
						[(ngModel)]="searchText" />
				</div>
				<!-- PLANTS -->
				<div
					class="data-list"
					*ngIf="activePlants.length > 0">
					<app-checked-card
						*ngFor="
							let data of activePlants
								| search : searchText : 'plantName';
							let i = index
						"
						[attr.data-index]="i"
						[title]="data?.plantName"
						(onClickIcon)="
							isSelectedPlant(data) ? null : addPlant(data)
						"
						[isSelected]="isSelectedPlant(data)"
						[icon]="
							isSelectedPlant(data)
								? 'checkbox-selected.svg'
								: 'checkbox-unselected.svg'
						"></app-checked-card>
				</div>
			</div>
			<!-- SECTION 2 -->
			<div class="section2">
				<h3>Current Plant Assignments</h3>

				<ul>
					<li class="instructions">Click “X” to remove a plant</li>
				</ul>
				<!-- SELECTED PLANTS -->
				<div
					class="data-list"
					*ngIf="selectedPlants.length > 0">
					<app-checked-card
						*ngFor="let data of selectedPlants; let i = index"
						[attr.data-index]="i"
						[title]="data?.plantName"
						[isSelected]="true"
						(onClickIcon)="removePlant(i)"
						icon="checkbox-remove.svg"></app-checked-card>
				</div>
			</div>
		</div>
	</div>

	<div
		*ngIf="!errorMessage.success"
		class="error">
		<img
			src="../../assets/alert.svg"
			alt="" />
		<p>
			{{ errorMessage.message }}
		</p>
	</div>

	<div class="step__btn-group">
		<button
			type="button"
			class="btn-outline"
			(click)="activeStep == 1 ? transferData() : setActiveStep(1)">
			Back
		</button>
		<button
			[ngClass]="!enableNextBtn() ? 'disabled-btn' : ''"
			[disabled]="!enableNextBtn()"
			(click)="activeStep == 1 ? setActiveStep(2) : showSaveModal()"
			type="submit"
			class="btn-fill">
			{{ activeStep == 1 ? "Next" : "Add User" }}
		</button>
	</div>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-save-modal
	*ngIf="addAdmin"
	(closeModal)="closeModal()"
	(onSave)="addAdminUser()"></app-save-modal>
