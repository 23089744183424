<div *ngIf="(loader.isLoading$ | async)" class="spinner-overlay">
    <!-- <mat-spinner diameter="50"></mat-spinner> -->
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>