<!-- @format -->

<div class="unit-wrapper">
	<div class="unit-navigation">
		<img
			src="../../assets/back-arrow-icon.svg"
			alt="back navigation" />
		<div class="prev-page">User</div>
		<img
			src="../../assets/forward-arrow-icon.svg"
			alt="back navigation" />
		<div class="forward-page">Add User</div>
	</div>
	<div class="card add-unit">
		<h1 class="section-title">Add a Non Admin User</h1>
		<p class="subtext">Fill out the User details using the form below.</p>
		<form
			#form="ngForm"
			(ngSubmit)="activateSubmitBtn() ? addNonAdminUser() : null"
			style="margin-top: 2rem">
			<div class="form-container">
				<!--company and plant-->
				<div class="input-container">
					<div
						class="input-item"
						*ngIf="currentRole === 'Super Admin'">
						<label class="custom-label required"
							>Company Name</label
						>
						<app-dropdown
							[disabled]="true"
							[label]="selectedCompany.companyName">
						</app-dropdown>
					</div>
					<div
						[ngStyle]="
							currentRole === 'Admin'
								? specialStyle
								: defaultStyle
						"
						class="input-item">
						<label class="custom-label required">Plant Name</label>
						<app-dropdown
							[disabled]="true"
							[label]="selectedPlant.plantName">
						</app-dropdown>
					</div>
				</div>

				<!--first name and last name-->
				<div class="input-container">
					<div class="input-item">
						<label
							class="custom-label required"
							for="fname"
							>First Name</label
						>
						<input
							[(ngModel)]="firstName"
							type="text"
							class="custom-input"
							name="fname"
							id="fname" />
						<span
							*ngIf="showFirstNameError"
							class="unit-error">
							<img
								class="unit-icon"
								src="../../assets/error-icon.svg"
								alt="dropdown icon" />
							<div class="unit-error-text">
								{{ firstNameError }}
							</div>
						</span>
					</div>
					<div class="input-item">
						<label
							class="custom-label required"
							for="lname"
							>Last Name</label
						>
						<input
							[(ngModel)]="lastName"
							type="text"
							class="custom-input"
							name="lname"
							id="lname" />
						<span
							*ngIf="showLastNameError"
							class="unit-error">
							<img
								class="unit-icon"
								src="../../assets/error-icon.svg"
								alt="dropdown icon" />
							<div class="unit-error-text">
								{{ lastNameError }}
							</div>
						</span>
					</div>
				</div>

				<label
					class="custom-label required"
					for="email"
					>Email</label
				>
				<input
					[(ngModel)]="email"
					type="text"
					class="custom-input"
					name="email"
					id="email" />
				<span
					*ngIf="showEmailError"
					class="unit-error">
					<img
						class="unit-icon"
						src="../../assets/error-icon.svg"
						alt="dropdown icon" />
					<div class="unit-error-text">{{ emailError }}</div>
				</span>

				<label class="custom-label required">Group Name</label>
				<app-dropdown
					(onSelectOption)="handleSelectGroup($event)"
					[options]="groups"
					[fields]="['groupId', 'groupName']"
					label="Choose Group">
				</app-dropdown>
			</div>

			<div class="btn-group">
				<a
					routerLink=".."
					[state]="[selectedCompany, selectedPlant, pageDetails]"
					class="btn-outline">
					Cancel
				</a>
				<button
					class="btn-fill"
					[ngClass]="!activateSubmitBtn() ? 'disabled-btn' : ''"
					type="submit">
					Add User
				</button>
			</div>
			<div
				*ngIf="!errorMessage.success"
				class="error">
				<img
					src="../../assets/alert.svg"
					alt="" />
				<p>
					{{ errorMessage.message }}
				</p>
			</div>
		</form>
	</div>
</div>
