import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataPoint } from 'src/app/models/datapoint.model';
import { Unit } from 'src/app/models/unit.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataPointService {

  baseUrl: string = environment.apiUrl
  constructor(private http : HttpClient) { }

  retrieveDataPoint() : Observable<DataPoint[]> {
    return this.http.get<DataPoint[]>(this.baseUrl + '/datapoint')
  }

  retrieveDataPointById(id: string) : Observable<DataPoint> {
    return this.http.get<DataPoint>(this.baseUrl + '/dataPoint/' + id)
  }

  retrieveUnitByPlantId(id: string) : Observable<Unit[]> {
    return this.http.get<Unit[]>(this.baseUrl + '/unit/plant/' + id)
  }

  retrieveDataPointByUnitId(id: string, showDeleted: string = "false") : Observable<DataPoint[]> {
    return this.http.get<DataPoint[]>(this.baseUrl + '/datapoint/unit/' + id + `?showDeleted=${showDeleted}`)
  }

  retrieveDataPointByPlantId(id: string, showDeleted: string = "false") : Observable<DataPoint[]> {
    return this.http.get<DataPoint[]>(this.baseUrl + '/datapoint/plant/' + id + `?showDeleted=${showDeleted}`)
  }

  retrieveDataPointByUnitIdPaginated(id: string, pageNumber:number, pageSize:number, showDeleted: string = "false") : Observable<DataPoint[]> {
    return this.http.get<DataPoint[]>(`${this.baseUrl}/datapoint/unit/${id}/${pageNumber}/${pageSize}?showDeleted=${showDeleted}`)
  }

  retrieveActiveDataPoints() : Observable<DataPoint[]> {
    return this.http.get<DataPoint[]>(this.baseUrl + '/datapoint/active')
  }

  addDataPoint(addDataPointRequest : DataPoint) : Observable<DataPoint> {
    return this.http.post<DataPoint>(this.baseUrl + '/datapoint', addDataPointRequest)
  }

  editDataPoint(id: number, editDataPointRequest : DataPoint) : Observable<DataPoint> {
    return this.http.patch<DataPoint>(this.baseUrl + '/datapoint/' + id, editDataPointRequest)
  }

  deactivateDataPoint(id: any) : Observable<DataPoint> {
    return this.http.patch<DataPoint>(this.baseUrl + '/datapoint/deactivate/' + id, {})
  }
}
