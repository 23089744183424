/** @format */

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/company.model';
import { Plant } from 'src/app/models/plant.model';
import { CompanyService } from 'src/app/services/company/company.service';
import { PlantService } from 'src/app/services/plant/plant.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ModificationWindowServiceService } from 'src/app/services/modification-window/modification-window-service.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';

@Component({
	selector: 'app-modification-window',
	templateUrl: './modification-window.component.html',
	styleUrls: ['./modification-window.component.css'],
})
export class ModificationWindowComponent {
	constructor(
		private route: ActivatedRoute,
		private plantService: PlantService,
		private companyService: CompanyService,
		private router: Router,
		public toastService: ToastService,
		public modificationWindow: ModificationWindowServiceService,
		private errorService: ErrorHandlerService,
	) {}

	resetPlant = false;
	resetTimeUnit = false;

	dropdownStyles = {
		width: '260px',
		height: '43px',
		marginRight: '25px',
		// borderColor: 'var(--default-gray-400, #b3bdc6)'
	};

	dropdownWrapperStyle = {
		width: '260px',
		// top:'42px',
		// borderColor: 'var(--default-gray-400, #b3bdc6)'
	};

	timeUnitStyle = {
		height: '43px',
		// borderColor: 'var(--default-gray-400, #b3bdc6)'
	};

	selectedCompany = {
		companyId: 0,
		companyName: '',
		isDeleted: false,
	};
	selectedPlant = {
		plantId: 0,
		plantName: '',
		isDeleted: false,
	};

	selectedTimeUnit = {
		id: 0,
		name: '',
	};

	selectedOperatorTimeUnit = {
		id: 0,
		name: '',
	};

	timeUnits = [
		{
			id: 1,
			name: 'Minutes',
		},
		{
			id: 2,
			name: 'Hours',
		},
		{
			id: 3,
			name: 'Days',
		},
	];

	resetCompany = false;
	toastText: string = '';
	instruction: string = 'Select Company and Plant';
	assignedPlants: any = [];

	handleSuccessToast(state: any) {
		// if (state === "Edit_Group") {
		// 	this.toastService.toastText = "Group successfully updated!"
		// }
		// if (state === "Add_Group") {
		// 	this.toastService.toastText = "Group successfully added!"
		// }
		this.toastService.showToast = state || false;
		setTimeout(() => {
			this.toastService.showToast = false;
		}, 5000);
	}

	resetFields() {
		this.windowTitle = 'No Window Available';
		this.operatorTitle = 'No Window Available';
		this.adminCurrentValue = '';
		this.operatorCurrentValue = '';
		this.selectedPlant = {
			plantId: 0,
			plantName: '',
			isDeleted: false,
		};

		this.selectedTimeUnit = {
			id: 0,
			name: '',
		};

		this.selectedOperatorTimeUnit = {
			id: 0,
			name: '',
		};

		if (this.currentRole === 'Super Admin') {
			this.resetCompany = true;
			this.selectedCompany = {
				companyId: 0,
				companyName: '',
				isDeleted: false,
			};
			this.resetCompany = false;
		}
	}

	handleModSubmit() {
		const item = {
			type:
				this.currentRole === 'Super Admin'
					? this.selectedTimeUnit.name
					: this.selectedOperatorTimeUnit.name,
			currentValue:
				this.currentRole === 'Super Admin'
					? this.adminCurrentValue
					: this.operatorCurrentValue,
			plantId: this.selectedPlant.plantId,
		};

		if (
			this.currentRole === 'Super Admin' &&
			(this.windowTitle !== 'No Window Available' ||
				this.operatorTitle !== 'No Window Available')
		) {
			this.modificationWindow
				.updateModificationWindow(this.selectedPlant.plantId, item)
				.subscribe({
					next: (modResult: any) => {
						this.windowTitle = `${this.adminCurrentValue} ${this.selectedTimeUnit.name}`;
						this.toastService.toastText =
							'Modification Window successfully updated!';
						this.handleSuccessToast(true);
						this.retrieveUnitsByPlantId(this.selectedPlant)
					},
					error: (error) => {
						this.errorService.handleError(
							error,
							'Error updating modification window',
						);
					},
				});
		}

		if (
			this.currentRole !== 'Super Admin' &&
			(this.windowTitle !== 'No Window Available' ||
				this.operatorTitle !== 'No Window Available')
		) {
			// already created mod window
			this.modificationWindow
				.updateModificationWindow(this.selectedPlant.plantId, item)
				.subscribe({
					next: (modResult: any) => {
						this.operatorTitle = `${this.operatorCurrentValue} ${this.selectedOperatorTimeUnit.name}`;
						this.toastService.toastText =
							'Modification Window successfully updated!';
						this.handleSuccessToast(true);
						this.retrieveUnitsByPlantId(this.selectedPlant)
					},
					error: (error) => {
						this.errorService.handleError(
							error,
							'Error updating modification window',
						);
					},
				});
		}

		if (
			this.currentRole === 'Super Admin' &&
			this.windowTitle === 'No Window Available' &&
			this.operatorTitle === 'No Window Available'
		) {
			this.modificationWindow.addModificationWindow(item).subscribe({
				next: (modResult: any) => {
					this.windowTitle = `${this.adminCurrentValue} ${this.selectedTimeUnit.name}`;

					this.toastService.toastText =
						'Modification Window successfully created!';
					this.handleSuccessToast(true);
					this.retrieveUnitsByPlantId(this.selectedPlant)
				},
				error: (error) => {
					this.errorService.handleError(
						error,
						'Error creating modification window',
					);
				},
			});
		}

		if (
			this.currentRole !== 'Super Admin' &&
			this.windowTitle === 'No Window Available' &&
			this.operatorTitle === 'No Window Available'
		) {
			this.modificationWindow.addModificationWindow(item).subscribe({
				next: (modResult: any) => {
					this.operatorTitle = `${this.operatorCurrentValue} ${this.selectedOperatorTimeUnit.name}`;
					this.toastService.toastText =
						'Modification Window successfully created!';
					this.handleSuccessToast(true);
					this.retrieveUnitsByPlantId(this.selectedPlant)
				},
				error: (error) => {
					this.errorService.handleError(
						error,
						'Error creating modification window',
					);
				},
			});
		}
	}

	findUnitByName(name: string) {
		return this.timeUnits.find((unit) => unit.name === name);
	}

	successAddText = 'Modification Window successfully updated!';
	companies: Company[] = [];
	plants: Plant[] = [];
	currentRole: any = '';
	adminCurrentValue: any = '';
	operatorCurrentValue: any = '';
	windowTitle = 'No Window Available';
	operatorTitle = 'No Window Available';

	initialWindow = {
		time: '',
		unit: '',
	};

	retrievePlantsByCompanyId(selected: any) {
		this.selectedCompany = selected;
		this.windowTitle = 'No Window Available';
		this.operatorTitle = 'No Window Available';

		if (this.selectedPlant.plantId > 0 || this.plants.length > 0) {
			this.resetPlant = true;
			this.selectedPlant = {
				plantId: 0,
				plantName: '',
				isDeleted: false,
			};

			this.adminCurrentValue = '';
			this.selectedTimeUnit = {
				id: 0,
				name: '',
			};
			this.selectedOperatorTimeUnit = {
				id: 0,
				name: '',
			};

			this.plants = [];
		}

		this.plantService
			.retrieveCompanyPlants(this.selectedCompany.companyId.toString())
			.subscribe({
				next: (response) => {
					this.plants = response.filter((item) => !item.isDeleted);
				},
				error: (err) => {
					return err;
				},
			});
		this.resetPlant = false;
	}

	renderTimeUnitLabel() {
		if (
			this.currentRole === 'Super Admin' &&
			this.selectedTimeUnit.id !== 0
		) {
			return this.selectedTimeUnit.name;
		} else if (
			this.currentRole !== 'Super Admin' &&
			this.selectedOperatorTimeUnit.id !== 0
		) {
			return this.selectedOperatorTimeUnit.name;
		} else {
			return 'Time Unit';
		}
	}

	retrieveUnitsByPlantId(selected: any) {
		this.windowTitle = 'No Window Available';
		this.operatorTitle = 'No Window Available';
		this.selectedPlant = selected;
		this.adminCurrentValue = '';
		this.selectedTimeUnit = {
			id: 0,
			name: '',
		};
		this.selectedOperatorTimeUnit = {
			id: 0,
			name: '',
		};
		this.modificationWindow
			.getByPlantId(this.selectedPlant.plantId)
			.subscribe({
				next: (modResult: any) => {
					this.adminCurrentValue = modResult?.adminCurrentValue;

					this.operatorCurrentValue = modResult?.operatorCurrentValue;

					const item = this.findUnitByName(
						this.currentRole == 'Super Admin'
							? modResult?.adminType
							: modResult?.operatorType,
					);

					if (this.currentRole == 'Super Admin') {
						this.selectedTimeUnit = item || {
							id: 0,
							name: '',
						};
					} else {
						this.selectedOperatorTimeUnit = item || {
							id: 0,
							name: '',
						};
					}

					if (
						modResult?.adminCurrentValue != null &&
						modResult?.adminType != null
					) {
						this.windowTitle = `${modResult?.adminCurrentValue} ${modResult?.adminType}`;
					}

					if (
						modResult?.operatorCurrentValue != null &&
						modResult?.operatorType != null
					) {
						this.operatorTitle = `${modResult?.operatorCurrentValue} ${modResult?.operatorType}`;
					}

					this.initialWindow = {
						time:
							this.currentRole == 'Super Admin'
								? modResult?.adminCurrentValue
								: modResult?.operatorCurrentValue,
						unit:
							this.currentRole == 'Super Admin'
								? modResult?.adminType
								: modResult?.operatorType,
					};
				},
				error: (response) => {
					return response;
				},
			});
	}

	wasEdited() {
		if (
			(this.currentRole === 'Super Admin' &&
				this.adminCurrentValue == this.initialWindow['time'] &&
				this.selectedTimeUnit.name == this.initialWindow['unit']) ||
			(this.currentRole != 'Super Admin' &&
				this.operatorCurrentValue == this.initialWindow['time'] &&
				this.selectedOperatorTimeUnit.name ==
					this.initialWindow['unit'])
		) {
			return false;
		}
		return true;
	}

	activateChangeWindowBtn() {
		if (!this.wasEdited()) {
			return false;
		} else if (
				(this.currentRole === 'Super Admin' &&
					(this.selectedTimeUnit.id === 0 ||
						!this.isNumberOfUnitsValid(this.adminCurrentValue))) ||
				(this.currentRole !== 'Super Admin' &&
					(this.selectedOperatorTimeUnit.id == 0 ||
						!this.isNumberOfUnitsValid(this.operatorCurrentValue)))
			) {
				return false;
			}
		return true;
	}

	setTimeUnit(selected: any) {
		if (this.currentRole == 'Super Admin') {
			this.selectedTimeUnit = selected;
		} else {
			this.selectedOperatorTimeUnit = selected;
		}
	}

	isNumberOfUnitsValid(num: string) {
		return !Number.isNaN(parseInt(num)) && parseInt(num) > 0;
	}

	validateInput(inputValue: any): void {
		const item = inputValue.value.replace(/\D/g, '');

		if (inputValue.value.length > 2) {
			const truncatedInput = inputValue.value.substr(0, 2);
			inputValue.value = truncatedInput.replace(/\D/g, '');
			this.adminCurrentValue = truncatedInput.replace(/\D/g, '');
		} else {
			inputValue.value = item;
			this.adminCurrentValue = item;
		}
	}

	ngOnInit(): void {
		this.currentRole = localStorage.getItem('role');

		try {
			const storedAssignment = localStorage.getItem('assignedPlants');
			if (storedAssignment) {
				this.assignedPlants = JSON.parse(storedAssignment);
			} else {
				console.error(
					"No data found in local storage for key 'assignedPlants' ",
				);
			}
		} catch (error) {
			console.error('Error parsing JSON data from local storage:', error);
		}

		if (this.currentRole !== 'Super Admin') {
			this.instruction = 'Select Plant';
			this.assignedPlants.map((assignment: Plant) =>
				this.plants.push(assignment),
			);
		} else {
			this.companyService.retrieveCompanies().subscribe({
				next: (companies) => {
					this.companies = companies.filter(
						(item) => !item.isDeleted,
					);
				},
				error: (response) => {
					return response;
				},
			});
		}
	}
}
