import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent {
  currentStep = 1;

  setCurrentStep(step:number) {
    this.showFirstNameError = false;
    this.showLastNameError = false;
    this.showEmailError = false;
    this.currentStep = step;
  }

  lastName:string = ""
  firstName:string = ""
  email:string = "";
  newPassword:string = "";
  currentPassword:string = "";
  confirmPassword:string = "";

  oldDetails:any = {};
  constructor(
    private authService: AuthService, 
    private router:Router, 
    public toastService: ToastService, 
  ) {}

  showToast: boolean = false;
  toastText: string = 'Profile successfully updated!';
  navToHome() {
    this.router.navigate(['home'])
  }
  displayOverlay: boolean = false;
  buttonTitle:string = "Next";
  
  toggleOverlay() {
    this.displayOverlay = !this.displayOverlay;
  }

  updateUserDetails() {
    const payload:any = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email
    }
    if (this.confirmPassword.length > 0 ||
        this.currentPassword.length > 0 ||
        this.newPassword.length > 0
      ) {
        payload.currentPassword = this.currentPassword;
        payload.newPassword = this.newPassword;
        payload.confirmPassword = this.confirmPassword;
      }
    this.showCurrentPasswordError = false;
    this.showNewPasswordError = false;
    this.showConfirmPasswordError = false;

    this.authService.updateUserDetails(payload).subscribe({
      next: (res:any) => {
        this.showToast = true;
        this.currentStep = 1;
        this.currentPassword = "";
        this.newPassword = "";
        this.confirmPassword = ""
        localStorage.setItem('token', res.token);
        setTimeout(() => {
          this.showToast = false;
        }, 5000);
      },
      error: (error) => {
        if (error.error.message === "Current Password is incorrect") {
          this.currentPasswordError = error.error.message;
          this.showCurrentPasswordError = true;
        }

        if (error.error.message === "New Password required" || error.error.message === "Your entry does not meet the password complexity requirements. See guidelines below ⓘ and try again") {
          this.showNewPasswordError = error.error.message;
          this.showNewPasswordError = true;
        }

        if (error.error.message === "The new password and confirm new password entries do not match. Please correct and try again") {
          this.confirmPasswordError = error.error.message;
          this.showConfirmPasswordError = true;
        }
      }
    })
  }

  firstNameError:string = "First Name should be 2-35 characters in length";
  lastNameError:string = "Last Name should be 2-35 characters in length";
  emailError:string = "Please enter a valid email address";

  currentPasswordError:string = "Password does not match";
  newPasswordError:string = "Invalid New Password";
  confirmPasswordError:string = "Invalid Confirm Password"

  showFirstNameError: boolean = false;
  showLastNameError: boolean = false;
  showEmailError: boolean = false;

  showCurrentPasswordError: boolean = false;
  showNewPasswordError: boolean = false;
  showConfirmPasswordError: boolean = false;

  hasSpecialCharactersOrSpaces(str:string) {
    const regex = /^[a-zA-Z0-9'\-\s]*$/;
    return !regex.test(str);
  }

  isValidEmail(email:string) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  isFieldValid() {
    let valid = true;
    if (this.firstName.length > 35 || this.firstName.length < 2) {
      this.showFirstNameError = true;
      this.firstNameError = "First Name should be 2-35 characters in length";
      valid = false;
    } else if (this.hasSpecialCharactersOrSpaces(this.firstName)) {
      this.showFirstNameError = true;
      this.firstNameError = "Only single quote ( ' ) and hyphen ( - ) are allowed as special characters";
      valid = false;
    } else {
      this.showFirstNameError = false;
    }
    

    if (this.lastName.length > 35 || this.lastName.length < 2) {
      this.showLastNameError = true;
      this.lastNameError = "Last Name should be 2-35 characters in length";
      valid = false;
    } else if (this.hasSpecialCharactersOrSpaces(this.lastName)) {
      this.showLastNameError = true;
      this.lastNameError = "Only single quote ( ' ) and hyphen ( - ) are allowed as special characters";
      valid = false;
    } else {
      this.showLastNameError = false;
    }

    if (!this.isValidEmail(this.email)) {
      this.showEmailError = true;
      this.emailError = "Please enter a valid email address";
      valid = false;
    } else {
      this.showEmailError = false;
    }
    return valid;
  }

  validate() {
    if (this.isFieldValid()) {
      this.setCurrentStep(2)
    }
    
  }

  handleChange() {
    if (this.lastName !== this.oldDetails.lastName || 
      this.firstName !== this.oldDetails.firstName ||
      this.email !== this.oldDetails.email) 
      {
        return "Update"
      } else {
        return "Next"
      }
  }
  ngOnInit(): void {
    // getUserDetails
    this.toastService.toastText = 'Profile successfully updated!'
    this.authService.getUserDetails().subscribe({
      next: (res:any) => {
        this.lastName = res.lastName;
        this.firstName = res.firstName
        this.email = res.email

        this.oldDetails = {
          lastName: this.lastName,
          firstName: this.firstName,
          email: this.email
        }
      },
      error: (error) => {
        
      }
    })
  }

}
