import { ChangeDetectorRef, Component } from '@angular/core'
import { Chart, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { LogsheetService } from 'src/app/services/logsheet/logsheet.service'
import { DatePipe } from '@angular/common'
import { ToastService } from 'src/app/services/toast/toast.service'
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service'
import { Company } from 'src/app/models/company.model'
import { Plant } from 'src/app/models/plant.model'
import { PlantService } from 'src/app/services/plant/plant.service'
import { CompanyService } from 'src/app/services/company/company.service'
import { UnitService } from 'src/app/services/unit/unit.service'
import { GroupService } from 'src/app/services/group/group.service'
import { Group } from 'src/app/models/group.model'
import { DataPoint } from 'src/app/models/datapoint.model'
import { DataPointService } from 'src/app/services/data-point/data-point.service'
Chart.register(...registerables, ChartDataLabels)
@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.css'],
})
export class AnalyticsComponent {
    myTotalChart: any
    unitChart: any
    myTotalGroupsChart: any
    myTotalDatapointsChart: any
    options = [
        { optionId: 1, optionName: 'Today', days: 0 },
        { optionId: 2, optionName: 'This Week', days: 4 },
        { optionId: 3, optionName: 'Last Week', days: 7 },
        { optionId: 4, optionName: 'Last Month', days: 30 },
        { optionId: 5, optionName: 'Last 3 Months', days: 90 },
        { optionId: 6, optionName: 'Last Year', days: 365 },
    ]

    selectedOption = {
        optionId: 1,
        optionName: 'Today',
        days: 0,
        isDeleted: false,
    }

    today = new Date()
    currentDate: any
    currentRole: any = ''
    resetPlant: boolean = false
    companies: Company[] = []
    plants: Plant[] = []
    assignedPlants: any = []
    startDate: any
    load: boolean = false
    defaultStyle = {
        width: '170px',
    }

    analytics = {
        Incomplete: 0,
        NotStarted: 0,
        ExecutedOnTime: 0,
        ExecutedPastDue: 0,
        Upcoming: 0,
        executed: 0,
        Overdue: 0,
    }

    unitAnalytics = {
        Active: 0,
        Inactive: 0
    }
    groupsArray:Group[] = []
    datapointsArray:DataPoint[] = []
    
    selectedValues = {
      company: {
        companyId: 0,
        companyName: "",
        isDeleted: false
      },
      plant: {
        plantId: 0,
        plantName: "",
        isDeleted: false
      },
    }

    errorMessage = {
      success: true,
      message: ''
    }

    specialStyle = {
      flex: 0.5
  }

    constructor(
        private logsheetService: LogsheetService,
        private unitService: UnitService,
        private datePipe: DatePipe,
        public toastService: ToastService,
        private errorService: ErrorHandlerService,
        private companyService: CompanyService,
        private plantService: PlantService,
        private changeDetector: ChangeDetectorRef,
        private groupService: GroupService,
        private datapointService: DataPointService

    ) {}

    ngOnInit(): void {
        try {
			const storedAssignment = localStorage.getItem("assignedPlants")
			if (storedAssignment) {
				this.assignedPlants = JSON.parse(storedAssignment)
			} 
			else {
				console.error("No data found in local storage for key 'assignedPlants' ");
			}
		} catch (error) {
			console.error("Error parsing JSON data from local storage:", error);
		}
        this.currentRole = localStorage.getItem('role')

        this.currentDate = this.datePipe.transform(this.today, 'dd-MMM-yy')

        if (this.currentRole === "Admin") {
          this.assignedPlants.map((assignment: Plant) => this.plants.push(assignment))
        } else {
          this.companyService.retrieveCompanies().subscribe({
              next: (companies) => {
                  this.companies = companies
               },
              error: (response) => {
                  this.errorMessage = this.errorService.handleRetrievalErrors(response)
              },
          })
      }
    }

    setSelection(data: any) {
        this.selectedOption = data
        const date = new Date()
        // sets the amount of days depending option selected
        if (this.selectedOption.optionName == 'This Week') {
            const days = date.getDay()
            this.selectedOption.days = days == 7 ? 1 : days + 1
        }
        if (this.selectedOption.optionName == 'Last Month') {
            const lastMonth = new Date()
            lastMonth.setMonth(date.getMonth() - 1)
            const timeDiff = date.getTime() - lastMonth.getTime()
            const dayDiff = timeDiff / (1000 * 3600 * 24)
            this.selectedOption.days = dayDiff
        }
        if (this.selectedOption.optionName == 'Last 3 Months') {
            const lastMonth = new Date()
            lastMonth.setMonth(date.getMonth() - 3)
            const timeDiff = date.getTime() - lastMonth.getTime()
            const dayDiff = timeDiff / (1000 * 3600 * 24)
            this.selectedOption.days = dayDiff
        }
        this.retrieveAnalytics(this.selectedOption, this.selectedValues.plant.plantId)
        this.retrieveUnitAnalytics(this.selectedValues.plant.plantId)
    }

    setSelectedCompany(item: Company) {
      if (item.companyId === this.selectedValues.company.companyId) return
      const initalState = { plantId: 0, plantName: "", isDeleted: false }
      if (this.isSelectionByUser() && (this.selectedValues.plant.plantId > 0 || this.plants.length > 0)) {
          this.resetPlant = true
          this.plants = []
          this.selectedValues.plant = { ...initalState }
      }
      // this.units = []
      this.plantService
          .retrieveCompanyPlants(item.companyId.toString())
          .subscribe({
              next: (response) => {
                  const plantsArrayWithoutCompany = response.map(({ plantId, plantName, isDeleted }) => ({
                      plantId,
                      plantName,
                      isDeleted,
                    }))

                  this.plants = plantsArrayWithoutCompany
              },
              error: (err) => {
                  this.errorMessage = this.errorService.handleRetrievalErrors(err)
              },
          })
      this.selectedValues.company = item
      this.resetPlant = false
  }

  setPlant(item:any) {
    this.selectedValues.plant = {
        plantId: item.plantId,
        plantName: item.plantName,
        isDeleted: item.isDeleted
    }

    this.retrieveAnalytics(this.selectedOption, this.selectedValues.plant.plantId)
    this.retrieveUnitAnalytics(this.selectedValues.plant.plantId)
    this.retrieveGroupData(this.selectedValues.plant.plantId)
    this.retrieveDatapointsData(this.selectedValues.plant.plantId)
}

  isSelectionByUser(): boolean {
    return !((this.changeDetector as any).destroyed)
}

    monthStart(date: Date, number: number) {
        const diff = new Date()
        return diff.setMonth(date.getMonth() - number)
    }

    weekStart(date: Date) {
        const diff =
            date.getDate() - date.getDay() + (date.getDay() === 0 ? -7 : 0)
        return new Date(date.setDate(diff))
    }

    stringToDate(date: string) {
        const newDate = new Date(date)
        const customFormat = 'dd-MMM-yyyy'
        const formattedDate = this.datePipe.transform(newDate, customFormat)
        return formattedDate
    }

    async retrieveAnalytics(selected: any, plantId: number) {
        await this.logsheetService.retrieveAnalytics(selected.days, plantId).subscribe({
            next: (res) => {
                this.analytics = res

                const dateBefore = new Date()
                if (this.selectedOption.optionName == 'This Week') {
                    const newDate = this.weekStart(dateBefore)
                    this.startDate = this.datePipe.transform(
                        newDate,
                        'dd-MMM-yy'
                    )
                } else if (this.selectedOption.optionName == 'Last Month') {
                    const newDate = this.monthStart(dateBefore, 1)
                    this.startDate = this.datePipe.transform(
                        newDate,
                        'dd-MMM-yy'
                    )
                } else if (this.selectedOption.optionName == 'Last 3 Months') {
                    const newDate = this.monthStart(dateBefore, 3)
                    this.startDate = this.datePipe.transform(
                        newDate,
                        'dd-MMM-yy'
                    )
                } else {
                    dateBefore.setDate(
                        this.today.getDate() - this.selectedOption.days
                    )
                    this.startDate = this.datePipe.transform(
                        dateBefore,
                        'dd-MMM-yy'
                    )
                }
                this.renderChart()
            },
            error: (error) => {
                this.errorService.handleError(
                    error,
                    'Error retrieving analytics for selected range'
                )
            },
        })
    }

    async retrieveUnitAnalytics(plantId: number) {
        await this.unitService.retrieveUnitAnalytics(plantId).subscribe({
            next: (res) => {
                this.unitAnalytics = res

                this.renderUnitChart()
            },
            error: (error) => {
                this.errorService.handleError(
                    error,
                    'Error retrieving analytics for selected range'
                )
            },
        })
    }

    async retrieveGroupData(plantId: number) {
        await this.groupService.retrieveGroupPlant(plantId).subscribe({
            next: (groups) => {
                this.groupsArray = [...groups]
                this.renderChart()
            },
            error: (error) => {
                this.errorService.handleError(
                    error,
                    'Error retrieving groups'
                )
            },
        })
    }

    async retrieveDatapointsData(plantId: number) {
        await this.datapointService.retrieveDataPointByPlantId(plantId.toString(), "true").subscribe({
            next: (datapoints) => {
                this.datapointsArray = [...datapoints]
                this.renderChart()
            },
            error: (error) => {
                this.errorService.handleError(
                    error,
                    'Error retrieving datapoints'
                )
            },
        })
    }

    renderChart() {
        if (this.myTotalChart != undefined) {
            this.myTotalChart.destroy() // destroys current chart
        }
        if (this.myTotalGroupsChart != undefined) {
            this.myTotalGroupsChart.destroy() // destroys current chart
        }
        if (this.myTotalDatapointsChart != undefined) {
            this.myTotalDatapointsChart.destroy() // destroys current chart
        }
        this.myTotalGroupsChart = new Chart('totalGroup', {
            type: 'bar',
            data: {
                labels: ['', ''],
                datasets: [{
                    label: 'Active',
                    data: [this.groupsArray.filter(group => !group.isDeleted).length],
                    backgroundColor: '#069A51',
                    borderWidth: 1,
                    stack: 'Stack 0',
                    barThickness: 50,
                  }, {
                    label: 'Inactive',
                    data: [this.groupsArray.filter(group => group.isDeleted).length],
                    backgroundColor: '#7B818A',
                    borderWidth: 1,
                    stack: 'Stack 0',
                    barThickness: 50,
                  }]
            },
            options: {
                maintainAspectRatio: false,
                indexAxis: 'y',
                responsive: true,
                plugins: {
                    datalabels: {
                        color: 'white',
                        anchor: 'center',
                        align: 'center',
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                        formatter: function (value, context) {
                            return value > 0 ? value : ''
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        position: 'top',
                        display: false,
                        labels: {
                            filter: function (legendItem, chartData) {
                                return legendItem.datasetIndex !== 0 // Hide the legend
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        beginAtZero: true,
                        display: false,
                        stacked: true,
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        display: false,
                        stacked: true
                    },
                },
            },
        })
        this.myTotalDatapointsChart = new Chart('totalDatapoint', {
            type: 'bar',
            data: {
                labels: ['', ''],
                datasets: [{
                    label: 'Active',
                    data: [this.datapointsArray.filter(datapoints => !datapoints.isDeleted).length],
                    backgroundColor: '#069A51',
                    borderWidth: 1,
                    stack: 'Stack 0',
                    barThickness: 50,
                  }, {
                    label: 'Inactive',
                    data: [this.datapointsArray.filter(datapoints => datapoints.isDeleted).length],
                    backgroundColor: '#7B818A',
                    borderWidth: 1,
                    stack: 'Stack 0',
                    barThickness: 50,
                  }]
            },
            options: {
                maintainAspectRatio: false,
                indexAxis: 'y',
                responsive: true,
                plugins: {
                    datalabels: {
                        color: 'white',
                        anchor: 'center',
                        align: 'center',
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                        formatter: function (value, context) {
                            return value > 0 ? value : ''
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        position: 'top',
                        display: false,
                        labels: {
                            filter: function (legendItem, chartData) {
                                return legendItem.datasetIndex !== 0 // Hide the legend
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        beginAtZero: true,
                        display: false,
                        stacked: true,
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        display: false,
                        stacked: true
                    },
                },
            },
        })
        this.myTotalChart = new Chart('total', {
            type: 'bar',
            data: {
                labels: ['', '', '', '', '', ''],
                datasets: [{
                    label: 'Other values',
                    data: [
                        this.analytics.Upcoming,
                        this.analytics.NotStarted,
                        this.analytics.Incomplete,
                        this.analytics.Overdue,
                        this.analytics.ExecutedOnTime,
                        this.analytics.ExecutedPastDue,
                    //   this.analytics.executed,
                    ],
                    backgroundColor: [
                        '#1E5555',  // available
                        '#55359F', // upcoming
                        '#EB6429', // upcoming
                        '#C53030', // overdue
                        '#3182CE', //ExecutedOnTime
                        '#102F56', //ExecutedPastDue
                    ],
                    borderWidth: 1,
                    stack: 'Stack 1',
                    barThickness: 50,
                    barPercentage: 0.8,
                  },
                //   {
                // //   label: 'Executed On Time',
                //   data: [this.analytics.ExecutedOnTime],
                //   backgroundColor: '#3182CE',
                //   borderWidth: 1,
                //   stack: 'Stack 0',
                //   barThickness: 50,
                //   yAxisID: 'test',
                //   barPercentage: 1
                // }, {
                // //   label: 'Executed Past Due',
                //   data: [this.analytics.ExecutedPastDue],
                //   backgroundColor: '#102F56',
                //   borderWidth: 1,
                //   stack: 'Stack 0',
                //   barThickness: 50,
                //   barPercentage: 1
                // },
                ]
              },
            options: {
                maintainAspectRatio: false,
                indexAxis: 'y',
                responsive: true,
                plugins: {
                    datalabels: {
                        color: 'white',
                        anchor: 'center',
                        align: 'center',
                        // rotation: 270,
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                        formatter: function (value, context) {
                            return value > 0 ? value : ''
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        position: 'top',
                        display: false,
                        labels: {
                            filter: function (legendItem, chartData) {
                                return legendItem.datasetIndex !== 0 // Hide the legend
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        beginAtZero: true,
                        display: true,
                        stacked: true
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        display: true,
                        stacked: true
                    },
                },
            },
        })
        this.load = true
    }

    renderUnitChart() {
        if (this.unitChart != undefined) {
            this.unitChart.destroy() // destroys current chart
        }
        this.unitChart = new Chart('unit', {
            type: 'bar',
            data: {
                labels: ['', ''],
                datasets: [{
                  label: 'Active',
                  data: [this.unitAnalytics.Active],
                  backgroundColor: '#069A51',
                  borderWidth: 1,
                  stack: 'Stack 0',
                  barThickness: 50,
                }, {
                  label: 'Inactive',
                  data: [this.unitAnalytics.Inactive],
                  backgroundColor: '#7B818A',
                  borderWidth: 1,
                  stack: 'Stack 0',
                  barThickness: 50,
                }]
              },
            options: {
                maintainAspectRatio: false,
                indexAxis: 'y',
                responsive: true,
                plugins: {
                    datalabels: {
                        color: 'white',
                        anchor: 'center',
                        align: 'center',
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                        formatter: function (value, context) {
                            return value > 0 ? value : ''
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        position: 'top',
                        display: false,
                        labels: {
                            filter: function (legendItem, chartData) {
                                return legendItem.datasetIndex !== 0 // Hide the legend
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        beginAtZero: true,
                        display: false,
                        stacked: true,
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: false,
                            drawOnChartArea: false,
                        },
                        display: false,
                        stacked: true
                    },
                },
            },
        })
        this.load = true
    }

    updateChartData() {
        this.myTotalChart.destroy()
        this.myTotalGroupsChart.destroy()
    }

    updateUnitChartData() {
        this.unitChart.destroy()
    }
}
