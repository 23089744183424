import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {
	@Input() values: any = []
	@Output() pageChange: EventEmitter<number> = new EventEmitter<number>()
	selectedPage = {
		backgroundColor: "#0F2642",
		color: "white",
		borderRadius: "4px",
	}

	setCurrentPage(page: any) {
		this.pageChange.emit(page)
	}

	incrementArray(arr: any, increment: any) {
		return arr.map((item: any) => item + increment)
	}

	decrementArray(arr: any, decrement: any) {
		return arr.map((item: any) => item - decrement)
	}

	performIncrement() {
		const { values } = this;

		if (values.currentPage === values.totalPages) return

		const result = values.currentPage + 1

		this.setCurrentPage(result)

		if (values.currentPage > values.loopArray[values.loopArray.length - 1]) this.shiftPageRight()
	}

	performDecrement() {
		const { values } = this;

		if (values.currentPage === 1) return

		const result = values.currentPage - 1

		this.setCurrentPage(result)

		if (values.currentPage < values.loopArray[0]) this.shiftPageLeft()
	}

	removeNumbersGreaterThan(arr: any, x: any) {
		return arr.filter((number: any) => number <= x)
	}

	shiftPageRight() {
		const { values } = this
		const incrementedArray = this.incrementArray(
			values.loopArray,
			values.currentIncrement,
		)
		values.loopArray = incrementedArray
	}

	shiftPageLeft() {
		const { values } = this
		const decrementArray = this.decrementArray(
			values.loopArray,
			values.currentIncrement,
		)
		values.loopArray = decrementArray
	}
}
