<!-- @format -->

<div class="dashboard">
	<!-- SIDEBAR -->
	<div class="side-nav">
		<app-sidebar></app-sidebar>
	</div>

	<!-- MAIN SECTION -->
	<div class="main-content">
		<!-- HEADER -->
		<app-header></app-header>
		<!-- PAGE BODY -->
		<main>
			<router-outlet></router-outlet>
		</main>
	</div>
</div>
