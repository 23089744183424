import { Component } from '@angular/core';
import { Company } from 'src/app/models/company.model';
import { Plant } from 'src/app/models/plant.model';
import { Unit } from 'src/app/models/unit.model';
import { ActivatedRoute, Router } from '@angular/router'
import { CompanyService } from 'src/app/services/company/company.service'
import { PlantService } from 'src/app/services/plant/plant.service'
import { ToastService } from 'src/app/services/toast/toast.service';
import { UnitService } from 'src/app/services/unit/unit.service'
import { Group } from 'src/app/models/group.model';
import { GroupService } from 'src/app/services/group/group.service';
import { ErrorHandlerService } from 'src/app/services/errorHandler/error-handler.service';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.css']
})
export class GroupsComponent {
    activeItem = 0
    assignedPlants: any = []
    companies: Company[] = []
    currentRole: any = ""
    firstRender = false
    groups: Group[] = []
    plants: Plant[] = []
    selectedGroup: number = 0;
    showCompanies = false;
    showDeactivateMenu= false
    showMenu = false
    showPlants = false;
    showToast: boolean = false;

    //pagination data
	currentPage: number = 1
	data: Plant[] = []
	pageSize: number = 7
	totalPages: number = 1
	loopArray: number[] = []

    deactivateText = {
        title: "Deactivate Non-Admin Group",
        message1: "",
        message2: "This action is irreversible. Do you wish to proceed?"
    }

    emptyState = {
        imgPath: '../../assets/group.svg',
        message1: 'No groups to display',
        message2: 'Created Non-Admin groups will appear here.',
    }

    emptyStateCompanies = {
        imgPath: '../../assets/group.svg',
        message1: 'Select a company to see associated Non-Admin groups listed here',
        message2: '',
    }

    emptyStatePlants = {
        imgPath: '../../assets/group.svg',
        message1: 'Select a plant to see associated Non-Admin groups listed here',
    }

    errorMessage: {
        success: boolean;
        message: string;
    } = {
        success: true,
        message: ''
    }

    get values() {
		return {
			data: this.data,
			currentPage: this.currentPage, 
			totalPages: this.totalPages, 
			loadData: this.loadData, 
			loopArray: this.loopArray 
		}
	}

    selectedCompany = {
        companyId: 0,
        companyName: '',
        isDeleted: false
    }

    selectedPlant = {
        plantId: 0,
        plantName: '',
        isDeleted: false
    }

    //styles
    defaultStyle = {
        flex: 1
    }

    specialStyle = {
        flex: 0.5
    }

    constructor(
        private route: ActivatedRoute,
        private plantService: PlantService,
        private companyService: CompanyService,
        private groupService: GroupService,
        private router: Router,
        public toastService: ToastService,
        private errorService: ErrorHandlerService,
    ) {}

    activateAddGroupBtn() {        
		if (this.currentRole === "Super Admin") {
			if (
				this.selectedCompany.companyId === 0 ||
				this.selectedPlant.plantId === 0
			) {
				return false
			} else if (
				this.selectedCompany.isDeleted ||
				this.selectedPlant.isDeleted
			) {
				return false
			} else {
				return true
			}
		} else if (this.currentRole === "Admin") {
			if (
				this.selectedPlant.plantId === 0 ||
                this.selectedPlant.plantId == null ||
                this.selectedPlant.plantId == undefined ||
                this.selectedPlant.plantName.length === 0 ||
                this.selectedPlant.isDeleted
			) {
				return false
			} else {
				return true
			}
		}
		return true
	}

    handleDeactivate() {
		this.groupService
			.deactivateGroup(this.selectedGroup)
			.subscribe({
				next: (response) => {
                    this.handleSuccessToast("Group-Deactivated")
                    this.groupService.retrieveGroupPlant(this.selectedPlant.plantId)
                        .subscribe({
                            next: (response) => {
                                this.groups = response
                            },
                            error: (response) => { 
                                this.errorMessage = response.message || response
                            },
                        })
				},
				error: (error) => {
					this.errorService.handleError(error, 'Error deactivating group')
				},
			})
	}

    handleSuccessToast(state:any) {
        if (state === 'Edit_Group') {
            this.toastService.toastText = 'Group successfully updated!'
        }
        if (state === 'Add_Group'){
            this.toastService.toastText = 'Group successfully added!'
        }
        if (state === "Group-Deactivated") {
			this.toastService.toastText = "Non-Admin Group succesfully deactivated!"
		}
        this.toastService.showToast = state || false;
        setTimeout(() => {
            this.toastService.showToast = false;
        }, 5000);
    }

    loadData(selected: any) {
		this.selectedPlant = selected

		this.groupService
		.retrieveGroupPlantPaginated(this.selectedPlant.plantId, this.currentPage, this.pageSize)
		.subscribe({
			next: (result: any) => {
				this.groups = result.item1
				this.data = result.item1
				this.totalPages = result.item2
                this.updateLoopArray()
			},
			error: (err) => {
				this.errorMessage = this.errorService.handleRetrievalErrors(err)
			},
		})
	}

    onPageChange(pageNumber: any) {
		this.currentPage = pageNumber
		this.loadData(this.selectedPlant)
	}

    showDeactivateModal(group: string, company: string, plant: string, groupId: number | undefined) {
        this.selectedGroup = groupId ?? 0
        this.deactivateText.message1 = "You are about to deactivate " + group + " in "+ plant + " at " + company+". Doing this will remove all types of assignments to this group."
        this.showDeactivateMenu = true
    }

    setPlant(item:any) {
        
        if (!this.firstRender) {
            this.selectedPlant = {
                plantId: item.plantId,
                plantName: item.plantName,
                isDeleted: item.isDeleted
            }
        }

        this.loadData(this.selectedPlant)
    }

    setSelectedCompany(item:any) {
        this.selectedCompany = {
            companyId : item.companyId,
            companyName: item.companyName,
            isDeleted: item.isDeleted
        }    
        if (!this.firstRender) {
            this.selectedPlant = {
                plantId: 0,
                plantName: '',
                isDeleted: false
            }
    
            this.groups = [];
        }

        this.plantService
            .retrieveCompanyPlants(item.companyId.toString())
            .subscribe({
                next: (response) => {

                    const plantsArrayWithoutCompany = response.map(({ plantId, plantName, isDeleted }) => ({
                        plantId,
                        plantName,
                        isDeleted,
                    }));

                    this.plants = plantsArrayWithoutCompany
                },
                error: (err) => {
                    this.errorMessage = this.errorService.handleRetrievalErrors(err)
                },
            })
        this.selectedCompany = item
    }

    toggleMenu(plantId: any) {
        this.activeItem = plantId
        this.showMenu = !this.showMenu
    }

    toggleSelect() {
        this.showCompanies = !this.showCompanies;
    }

    updateLoopArray() {
        this.loopArray = new Array(this.totalPages).fill(0).map((_, index) => index + 1);
    }

    ngOnInit(): void {
        this.currentRole = localStorage.getItem('role')
        this.handleSuccessToast(history.state.prevPage)
        this.firstRender = history.state?.firstRender


        if (this.firstRender) {
            this.selectedCompany = history.state.selectedValues?.company
            this.selectedPlant = history.state.selectedValues?.plant
            this.setSelectedCompany(this.selectedCompany)
            this.setPlant(this.selectedPlant)
        }

        try {
			const storedAssignment = localStorage.getItem("assignedPlants")
			if (storedAssignment) {
				this.assignedPlants = JSON.parse(storedAssignment)
			} 
			else {
				console.error("No data found in local storage for key 'assignedPlants' ");
			}
		} catch (error) {
			console.error("Error parsing JSON data from local storage:", error);
		}

        if (this.currentRole === "Admin") {
            this.assignedPlants.map((assignment: Plant) => this.plants.push(assignment))
        } else {
            this.companyService.retrieveCompanies().subscribe({
                next: (companies) => {
                    this.companies = companies
                },
                error: (response) => {
                    this.errorMessage = this.errorService.handleRetrievalErrors(response)
                },
            })
        }

        history.replaceState({}, '')
        this.firstRender = false
    }
}
