<!-- @format -->

<app-success-toast *ngIf="toastService.showToast"></app-success-toast>

<div class="custom-section card add-unit">
	<div class="section-header">
		<div>
			<h1 class="section-title">Non-Admin Group</h1>

			<span class="subtext"
				>Manage the list of Non-Admin Groups within Lightyear.</span
			>
		</div>

		<div>
			<button
				type="button"
				[ngClass]="!activateAddGroupBtn() ? 'disabled-btn' : ''"
				[routerLink]="!activateAddGroupBtn() ? [] : ['add-group']"
				[state]="{company: selectedCompany, plant: selectedPlant}">
				Add a Group
			</button>
		</div>
	</div>

	<div class="dropdown-group">
		<app-dropdown
			*ngIf="currentRole === 'Super Admin'"
			[options]="companies"
			selectedItem="{{ selectedCompany }}"
			(onSelectOption)="setSelectedCompany($event)"
			[fields]="['companyId', 'companyName']"
			[ngStyle]="currentRole === 'Admin' ? specialStyle : defaultStyle"
			[label]="
				selectedCompany.companyName === ''
					? 'Choose Company'
					: selectedCompany.companyName
			"></app-dropdown>

		<app-dropdown
			[disabled]="
				selectedCompany.companyName.length === 0 &&
				currentRole === 'Super Admin'
			"
			[options]="plants"
			selectedItem="{{ selectedPlant }}"
			(onSelectOption)="setPlant($event)"
			[fields]="['plantId', 'plantName']"
			[ngStyle]="currentRole === 'Admin' ? specialStyle : defaultStyle"
			[label]="
				selectedPlant.plantName === ''
					? 'Choose Plant'
					: selectedPlant.plantName
			"></app-dropdown>
	</div>

	<table class="custom-table">
		<thead>
			<tr>
				<th>Group Name</th>

				<th>Status</th>

				<th>
					<img
						src="../../assets/dotted-menu.svg"
						alt="menu icon" />
				</th>
			</tr>
		</thead>

		<tbody *ngFor="let group of groups">
			<tr>
				<td>{{ group.groupName }}</td>

				<td>{{ group.isDeleted ? "Inactive" : "Active" }}</td>

				<td>
					<img
						*ngIf="!group.isDeleted"
						src="../../assets/dotted-menu.svg"
						alt="menu icon"
						(click)="toggleMenu(group.groupId)" />

					<div
						[ngClass]="
							!showMenu || group.groupId != activeItem
								? 'd-none'
								: ''
						"
						class="custom-table__menu">
						<div>
							{{ group.groupName }}
						</div>

						<!-- <a
                [routerLink]="[
                  '/settings',

                  'groups',

                  'edit-group',

                  group.groupId
                ]"
                [state]="[selectedCompany, selectedPlant]"
              >
                <img src="../../assets/edit-pencil.svg" alt="menu icon" />

                <span>Edit Group</span>
              </a>

              <div class="underline"></div> -->

						<div
							(click)="
								showDeactivateModal(
									group.groupName,
									selectedCompany.companyName,
									selectedPlant.plantName,
									group.groupId
								);
								showMenu = false
							">
							<img
								src="../../assets/delete.svg"
								alt="menu icon" />

							<span>Deactivate</span>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>

	<div class="center">
		<app-empty-state [data]="emptyStateCompanies" *ngIf="
			selectedCompany.companyName.length === 0 && currentRole !== 'Admin'
		"/>
	</div>

	<div class="center">
		<app-empty-state [data]="emptyStatePlants" *ngIf="
			(selectedPlant.plantName.length === 0 &&
				selectedCompany.companyName.length > 0) ||
			(selectedPlant.plantName.length === 0 && currentRole === 'Admin')
		"/>
	</div>

	<div class="center">
		<app-empty-state [data]="emptyState" *ngIf="
			selectedPlant.plantName.length > 0 &&
			(selectedCompany.companyName.length > 0 ||
				selectedPlant.plantName.length > 0) &&
			groups.length === 0
		"/>
	</div>
	<app-pagination [values]="values" (pageChange)="onPageChange($event)" *ngIf="totalPages > 0"/>
</div>

<app-deactivate-modal
	(onCancel)="showDeactivateMenu = false"
	*ngIf="showDeactivateMenu"
	[title]="deactivateText.title"
	[message1]="deactivateText.message1"
	(onSave)="handleDeactivate()"
	[message2]="deactivateText.message2" />
