<!-- @format -->

<div class="card custom-section">
	<h1 class="section-title">Add a Super User</h1>
	<p class="subtext">Fill out the details using the form below.</p>
	<form
		#form="ngForm"
		style="margin-top: 2rem"
		(ngSubmit)="!isActiveBtn() ? null : addUser()">
		<div class="form-group col-2">
			<div>
				<label
					for="first_name"
					class="custom-label required"
					>First Name</label
				>
				<input
					type="text"
					class="custom-input"
					name="first_name"
					id="first_name"
					minlength="2"
					maxlength="35"
					[(ngModel)]="addSuperUserRequest.firstName" />
			</div>
			<div>
				<label
					for="last_name"
					class="custom-label required"
					>Last Name</label
				>
				<input
					type="text"
					class="custom-input"
					name="last_name"
					id="last_name"
					minlength="2"
					maxlength="35"
					[(ngModel)]="addSuperUserRequest.lastName" />
			</div>
		</div>
		<div>
			<label
				for="email"
				class="custom-label required"
				>Email</label
			>
			<input
				type="email"
				class="custom-input"
				name="email"
				id="email"
				maxlength="320"
				[(ngModel)]="addSuperUserRequest.username" />
		</div>

		<div class="btn-group">
			<button
				type="button"
				class="btn-outline"
				(click)="transferData()">
				Cancel
			</button>
			<button
				[ngClass]="!isActiveBtn() ? 'disabled-btn' : ''"
				[disabled]="!isActiveBtn()"
				type="submit"
				class="btn-fill">
				Add User
			</button>
		</div>
	</form>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
