<!-- @format -->

<div class="custom-date">
	<span [ngClass]="isDisabled ? 'disabled' : ''">{{
		getFormattedDate()
	}}</span>
	<input
		[(ngModel)]="datePickerValue"
		(change)="formatDate()"
		type="date"
		[min]="getCurrentDate()"
		(name)="(id)"
		[id]="id"
		class="custom-input"
		disabled="{{ isDisabled }}"
		[ngClass]="{'disabled-input': isDisabled, 'error': error }"
		placeholder=" YYYY-MM-DD" />
</div>
