<!-- @format -->

<div class="custom-section card">
	<div class="section-header">
		<div>
			<h1 class="section-title">Plants</h1>
		</div>
		<div>
			<button
				type="button"
				[ngClass]="
					selectedCompany.companyId == 0 || selectedCompany.isDeleted
						? 'disabled-btn'
						: ''
				"
				[routerLink]="
					selectedCompany.companyId == 0 || selectedCompany.isDeleted
						? []
						: ['add-plant']
				"
				[state]="selectedCompany">
				Add a plant
			</button>
		</div>
	</div>

	<div class="dropdown-width dropdown-group">
		<app-dropdown
			[disabled]="false"
			[options]="companies"
			selectedItem="{{ selectedCompany }}"
			(onSelectOption)="loadData($event)"
			[fields]="['companyId', 'companyName']"
			[label]="
				selectedCompany.companyId == 0
					? 'Choose Company'
					: selectedCompany.companyName
			"></app-dropdown>
	</div>

	<table class="custom-table">
		<thead>
			<tr>
				<th>Plant</th>
				<th>Status</th>
				<th>
					<img
						src="../../assets/dotted-menu.svg"
						alt="menu icon" />
				</th>
			</tr>
		</thead>
		<tbody *ngFor="let plant of plants">
			<tr>
				<td>{{ plant.plantName }}</td>
				<td>{{ plant.isDeleted ? "Inactive" : "Active" }}</td>
				<td>
					<img
						*ngIf="!plant.isDeleted"
						src="../../assets/dotted-menu.svg"
						alt="menu icon"
						(click)="toggleMenu(plant.plantId)"
						style="cursor: pointer" />

					<div
						[ngClass]="
							!showMenu || plant.plantId != activeItem
								? 'd-none'
								: ''
						"
						class="custom-table__menu">
						<div>
							{{ plant.plantName }}
						</div>

						<a
							[routerLink]="[
								'/settings',
								'plants',
								'edit-plant',
								plant.plantId
							]"
							[state]="selectedCompany">
							<img
								src="../../assets/edit-pencil.svg"
								alt="menu icon" />
							<span>Edit Plant</span>
						</a>
						<div class="underline"></div>
						<div
							(click)="
								showDeactivateModal(
									plant.plantName,
									plant.plantId
								);
								showMenu = false
							">
							<img
								src="../../assets/delete.svg"
								alt="menu icon" />
							<span>Deactivate</span>
						</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
	<div
		class="center">
		<app-empty-state [data]="emptyStateCompanies" *ngIf="selectedCompany.companyId == 0"/>
	</div>
	<div class="center">
		<app-empty-state [data]="emptyStatePlants" *ngIf="selectedCompany.companyId > 0 && plants.length === 0"/>
	</div>
	<app-pagination [values]="values" (pageChange)="onPageChange($event)" *ngIf="totalPages > 0"/>
</div>

<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
<app-deactivate-modal
	(onCancel)="showDeactivateMenu = false"
	*ngIf="showDeactivateMenu"
	[title]="deactivateText.title"
	[message1]="deactivateText.message1"
	[message2]="deactivateText.message2"
	(onSave)="handleDeactivate()" />
