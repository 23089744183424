<!-- <div class="overview-navigation">
    <div class="forward-page">Analytics</div>
</div> -->

<div class="analytics-container">
    <div class="custom-section card">
        <div class="heading">
            <div>
                <h2 class="title">Analytics</h2>
                <p>Take a look at your group performance breakdown</p>
            </div>
            <!-- <div class="dropdown-container">
           
            <div *ngIf="load == true">
            <p class="dates">{{startDate}} to {{currentDate}}</p>
            </div>
            
            </div> -->
        </div>

        <div class="dropdown-container">
            <div class="">
                <app-dropdown
                    *ngIf="currentRole === 'Super Admin'"
                    [options]="companies"
                    selectedItem="{{ selectedValues.company }}"
                    (onSelectOption)="setSelectedCompany($event)"
                    [fields]="['companyId', 'companyName']"
                    [ngStyle]="currentRole === 'Admin' ? specialStyle : defaultStyle"
                    [label]="
                        selectedValues.company.companyName === ''
                            ? 'Choose Company'
                            : selectedValues.company.companyName
                    "></app-dropdown>
        
                <app-dropdown
                    [disabled]="
                        selectedValues.company.companyName.length === 0 &&
                        currentRole === 'Super Admin'
                    "
                    [options]="plants"
                    selectedItem="{{ selectedValues.plant }}"
                    (onSelectOption)="setPlant($event)"
                    [fields]="['plantId', 'plantName']"
                    [ngStyle]="currentRole === 'Admin' ? specialStyle : defaultStyle"
                    [label]="
                        resetPlant || selectedValues.plant.plantName === ''
                            ? 'Choose Plant'
                            : selectedValues.plant.plantName
                    "></app-dropdown>
            </div>
            <app-dropdown
            [disabled]="false"
            [options]="options"
            [selectedItem]="selectedOption"
            (onSelectOption)="setSelection($event)"
            [fields]="['optionId', 'optionName', 'days']"
            [inputStyle]="defaultStyle"
            
            [label]="selectedOption.optionName">
        </app-dropdown>
        </div>
       
        
        
            <div class="chart-container units">
                <div class="label-container">
                    <p class="logsheet-count"><span class="digits">{{(unitAnalytics.Active|| 0) + (unitAnalytics.Inactive|| 0)}}</span> Unit(s)</p>
                    <div class="legend"> 
                        <div class="legend-item"><div class="color-legend active-key"></div><div class="desc">Active</div></div>
                        <div class="legend-item"><div class="color-legend inactive-key"></div><div class="desc">Inactive</div></div>
                    </div>
                </div>
                <div class="chart"><canvas id="unit"></canvas></div>
            </div>

            <div class="chart-container chart-container-sheets">
                <div class="label-container">
                    <p class="logsheet-count"><span class="digits">{{(analytics.ExecutedOnTime|| 0) + (analytics.ExecutedPastDue|| 0) + (analytics.Incomplete || 0) + (analytics.Upcoming || 0) + (analytics.NotStarted|| 0) + (analytics.Overdue || 0)}}</span> Total Log Sheets</p>
                    <div class="legend"> 
                        <div class="legend-item"><div class="color-legend upcoming-key"></div><div class="desc">Upcoming</div></div>
                        <div class="legend-item"><div class="color-legend not__started-key"></div><div class="desc">Not Started</div></div>
                        <div class="legend-item"><div class="color-legend incomplete-key"></div><div class="desc">Incomplete</div></div>
                        <div class="legend-item"><div class="color-legend executed__on__time-key"></div><div class="desc">Executed On Time</div></div>
                        <div class="legend-item"><div class="color-legend executed__past__due-key"></div><div class="desc">Executed Past Due</div></div>
                        <div class="legend-item"><div class="color-legend overdue-key"></div><div class="desc">Overdue</div></div>
                    </div>
                </div>
                <div class="chart"><canvas id="total"></canvas></div>
            </div>

            <div class="chart-container group">
                <div class="label-container">
                    <p class="logsheet-count"><span class="digits">{{ groupsArray.length }}</span> Group(s)</p>
                    <div class="legend"> 
                        <div class="legend-item"><div class="color-legend active-key"></div><div class="desc">Active</div></div>
                        <div class="legend-item"><div class="color-legend inactive-key"></div><div class="desc">Inactive</div></div>
                    </div>
                </div>
                <div class="chart"><canvas id="totalGroup"></canvas></div>
            </div>

            <div class="chart-container datapoints">
                <div class="label-container">
                    <p class="logsheet-count"><span class="digits">{{ datapointsArray.length }}</span> Datapoint(s)</p>
                    <div class="legend"> 
                        <div class="legend-item"><div class="color-legend active-key"></div><div class="desc">Active</div></div>
                        <div class="legend-item"><div class="color-legend inactive-key"></div><div class="desc">Inactive</div></div>
                    </div>
                </div>
                <div class="chart"><canvas id="totalDatapoint"></canvas></div>
            </div>

       
    </div>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
