<!-- @format -->

<div class="card custom-section">
	<h1 class="section-title">Add a Data Point</h1>
	<p class="subtext">Fill out the data point details using the form below.</p>
	<form
		#form="ngForm"
		style="margin-top: 2rem"
		(ngSubmit)="!activateSubmitBtn() ? null : addDataPoint()">
		<!-- COMPANY -->
		<div *ngIf="currentRole === 'Super Admin'">
			<label
				for=""
				class="custom-label required"
				>Company Name</label
			>
			<app-dropdown
				[disabled]="true"
				selectedItem="{{ selectedValues['company'] }}"
				label="{{
					selectedValues['company'].companyName
				}}"></app-dropdown>

			<div class="form-group"></div>
		</div>
		<div class="form-group col-2">
			<!-- PLANT -->
			<div>
				<label
					for=""
					class="custom-label required"
					>Plant Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues['plant'] }}"
					label="{{
						selectedValues['plant'].plantName
					}}"></app-dropdown>
			</div>

			<!-- UNIT -->
			<div>
				<label
					for=""
					class="custom-label required"
					>Unit Name</label
				>
				<app-dropdown
					[disabled]="true"
					selectedItem="{{ selectedValues['unit'] }}"
					label="{{
						selectedValues['unit'].unitName
					}}"></app-dropdown>
			</div>
		</div>

		<div class="form-group col-3">
			<!-- DATA POINT NAME -->
			<div>
				<label
					for="data_point_name"
					class="custom-label required"
					>Data Point Name</label
				>

				<input
					type="text"
					class="custom-input"
					name="data_point_name"
					id="data_point_name"
					minlength="3"
					maxlength="50"
					placeholder="Enter data point name"
					[(ngModel)]="addDataPointRequest.dataPointName" />
				<div
					*ngIf="!errorMessage.success"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ errorMessage.message }}
					</p>
				</div>
			</div>
			<!-- DATA TYPE -->
			<div>
				<label
					for="unit"
					class="custom-label required"
					>Data Type</label
				>
				<app-dropdown
					[disabled]="false"
					selectedItem="{{ selectedDataType }}"
					(onSelectOption)="setSelectedDataPointType($event)"
					[options]="dataTypes"
					[fields]="['id', 'dataPointType']"
					[form]="true"
					label="Choose Data Type"></app-dropdown>
			</div>
			<!-- UNIT OF MEASUREMENT -->
			<div *ngIf="selectedDataType.dataPointType === 'Number'">
				<label
					for="unit"
					class="custom-label required"
					>Unit of Measurement</label
				>
				<input
					type="text"
					class="custom-input"
					placeholder="Enter unit of measurement"
					[ngClass]="
						selectedDataType.dataPointType !== 'Number'
							? 'disabled-input'
							: ''
					"
					[readOnly]="
						selectedDataType.dataPointType !== 'Number'
							? true
							: false
					"
					name="unit"
					id="unit"
					[(ngModel)]="addDataPointRequest.unitOfMeasurement" />
				<div
					*ngIf="!errorMessage.success"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ errorMessage.message }}
					</p>
				</div>
			</div>

			<div *ngIf="selectedDataType.dataPointType === 'List (Single Selection)' || selectedDataType.dataPointType === 'List (Multiple Selection)'">
				<label
					for="list"
					class="custom-label required"
					>List Data Type Options
					<button
						class="tool-tip"
						type="button"
						(click)="toggleOverlay()">
						ⓘ
						<div
							class="overlay"
							*ngIf="displayOverlay">
							<div class="overlay-header">
								<div class="triangle-up"></div>
								{{ listMessage }}
							</div>
						</div>
					</button>
				</label>
				<input
					type="text"
					class="custom-input"
					placeholder="Use a comma to seperate each option"
					name="list"
					id="list"
					[(ngModel)]="addDataPointRequest.listOptions" />
				<div
					*ngIf="validationErrorMessage"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ validationErrorMessage }}
					</p>
				</div>
			</div>
		</div>

		<div
			class="form-group col-2"
			*ngIf="selectedDataType.dataPointType === 'Number'">
			<!-- MIN -->
			<div>
				<label
					for="data_point_min"
					class="custom-label required"
					>Data Point Min</label
				>
				<input
					type="number"
					class="custom-input"
					name="data_point_min"
					placeholder="Enter min"
					min="0"
					[ngClass]="
						selectedDataType.dataPointType !== 'Number'
							? 'disabled-input'
							: ''
					"
					[readOnly]="
						selectedDataType.dataPointType !== 'Number'
							? true
							: false
					"
					id="data_point_min"
					[(ngModel)]="addDataPointRequest.dataPointMin"
					onkeypress="return (/^[0-9&.-]/g.test(event.key))"
					 />
				<div
					*ngIf="!errorMessage.success"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ errorMessage.message }}
					</p>
				</div>
			</div>
			<!-- MAX -->
			<div>
				<label
					for="data_point_max"
					class="custom-label required"
					>Data Point Max</label
				>
				<input
					type="number"
					class="custom-input"
					name="data_point_max"
					id="data_point_max"
					placeholder="Enter max"
					[ngClass]="
						selectedDataType.dataPointType !== 'Number'
							? 'disabled-input'
							: ''
					"
					[readOnly]="
						selectedDataType.dataPointType !== 'Number'
							? true
							: false
					"
					onkeypress="return (/^[0-9&.-]/g.test(event.key))"
					[(ngModel)]="addDataPointRequest.dataPointMax" />
				<div
					*ngIf="!errorMessage.success"
					class="error">
					<img
						src="../../assets/alert.svg"
						alt="" />
					<p>
						{{ errorMessage.message }}
					</p>
				</div>
			</div>
		</div>

		<!-- DESCRIPTION -->
		<label
			for="desc"
			class="custom-label"
			>Description</label
		>
		<textarea
			name="data_point_description"
			id="data_point_description"
			cols="30"
			rows="10"
			placeholder="Type here"
			class="custom-input"
			[(ngModel)]="addDataPointRequest.dataPointDescription">
		</textarea>

		<input
			type="hidden"
			name="company_is_deleted"
			id="company_is_deleted"
			[(ngModel)]="addDataPointRequest.isDeleted" />
		<input
			type="hidden"
			name="company_status"
			id="company_status"
			[(ngModel)]="addDataPointRequest.status" />
		<div class="btn-group">
			<button
				class="btn-outline"
				(click)="transferData()"
				routerLink="..">
				Cancel
			</button>
			<button
				type="submit"
				class="btn-fill"
				[ngClass]="!activateSubmitBtn() ? 'disabled-btn' : ''">
				Add Data Point
			</button>
		</div>
	</form>
</div>
<app-success-toast *ngIf="toastService.showToast"></app-success-toast>
