<!-- @format -->

<div class="overview-container">
	<div class="overview-container-menu">
		<div>
			<div class="heading">
				<p>{{ unitName }}</p>

				<button
					class="close-button"
					[routerLink]="['/home']"
					[state]="[firstRender, selectedValues]"
					(click)="closeOverview()">
					Close
				</button>
			</div>
			<p class="instructions">Select a log sheet from the list below</p>

			<div class="search-bar">
				<img
					class="search-icon"
					src="../assets/search-alt.svg"
					alt="search" />
				<input
					type="text"
					placeholder="Search Log Sheet name"
					[(ngModel)]="searchText" />
			</div>
		</div>

		<div class="logsheet-labels">
			<a
				[routerLink]="[
					'/home',
					'overview',
					'unit',
					logsheet.logSheetTemplateId
				]"
				routerLinkActive="active-link"
				[state]="[logsheet.logSheetName]"
				class="logsheet-label"
				*ngFor="
					let logsheet of logsheets
						| search : searchText : 'logSheetName'
				">
				{{ logsheet.logSheetName }}
			</a>
		</div>
	</div>
	<div class="overview-container-main">
		<div class="custom-section card">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
