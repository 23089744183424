import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl : string = environment.apiUrl

  constructor(private http: HttpClient) { }

  retrieveUsers(userRequest : any) : Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + `/userGroup/user/plant/${userRequest.pageNumber}/${userRequest.pageSize}?searchParam=${userRequest.search || ''}&plantId=${userRequest.plantId || ''}`)
  }

}
