<div
    class="pagination"
    *ngIf="values.data.length !== 0">
    <button
        [style.opacity]="values.currentPage === 1 ? 0.5 : 1"
        type="button"
        (click)="performDecrement()">
        <img
            class="arrow flipped"
            src="../../assets/triangle-icon.svg"
            alt="navigate back" />
    </button>
    <div *ngFor="let item of values.loopArray; let i = index">
        <button
            *ngIf="item <= values.totalPages"
            [style]="values.currentPage === item ? selectedPage : ''"
            (click)="setCurrentPage(item)"
            class="page-number">
            {{ item }}
        </button>
    </div>
    <button
        [style.opacity]="values.currentPage === values.totalPages ? 0.5 : 1"
        type="button"
        (click)="performIncrement()">
        <img
            class="arrow"
            src="../../assets/triangle-icon.svg"
            alt="navigate forward" />
    </button>
</div>