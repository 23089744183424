import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Plant } from 'src/app/models/plant.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlantService {

  baseUrl : string = environment.apiUrl

  constructor(private http: HttpClient) { }

  retrieveCompanyPlants(id: string) : Observable<Plant[]> {
    return this.http.get<Plant[]>(this.baseUrl + '/plant/company/' + id)
  }

  retrieveCompanyPlantsPaginated(id: string, currentPage: number, totalPages: number) : Observable<Plant[]> {
    return this.http.get<Plant[]>(`${this.baseUrl}/plant/company/${id}/${currentPage}/${totalPages}`)
  }

  retrieveAllPlants() : Observable<Plant[]> {
    return this.http.get<Plant[]>(this.baseUrl + '/plant')
  }

  retrievePlant(id: string) : Observable<Plant> {
    return this.http.get<Plant>(this.baseUrl + '/plant/' + id)
  }

  addPlant(addCompanyRequest : Plant) : Observable<Plant> {
    return this.http.post<Plant>(this.baseUrl + '/plant', addCompanyRequest)
  }

  editPlant(id: number, updatePlantRequest : Plant) : Observable<Plant> {
    return this.http.patch<Plant>(this.baseUrl + '/plant/' + id, updatePlantRequest)
  }

  getPlantsAssociatedWithAdminSelected(id: number) : Observable<Plant> {
    return this.http.get<Plant>(this.baseUrl + `/usergroup/current-assigned-plant/${id}`)
  }

  deactivatePlant(id: number) : Observable<Plant> {
    return this.http.patch<Plant>(this.baseUrl + '/plant/deactivate/' + id, {})
  }
}
