import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-authorisaton-error',
  templateUrl: './authorisaton-error.component.html',
  styleUrls: ['./authorisaton-error.component.css']
})
export class AuthorisatonErrorComponent {
  url:string = "/auth/login";
  buttonTitle: string = "Return to Login"
  constructor(private router: Router) {};
  
  goBack(){
    this.router.navigate([this.url])
  }
}
