<!-- @format -->

<div class="modal-overlay">
	<div class="modal-body">
		<img
			*ngIf="actionButtonTitle !== 'Close'"
			(click)="toggleModal()"
			src="../../../assets/close.svg"
			alt=" icon" />
		<span>{{title}}</span>
		<div class="message">
			<p>{{subTitle}}</p>
		</div>
		<div>
			<button
				class="btn-outline"
				(click)="toggleModal()"
				*ngIf="showCancelButton"
				type="button">
				Cancel
			</button>
			<button
				(click)="saveDetails()"
				type="submit"
				class="btn-fill-yellow">
				{{actionButtonTitle}}
			</button>
		</div>
	</div>
</div>
<!-- (click)="dialogService.onSave()" -->
