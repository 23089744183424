import { ChangeDetectorRef, Component } from '@angular/core'
import { Router } from '@angular/router'
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core'
import { InactivityService } from 'src/app/services/inactivity-service/inactivity-service.service'
import { OverviewService } from 'src/app/services/overview/overview.service'
import { environment } from 'src/environments/environment'

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent {
    constructor(
      private inactivityService: InactivityService
        /* private idle: Idle,
        cd: ChangeDetectorRef,
        private router: Router,
        private overviewService: OverviewService */
    ) {
        /* const { idleTime, timeUntilIdle } = environment;
    idle.setIdle(timeUntilIdle);
    idle.setTimeout(idleTime - timeUntilIdle)
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      localStorage.removeItem("lastName");
      localStorage.removeItem("firstName");
      localStorage.removeItem("token");
      localStorage.removeItem("companyId");
      this.overviewService.resetToHome();
      localStorage.setItem("sessionInvalid", "true");
      this.router.navigate(["auth/login"])
    });

    idle.onIdleEnd.subscribe(() => {
      cd.detectChanges();
    });
  }

  reset() {
    this.idle.watch();
  }

  ngOnInit(): void {
    this.reset();
  } */
    }
}
