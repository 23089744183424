<div class='subMenuContainer'>
    <div class='title'>
        <h2> {{subTitle}} </h2>
    </div>
    <!-- Creates SubMenus links from Object Array -->
    <nav *ngFor='let item of subMenuInfo'>
        <a class="subMenu" routerLinkActive="active" [routerLink]="item['subMenuLink']">
            <div><img [src]="item['subMenuIcon']" alt=""></div>
            <div>
                <p class='subTitle'>{{ item['subMenuTitle'] }}</p>
                <p class='subDesc'>{{ item['subMenuDesc'] }}</p>
            </div>
        </a>
    </nav>
</div>