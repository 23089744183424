/** @format */

import { DatePipe } from "@angular/common"
import { Component } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Logsheet } from "src/app/models/logsheet.model"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"
import { LogsheetService } from "src/app/services/logsheet/logsheet.service"
import { DialogService } from "src/app/services/modal/dialog.service"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: 'app-schedule-logsheet',
	templateUrl: './schedule-logsheet.component.html',
	styleUrls: ['./schedule-logsheet.component.css'],
})
export class ScheduleLogsheetComponent {
	logSheetName: string = ''
	scheduleTypes = [
		{
			id: 1,
			type: 'Scheduled',
		},
		{
			id: 2,
			type: 'On Demand',
		},
	]
	intervalUnits = [
		{
			id: 1,
			unit: 'Minutes',
		},
		{
			id: 2,
			unit: 'Hours',
		},
		{
			id: 3,
			unit: 'Days',
		},
	]
	frequencyUnits = [
		{
			id: 1,
			unit: 'Hour(s)',
		},
		{
			id: 2,
			unit: 'Day(s)',
		},
		{
			id: 3,
			unit: 'Week(s)',
		},
		{
			id: 4,
			unit: 'Month(s)',
		},
	]

	frequencyValue: any = []

	allowedForMonth = [
		{
			id: 1,
			day: 'Day',
		},
	]
	daysOfTheWeek = [
		{
			id: 1,
			day: 'Sunday',
		},
		{
			id: 2,
			day: 'Monday',
		},
		{
			id: 3,
			day: 'Tuesday',
		},
		{
			id: 4,
			day: 'Wednesday',
		},
		{
			id: 5,
			day: 'Thursday',
		},
		{
			id: 6,
			day: 'Friday',
		},
		{
			id: 7,
			day: 'Saturday',
		},
	]
	firstOrLastDay = [
		{
			id: 1,
			day: 'First',
		},
		// {
		// 	id: 2,
		// 	day: 'Second',
		// },
		// {
		// 	id: 3,
		// 	day: 'Third',
		// },
		// {
		// 	id: 4,
		// 	day: 'Fourth',
		// },
		{
			id: 5,
			day: 'Last',
		},
	]

	selectedScheduleType = {
		id: 0,
		type: '',
	}
	selectedIntervalUnit = {
		id: 0,
		unit: '',
	}
	selectedFrequency = {
		id: 0,
		unit: '',
	}
	selectedFirstOrLastDay = {
		id: 0,
		day: '',
	}
	selectedFrequencyUnit = {
		id: 0,
		unit: '',
	}
	selectedDayOfWeek = {
		id: 0,
		unit: '',
	}

	firstRender = false
	sheetEnabled = false
	// selectedDaysOfTheWeek: string[] =

	errorMessage: any = {
		success: true,
		message: '',
	}

	startTimeMissing = ''
	startDateMissing = ''
	frequencyMissing = ''
	overdueIntervalMissing = ''
	daysSelectedMissing = ''
	atLeastOneMissingField = false

	addScheduleRequest = {
		logSheetTemplateId: 0,
		logSheetName: '',
		isEnabled: false,
		recurrenceOnSpecificDay: null,
		recurrenceFrequencyUnit: '',
		frequencyDay: 0,
		recurrenceFrequency: '',
		firstOrLastDay: '',
		specificDay: '1', //monthly
		dayOfWeek: '', //monthly
		selectedDaysOfTheWeek: [] as string[], //weekly
		// scheduleStart: "",
		scheduleType: '',
		scheduleInterval: 0,
		scheduleIntervalUnit: '',
		// overdueScheduleInterval: 0,

		startDate: '',
		startTime: '',
		endDate: '',
		endTime: '',
	}

	selectedValues =
		//  history?.state?.selectedValues

		{
			company: {
				companyId: 0,
				companyName: '',
				isDeleted: false,
			},
			plant: {
				plantId: 0,
				plantName: '',
				isDeleted: false,
			},
			unit: {
				unitId: 0,
				unitName: '',
				isDeleted: false,
				// status: false,
			},
		}

	interval: string = ''
	percentage: string = ''
	showModal: boolean = false

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private logsheetService: LogsheetService,
		public toastService: ToastService,
		public dialogService: DialogService,
		private errorService: ErrorHandlerService,
		private datePipe: DatePipe,
	) {}

	toggleSheet(enabled: boolean) {
		this.addScheduleRequest.isEnabled = enabled
	}

	closeModal() {
		this.showModal = false
	}

	getStartDate(value: string) {
		this.addScheduleRequest.startDate = value
	}
	getEndDate(value: string) {
		this.addScheduleRequest.endDate = value
	}

	savedStart: any = ''

	setSelectedDaysOfTheWeek(day: string) {
		if (this.addScheduleRequest.selectedDaysOfTheWeek.includes(day)) {
			this.addScheduleRequest.selectedDaysOfTheWeek =
				this.addScheduleRequest.selectedDaysOfTheWeek.filter(
					(el: string) => el !== day,
				)
		} else {
			this.addScheduleRequest.selectedDaysOfTheWeek.push(day)
		}
	}

	setScheduleType(selected: any) {
		this.addScheduleRequest.scheduleType = selected.type
	}
	setFirstOrLastDay(selected: any) {
		this.addScheduleRequest.firstOrLastDay = selected.day
	}
	setDayFrequency(selected: any) {
		this.addScheduleRequest.scheduleType = selected.type
	}

	setIntervalUnits(selected: any) {
		this.addScheduleRequest.scheduleIntervalUnit = selected.unit
	}
	setDayOfWeek(selected: any) {
		this.addScheduleRequest.dayOfWeek = selected.day
	}

	setFrequencyUnit(selected: any) {
		this.addScheduleRequest.recurrenceFrequencyUnit = selected.unit
	}

	setSpecificDay(selected: any) {
		this.addScheduleRequest.specificDay = selected.unit
	}

	transferData() {
		this.firstRender = true
		this.router.navigateByUrl('settings/logsheets', {
			state: {
				selectedValues: { ...this.selectedValues },
				firstRender: this.firstRender,
			},
		})
	}

	isValidNumber(x: any) {
		return parseInt(x) > 0
	}

	hasError = false
	activeError = ''
	weeklyCountError = ''
	firstOrLastDayError = ''
	dayOfWeekError = ''
	frequencyGeneralError = ''
	frequencyWeekError = ''
	frequencyHourError = ''

	handleValidation() {
		this.atLeastOneMissingField = false
		this.weeklyCountError = ''
		this.frequencyWeekError = ''
		this.frequencyGeneralError = ''
		this.firstOrLastDayError = ''
		this.dayOfWeekError = ''
		this.daysSelectedMissing = ''
		this.overdueIntervalMissing = ''
		this.frequencyHourError = ''
		this.hasError = false

		this.atLeastOneMissingField = false
		const {
			recurrenceFrequencyUnit,
			scheduleInterval,
			selectedDaysOfTheWeek,
			frequencyDay,
			startTime,
			startDate,
		} = this.addScheduleRequest

		const startDateFormatted = new Date(this.addScheduleRequest.startDate)
		const endDateFormatted = new Date(this.addScheduleRequest.endDate)
		const startTimeFormatted = new Date(this.addScheduleRequest.startTime)
		const endTimeFormatted = new Date(this.addScheduleRequest.endTime)

		startDateFormatted.setHours(startTimeFormatted.getHours())
		startDateFormatted.setMinutes(startTimeFormatted.getMinutes())
		endDateFormatted.setHours(endTimeFormatted.getHours())
		endDateFormatted.setMinutes(endTimeFormatted.getMinutes())

		const monthlyRadioNotSelected =
			this.addScheduleRequest.recurrenceOnSpecificDay == null &&
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Month(s)'

		const noDaysSelectedWeekly =
			selectedDaysOfTheWeek.length === 0 &&
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Week(s)'

		if (this.addScheduleRequest.scheduleType === 'Scheduled') {
			this.startTimeMissing = !startTime ? 'Start time is missing' : ''
			this.startDateMissing = !startDate ? 'Start Date is missing' : ''
			this.frequencyMissing = !recurrenceFrequencyUnit
				? 'Frequency unit not selected'
				: ''
			this.overdueIntervalMissing = !scheduleInterval
				? 'Overdue interval is missing'
				: ''
			this.daysSelectedMissing =
				selectedDaysOfTheWeek.length < 1
					? 'At least one day must be selected'
					: ''

			if (
				isNaN(startDateFormatted.getTime()) ||
				startDateFormatted > endDateFormatted
			) {
				this.hasError = true
				return false
			}

			if (this.addScheduleRequest.recurrenceFrequencyUnit === 'Hour(s)') {
				if (frequencyDay <= 0 || frequencyDay > 23) {
					this.frequencyHourError =
						'Hourly intervals must be within the range of 1 to 23 hours, inclusive.'
				}

				if (this.frequencyHourError) return false
			}

			if (this.addScheduleRequest.recurrenceFrequencyUnit === 'Week(s)') {
				if (selectedDaysOfTheWeek.length < 1) {
					this.weeklyCountError =
						selectedDaysOfTheWeek.length != 1
							? 'Select a day(s) for repeat and try again'
							: ''
				}

				if (frequencyDay != 1) {
					this.frequencyWeekError =
						'Weekly recurrence value must be set to one (1)'
				}

				if (this.weeklyCountError || this.frequencyWeekError)
					return false
			}

			if (
				this.addScheduleRequest.recurrenceFrequencyUnit === 'Month(s)'
			) {
				if (
					!this.addScheduleRequest.firstOrLastDay &&
					this.addScheduleRequest.recurrenceOnSpecificDay == false
				) {
					this.firstOrLastDayError =
						'At least one required field is empty. Please input a value'
				}
				if (
					!this.addScheduleRequest.dayOfWeek &&
					this.addScheduleRequest.recurrenceOnSpecificDay == false
				) {
					this.dayOfWeekError =
						'At least one required field is empty. Please input a value'
				}

				if (monthlyRadioNotSelected) {
					this.atLeastOneMissingField = true
				}

				if (
					this.firstOrLastDayError ||
					this.dayOfWeekError ||
					monthlyRadioNotSelected
				)
					return false
			}

			if (frequencyDay <= 0) {
				this.frequencyGeneralError =
					'At least one required field is empty. Please input a value'
			}

			if (
				isNaN(startDateFormatted.getTime()) ||
				!recurrenceFrequencyUnit ||
				scheduleInterval <= 0 ||
				noDaysSelectedWeekly ||
				monthlyRadioNotSelected ||
				frequencyDay <= 0
			) {
				this.atLeastOneMissingField = true
				return false
			}
		}

		return true
	}

	activeAddBtn() {
		if (this.addScheduleRequest.scheduleType !== 'On Demand') {
			if (this.addScheduleRequest.scheduleIntervalUnit.length === 0) {
				return false
			}
			if (
				this.addScheduleRequest.scheduleInterval.toString().length === 0
			) {
				return false
			}
		}

		return true
	}

	formatDate(item: string) {
		var dateFormatted = new Date(item).toLocaleDateString('en-us', {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		})

		if (dateFormatted === 'Invalid Date') return ''

		return dateFormatted
	}

	emptyState = ' '
	ngOnInit(): void {
		for (let i = 1; i <= 28; i++) {
			this.frequencyValue.push({
				id: i,
				unit: i.toString(), // Assuming you want 'unit' as a string
			})
		}
		this.selectedValues = history?.state?.selectedValues
		this.logSheetName = history?.state?.logSheetName
		this.route.paramMap.subscribe({
			next: (params) => {
				const id = params.get('id')

				if (id) {
					this.logsheetService
						.retrieveLogsheetRelative(id)
						.subscribe({
							next: (response) => {
								this.logsheetService
									.retrieveLogSheetById(
										response.logSheetTemplateId.toString(),
									)
									.subscribe({
										next: (response: any) => {
											this.addScheduleRequest.logSheetTemplateId =
												response.logSheetTemplateId

											if (response.scheduleType) {
												// set fields based on response
												this.addScheduleRequest.scheduleType =
													response.scheduleType ===
													'onDemand'
														? 'On Demand'
														: 'Scheduled'
												this.addScheduleRequest.isEnabled =
													response.isEnabled
												if (
													response.scheduleType.toLowerCase() ===
													'scheduled'
												) {
													this.addScheduleRequest.scheduleInterval =
														response.scheduleInterval

													this.addScheduleRequest.scheduleIntervalUnit =
														response.scheduleIntervalUnit

													this.savedStart =
														response.scheduleStart

													// auto fills fields with backend results
													this.addScheduleRequest.startDate =
														response.startDate
													this.addScheduleRequest.startTime =
														response.startTime
													this.addScheduleRequest.endDate =
														response.endDate
													this.addScheduleRequest.endTime =
														response.endTime
													this.addScheduleRequest.frequencyDay =
														response.frequencyDay
													this.addScheduleRequest.recurrenceFrequencyUnit =
														response.recurrenceFrequencyUnit
													this.addScheduleRequest.selectedDaysOfTheWeek =
														response.selectedDaysOfTheWeek
															? response.selectedDaysOfTheWeek
															: []
													this.addScheduleRequest.specificDay =
														response.specificDay
													this.addScheduleRequest.recurrenceOnSpecificDay =
														response.recurrenceOnSpecificDay
													this.addScheduleRequest.dayOfWeek =
														response.dayOfWeek
													this.addScheduleRequest.firstOrLastDay =
														response.firstOrLastDay
												}
											}
										},
										error: (err) => {
											this.errorMessage =
												this.errorService.handleRetrievalErrors(
													err,
												)
										},
									})
							},
							error: (response) => {
								this.errorMessage =
									this.errorService.handleRetrievalErrors(
										response,
									)
							},
						})
				}
			},
		})
	}

	// this.datePipe.transform(
	// 	this.addScheduleRequest.startTime,
	// 	"h:mm a",
	// )

	formatTimeForDB(time: string) {
		const currentDate = new Date(time)
		const year = currentDate.getFullYear()
		const month = String(currentDate.getMonth() + 1).padStart(2, '0')
		const day = String(currentDate.getDate()).padStart(2, '0')
		const hours = String(currentDate.getHours()).padStart(2, '0')
		const minutes = String(currentDate.getMinutes()).padStart(2, '0')
		const seconds = String(currentDate.getSeconds()).padStart(2, '0')

		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
	}
	submitClick() {
		if (!this.handleValidation()) {
			return
		}

		this.showModal = true
	}

	renderSummary() {
		let defaultResponse = ''

		// if start date has a value and unit is hours
		if (
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Hour(s)' &&
			this.addScheduleRequest.startDate
		) {
			defaultResponse = `Occurs every ${
				this.addScheduleRequest.frequencyDay
			} ${
				this.addScheduleRequest.recurrenceFrequencyUnit
			} starting on ${this.formatDate(this.addScheduleRequest.startDate)}`
		}

		// if start date has no value and unit is hours
		if (
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Hour(s)' &&
			!this.addScheduleRequest.startDate
		) {
			defaultResponse = `Occurs every ${this.addScheduleRequest.frequencyDay} ${this.addScheduleRequest.recurrenceFrequencyUnit}`
		}

		// if start date has a value and unit is days
		if (
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Day(s)' &&
			this.addScheduleRequest.startDate
		) {
			defaultResponse = `Occurs every ${
				this.addScheduleRequest.frequencyDay
			} ${
				this.addScheduleRequest.recurrenceFrequencyUnit
			} starting on ${this.formatDate(this.addScheduleRequest.startDate)}`
		}

		// if start date has no value and unit is days
		if (
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Day(s)' &&
			!this.addScheduleRequest.startDate
		) {
			defaultResponse = `Occurs every ${this.addScheduleRequest.frequencyDay} ${this.addScheduleRequest.recurrenceFrequencyUnit}`
		}

		// if start date has a value and unit is weeks with selected weeks included
		if (
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Week(s)' &&
			this.addScheduleRequest.startDate &&
			this.addScheduleRequest.selectedDaysOfTheWeek[1]
		) {
			const selectedDaysOfTheWeek =
				this.addScheduleRequest.selectedDaysOfTheWeek
			const nonEmptyStrings = selectedDaysOfTheWeek.filter(
				(day) => day !== '',
			)

			const sortedDays = nonEmptyStrings.sort((a, b) => {
				const weekDaysOrder = [
					'Sunday',
					'Monday',
					'Tuesday',
					'Wednesday',
					'Thursday',
					'Friday',
					'Saturday',
				]
				return weekDaysOrder.indexOf(a) - weekDaysOrder.indexOf(b)
			})

			const resultString = sortedDays.join(', ')
			defaultResponse = `Occurs every ${
				this.addScheduleRequest.frequencyDay
			} ${
				this.addScheduleRequest.recurrenceFrequencyUnit
			} on ${resultString} starting ${this.formatDate(
				this.addScheduleRequest.startDate,
			)}`
		}

		// if start date has a value and unit is weeks with selected weeks included
		if (
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Week(s)' &&
			!this.addScheduleRequest.startDate &&
			this.addScheduleRequest.selectedDaysOfTheWeek[1]
		) {
			const selectedDaysOfTheWeek =
				this.addScheduleRequest.selectedDaysOfTheWeek
			const nonEmptyStrings = selectedDaysOfTheWeek.filter(
				(day) => day !== '',
			)

			const sortedDays = nonEmptyStrings.sort((a, b) => {
				const weekDaysOrder = [
					'Sunday',
					'Monday',
					'Tuesday',
					'Wednesday',
					'Thursday',
					'Friday',
					'Saturday',
				]
				return weekDaysOrder.indexOf(a) - weekDaysOrder.indexOf(b)
			})

			const resultString = sortedDays.join(', ')
			defaultResponse = `Occurs every ${this.addScheduleRequest.frequencyDay} ${this.addScheduleRequest.recurrenceFrequencyUnit} on ${resultString}`
		}

		if (
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Month(s)' &&
			this.addScheduleRequest.startDate
		) {
			if (this.addScheduleRequest.recurrenceOnSpecificDay) {
				defaultResponse = `Occurs every ${
					this.addScheduleRequest.frequencyDay
				} ${this.addScheduleRequest.recurrenceFrequencyUnit} on day ${
					this.addScheduleRequest.specificDay
				} starting ${this.formatDate(
					this.addScheduleRequest.startDate,
				)}`
			} else {
				if (this.addScheduleRequest.dayOfWeek) {
					defaultResponse = `Occurs every ${
						this.addScheduleRequest.frequencyDay
					} ${
						this.addScheduleRequest.recurrenceFrequencyUnit
					} on the ${
						this.addScheduleRequest.firstOrLastDay || 'First'
					} ${
						this.addScheduleRequest.dayOfWeek
					} starting ${this.formatDate(
						this.addScheduleRequest.startDate,
					)}`
				}
			}
		}

		if (
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Month(s)' &&
			!this.addScheduleRequest.startDate
		) {
			if (this.addScheduleRequest.recurrenceOnSpecificDay) {
				defaultResponse = `Occurs every ${this.addScheduleRequest.frequencyDay} ${this.addScheduleRequest.recurrenceFrequencyUnit} on day ${this.addScheduleRequest.specificDay} `
			} else {
				if (this.addScheduleRequest.dayOfWeek) {
					defaultResponse = `Occurs every ${
						this.addScheduleRequest.frequencyDay
					} ${
						this.addScheduleRequest.recurrenceFrequencyUnit
					} on the ${
						this.addScheduleRequest.firstOrLastDay || 'First'
					} ${this.addScheduleRequest.dayOfWeek}`
				}
			}
		}

		// wrap everything in this
		if (this.addScheduleRequest.frequencyDay === 0) {
			defaultResponse = ''
		}
		return defaultResponse
	}

	add100YearsToCurrentDate = () => {
		const currentDate = new Date()
		const year = currentDate.getFullYear() + 100
		const month = String(currentDate.getMonth() + 1).padStart(2, '0')
		const day = String(currentDate.getDate()).padStart(2, '0')
		const hours = String(currentDate.getHours()).padStart(2, '0')
		const minutes = String(currentDate.getMinutes()).padStart(2, '0')
		const seconds = String(currentDate.getSeconds()).padStart(2, '0')

		return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
	}

	fuseDateTime = (date: String, time: String) => {
		return `${date.split('T')[0]}T${time.split('T')[1]}`
	}

	submitSchedule() {
		this.atLeastOneMissingField = false
		const {
			startDate,
			startTime,
			recurrenceFrequencyUnit,
			scheduleInterval,
			selectedDaysOfTheWeek,
			endDate,
			endTime,
		} = this.addScheduleRequest

		const monthlyRadioNotSelected =
			this.addScheduleRequest.recurrenceOnSpecificDay == null &&
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Month(s)'
		const noDaysSelectedWeekly =
			selectedDaysOfTheWeek.length === 0 &&
			this.addScheduleRequest.recurrenceFrequencyUnit === 'Week(s)'

		if (this.addScheduleRequest.scheduleType === 'Scheduled') {
			this.startTimeMissing = !startTime ? 'Start time is missing' : ''
			this.startDateMissing = !startDate ? 'Start Date is missing' : ''
			this.frequencyMissing = !recurrenceFrequencyUnit
				? 'Frequency unit not selected'
				: ''
			this.overdueIntervalMissing = !scheduleInterval
				? 'Overdue interval is missing'
				: ''
			this.daysSelectedMissing =
				selectedDaysOfTheWeek.length < 1
					? 'At least one day must be selected'
					: ''

			if (
				!startTime ||
				!startDate ||
				!recurrenceFrequencyUnit ||
				!scheduleInterval ||
				noDaysSelectedWeekly ||
				monthlyRadioNotSelected
			) {
				this.atLeastOneMissingField = true
			}

			if (!endDate || !endTime) {
				this.addScheduleRequest.endDate =
					this.add100YearsToCurrentDate()
				this.addScheduleRequest.endTime =
					this.add100YearsToCurrentDate()
			}

			// TODO: submitSchedule functionality
			// // Add variables to request object

			this.addScheduleRequest.startTime = this.formatTimeForDB(
				this.addScheduleRequest.startTime,
			)
			this.addScheduleRequest.endTime = this.formatTimeForDB(
				this.addScheduleRequest.endTime,
			)
			this.addScheduleRequest.endDate = this.formatTimeForDB(
				this.addScheduleRequest.endDate,
			)
			this.addScheduleRequest.startDate = this.formatTimeForDB(
				this.addScheduleRequest.startDate,
			)

			this.addScheduleRequest.startTime = this.fuseDateTime(
				this.addScheduleRequest.startDate,
				this.addScheduleRequest.startTime,
			)
			this.addScheduleRequest.endTime = this.fuseDateTime(
				this.addScheduleRequest.endDate,
				this.addScheduleRequest.endTime,
			)

			this.addScheduleRequest.scheduleInterval = parseInt(
				this.addScheduleRequest.scheduleInterval.toString(),
			)
		}

		this.logsheetService
			.scheduleLogSheet(
				this.addScheduleRequest.logSheetTemplateId,
				this.addScheduleRequest,
			)
			.subscribe({
				next: (response) => {
					this.transferData()
					this.toastService.showToast = true
					this.toastService.toastText =
						'Log Sheet successfully added!'
					setTimeout(() => {
						this.toastService.showToast = false
					}, 5000)
				},
				error: (response) => {
					// fall back
					this.logsheetService
						.retrieveLogsheetRelative(
							this.addScheduleRequest.logSheetTemplateId.toString(),
						)
						.subscribe({
							next: (resp: any) => {
								//
								this.logsheetService
									.scheduleLogSheet(
										resp.logSheetTemplateId,
										this.addScheduleRequest,
									)
									.subscribe({
										next: (response) => {
											this.transferData()
											this.toastService.showToast = true
											this.toastService.toastText =
												'Log Sheet successfully added!'
											setTimeout(() => {
												this.toastService.showToast =
													false
											}, 5000)
										},
										error: (error) => {
											this.errorService.handleError(
												error,
												'Error scheduling logsheet',
											)
										},
									})
								//
							},
							error: (error) => {
								this.errorService.handleError(
									error,
									'Error scheduling logsheet',
								)
							},
						})
					//
				},
			})
	}
}
